<template>
  <div class="user_background center_col_container page_basic_container" style="padding-top: 30px">
    <div class="center_col_container hover_shadow tf_et" v-if="!has_register" v-loading="loading"
         style="padding: 40px;margin: 20px;background-color: white;border-radius: 5px">
      <span style="padding: 10px;font-size: 20px">账户注册</span>
      <div class="center_col_container" style="width: 100%;">
        <el-input v-model="username" placeholder="用户名/账户" style="width: 100%;margin: 10px"></el-input>
        <el-input v-model="email" placeholder="邮箱" style="width: 100%;margin: 10px"></el-input>
        <el-input v-model="password" placeholder="密码" show-password style="width: 100%;margin: 10px;"></el-input>
        <el-input v-model="password_again" placeholder="再次确认密码" show-password
                  style="width: 100%;margin: 10px;"></el-input>
        <span v-if="error_hint!==''" style="color: red;margin-right: auto;font-size: 14px">* 提示：{{ error_hint }}</span>
      </div>
      <el-button style="margin: 20px;width: 100%;" type="warning" plain round @click="post_register">立即注册</el-button>
    </div>
    <div class="center_col_container hover_shadow" v-else
         style="padding: 40px;margin: 20px;width: 25%;background-color: white;border-radius: 5px">
      <span style="padding: 10px;font-size: 20px">激活邮件已发送至您的邮箱~</span>
      <span style="padding: 5px;font-size: 16px;color: gray">点击激活邮件中的链接以激活EasyPaper网校账户！</span>
      <img src="../../assets/small_e/correct.jpg" style="width: 55%;height: auto">
      <!--      <el-image-->
      <!--          style="width: 75%; height: auto"-->
      <!--          src="../../assets/small_e/correct.jpg"-->
      <!--          fit="contain"></el-image>-->
      <div style="padding: 15px" class="center_col_container">
        <el-button @click="goToLogin" size="medium">立即登陆</el-button>
        <span style="padding: 8px;color: #aaaaaa;font-size: 10px">已激活？</span>
      </div>
    </div>
    <el-dialog
        title="一个简单的验证"
        class="center_col_container"
        :visible.sync="dialogVisible"
        width="350px">
      <div class="center_col_container">
        <SlideVerify ref="slideblock" slider-text="向右滑动"
                     @success="onVerifySuccess"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {user_login, user_register} from "@/utils/user_utils";
import {checkemail, isPasswordAvailable} from "@/utils/utils";

export default {
  name: "UserRegister",

  data() {
    return {
      error_hint: "",
      loading: false,
      dialogVisible: false,
      has_register: false,
      password: "",
      password_again: "",
      username: "",
      email: "",
      rules: {
        username: [
          {required: true, message: '请输入姓名或昵称', trigger: 'blur'},
          {min: 8, max: 30, message: '长度在 8 到 8 个字符', trigger: 'blur'}
        ],
        password:
            {required: true, message: '请输入密码', trigger: 'blur'},
        password_again:
            {required: true, message: '请重新输入密码', trigger: 'blur'},
        email:
            {required: true, message: '请输入邮箱', trigger: 'blur'},
      }
    }
  },
  methods: {
    onVerifySuccess() {
      this.dialogVisible = false
      this.$refs.slideblock.reset()
      user_register({
        "username": this.username,
        "email": this.email,
        "password1": this.password,
        "password2": this.password_again
      }, this)
    },
    post_register() {
      if (this.username.length < 3) {
        this.error_hint = "用户名称太短（不能少于3个字符）"
      } else if (this.username.indexOf(" ") !== -1) {
        this.error_hint = "用户名称不能包含空格！"
      } else if (this.username.length >= 30) {
        this.error_hint = "用户名称过长（不能超过30个字符）"
      } else if (this.password !== this.password_again) {
        this.error_hint = "两次输入的密码不同！"
        this.password_again = ""
      } else if (!(this.password.length >= 8 && this.password.length <= 30)) {
        this.error_hint = "密码长度为8-20个字符！"
      } else if (!isPasswordAvailable(this.password)) {
        this.error_hint = "密码强度不足！（密码需由大写/小写字母/数字/特殊字符组成，任意3者即可）"
      } else if (!checkemail(this.email)) {
        this.error_hint = "请输入合法的邮箱地址！"
      } else {
        this.error_hint = ""
        this.dialogVisible = true
      }
    },
    goToLogin() {
      this.$router.push("/user/login")
    }
  }
}
</script>

<style scoped>

</style>