<template>

  <div class="page_basic_container space_between_row_container" style="background-color:#fcfcfc;padding-bottom: 100px">
    <div class="flex_start_col_container" style="width: 100%">
      <div class="center_col_container">
        <transition name="van-slide-up">
          <template v-if="is_pre_select">
            <div class="base_container" v-loading="loading">
              <div style="padding: 10px;margin-top: 50px;margin-bottom: 10px;padding-bottom: 40px;border-radius: 20px"
                   class="hover_shadow white_div">
                <div class="center_col_container" style="padding: 30px">
                  <span style="font-size: 25px">选择一些相关信息然后开始选择吧~</span>
                </div>
                <div class="hidden-sm-and-down">
                  <div class="flex_start_row_container" style="margin-bottom: 20px;width: 100%">
                    <p class="filter_title">学科分类:</p>
                    <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                       v-model="cataFilters"
                                       @change="onCataFilterChange" size="small">
                      <template v-for="item in Object.keys(subject_cata_dict)">
                        <el-checkbox-button :label="item">{{ subject_cata_dict[item] }}</el-checkbox-button>
                      </template>
                    </el-checkbox-group>
                    <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                      <el-button style="margin-left: 10px" @click="cataFilterSelectAll" size="mini">全选</el-button>
                      <el-button style="margin-left: 10px" @click="clearCataFilter" size="mini">全不选</el-button>
                    </div>
                  </div>
                  <div class="flex_start_row_container" style="margin-bottom:20px">
                    <p class="filter_title"> 考试:</p>
                    <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                       v-model="examFilters"
                                       @change="onExamFilterChange" size="mini">
                      <template v-for="item in exam.obj">
                        <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                      </template>
                    </el-checkbox-group>
                    <div class="flex_start_center_row_container" style="margin-top: auto;margin-bottom: auto">
                      <el-button style="margin-left: 10px" @click="examFilterSelectAll" size="mini">全选</el-button>
                      <el-button style="margin-left: 10px" @click="clearExamFilter" size="mini">全不选</el-button>
                    </div>
                  </div>
                  <div class="flex_start_row_container" style="margin-bottom: 45px">
                    <p class="filter_title">年级/阶段：</p>
                    <el-checkbox-group class="filter_item" v-model="stageFilters" @change="onStageFilterChange"
                                       size="small"
                                       style="margin-top: auto;margin-bottom: auto">
                      <template v-for="item in stage.obj">
                        <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                      </template>
                    </el-checkbox-group>
                    <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                      <el-button style="margin-left: 10px" @click="stageFilterSelectAll" size="mini">全选</el-button>
                      <el-button style="margin-left: 10px" @click="clearStageFilter" size="mini">全不选</el-button>
                    </div>
                  </div>
                  <div class="center_col_container">
                    <el-button @click="endPreSelect">开始在线择师</el-button>
                  </div>
                </div>
                <div class="hidden-md-and-up">
                  <div class="flex_start_col_container" style="margin-bottom: 20px;width: 100%">
                    <p class="filter_title">学科分类:</p>
                    <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                       v-model="cataFilters"
                                       @change="onCataFilterChange" size="small">
                      <template v-for="item in Object.keys(subject_cata_dict)">
                        <el-checkbox-button :label="item">{{ subject_cata_dict[item] }}</el-checkbox-button>
                      </template>
                    </el-checkbox-group>
                    <div class="flex_start_row_container" style="margin-top: 10px">
                      <el-button style="margin-left: 10px" @click="cataFilterSelectAll" size="mini">全选</el-button>
                      <el-button style="margin-left: 10px" @click="clearCataFilter" size="mini">全不选</el-button>
                    </div>
                  </div>
                  <div class="flex_start_col_container" style="margin-bottom:20px">
                    <p class="filter_title"> 考试:</p>
                    <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                       v-model="examFilters"
                                       @change="onExamFilterChange" size="mini">
                      <template v-for="item in exam.obj">
                        <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                      </template>
                    </el-checkbox-group>
                    <div class="flex_start_center_row_container" style="margin-top: 10px">
                      <el-button style="margin-left: 10px" @click="examFilterSelectAll" size="mini">全选</el-button>
                      <el-button style="margin-left: 10px" @click="clearExamFilter" size="mini">全不选</el-button>
                    </div>
                  </div>
                  <div class="flex_start_col_container" style="margin-bottom: 45px">
                    <p class="filter_title">年级/阶段：</p>
                    <el-checkbox-group class="filter_item" v-model="stageFilters" @change="onStageFilterChange"
                                       size="small"
                                       style="margin-top: auto;margin-bottom: auto">
                      <template v-for="item in stage.obj">
                        <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                      </template>
                    </el-checkbox-group>
                    <div class="flex_start_row_container" style="margin-top: 10px">
                      <el-button style="margin-left: 10px" @click="stageFilterSelectAll" size="mini">全选</el-button>
                      <el-button style="margin-left: 10px" @click="clearStageFilter" size="mini">全不选</el-button>
                    </div>
                  </div>
                  <div class="center_col_container">
                    <el-button @click="endPreSelect">开始在线择师</el-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition>
        <transition name="van-slide-up">
          <template v-if="!is_pre_select">
            <template v-if="!show_teacher_detail">
              <div class="base_container2">
                <div style="padding: 10px;margin-top: 20px;margin-bottom: 10px" class="hover_shadow white_div">
                  <div class="hidden-sm-and-down">
                    <div class="flex_start_row_container" style="margin-bottom: 4px">
                      <p class="filter_title">学科分类:</p>
                      <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                         v-model="cataFilters"
                                         @change="onCataFilterChange" size="small">
                        <template v-for="item in Object.keys(subject_cata_dict)">
                          <el-checkbox-button :label="item">{{ subject_cata_dict[item] }}</el-checkbox-button>
                        </template>
                      </el-checkbox-group>
                      <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                        <el-button style="margin-left: 10px" @click="cataFilterSelectAll" size="mini">全选</el-button>
                        <el-button style="margin-left: 10px" @click="clearCataFilter" size="mini">全不选</el-button>
                      </div>
                    </div>
                    <div class="flex_start_row_container" style="margin-bottom:4px">
                      <p class="filter_title"> 考试:</p>
                      <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                         v-model="examFilters"
                                         @change="onExamFilterChange" size="mini">
                        <template v-for="item in exam.obj">
                          <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                        </template>
                      </el-checkbox-group>
                      <div class="flex_start_center_row_container" style="margin-top: auto;margin-bottom: auto">
                        <el-button style="margin-left: 10px" @click="examFilterSelectAll" size="mini">全选</el-button>
                        <el-button style="margin-left: 10px" @click="clearExamFilter" size="mini">全不选</el-button>
                      </div>
                    </div>
                    <div class="flex_start_row_container" style="margin-bottom: 4px">
                      <p class="filter_title">年级/阶段：</p>
                      <el-checkbox-group class="filter_item" v-model="stageFilters" @change="onStageFilterChange"
                                         size="small"
                                         style="margin-top: auto;margin-bottom: auto">
                        <template v-for="item in stage.obj">
                          <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                        </template>
                      </el-checkbox-group>
                      <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                        <el-button style="margin-left: 10px" @click="stageFilterSelectAll" size="mini">全选</el-button>
                        <el-button style="margin-left: 10px" @click="clearStageFilter" size="mini">全不选</el-button>
                      </div>
                    </div>
                    <div class="flex_start_col_container" style="margin-bottom: 4px;">
                      <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                        <p class="filter_title" style="font-size: medium">具体学科：</p>

                        <template v-if="displaySubjects.length===0">
                          <p class="filter_title">暂无学科</p>
                          <el-button style="height: 30px;margin-top: auto;margin-bottom: auto"
                                     @click="subjectSelectTotal"
                                     size="mini">所有学科
                          </el-button>
                        </template>
                        <template v-else>
                          <el-button @click="toggleDisplay" size="mini"
                                     style="height: 30px;margin-top: auto;margin-bottom: auto">
                            <template v-if="!show_subject_filter">展示</template>
                            <template v-if="show_subject_filter">隐藏</template>
                          </el-button>
                        </template>
                      </div>
                      <transition name="van-fade">
                        <div v-show="show_subject_filter" class="flex_start_row_container" style="margin-bottom: 4px">
                          <el-checkbox-group v-model="subjectFilters" @change="onSubjctFilterChange" size="small"
                                             style="margin-top: auto;margin-bottom: auto">
                            <template v-for="subject in subjects">
                              <el-checkbox :label="subject.id" v-if="isDisplaySubject(subject)">
                                {{ showSubjectName(subject) }}<br>
                              </el-checkbox>
                            </template>
                          </el-checkbox-group>
                          <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                            <el-button v-if="displaySubjects.length!==0" style="margin-left: 10px"
                                       @click="subjectFilterSelectAll"
                                       size="mini">全选
                            </el-button>
                            <el-button v-if="displaySubjects.length!==0" style="margin-left: 10px"
                                       @click="clearSubjectFilter"
                                       size="mini">全不选
                            </el-button>
                            <el-button style="margin-left: 10px" @click="subjectSelectTotal" size="mini">所有学科
                            </el-button>
                          </div>
                        </div>

                      </transition>
                    </div>

                  </div>
                  <div class="hidden-md-and-up">
                    <div class="flex_start_col_container" style="margin-bottom: 4px">
                      <p class="filter_title">学科分类:</p>
                      <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                         v-model="cataFilters"
                                         @change="onCataFilterChange" size="small">
                        <template v-for="item in Object.keys(subject_cata_dict)">
                          <el-checkbox-button :label="item">{{ subject_cata_dict[item] }}</el-checkbox-button>
                        </template>
                      </el-checkbox-group>
                      <div class="flex_start_row_container" style="margin-top: 4px">
                        <el-button style="margin-left: 10px" @click="cataFilterSelectAll" size="mini">全选</el-button>
                        <el-button style="margin-left: 10px" @click="clearCataFilter" size="mini">全不选</el-button>
                      </div>
                    </div>
                    <div class="flex_start_col_container" style="margin-bottom:4px">
                      <p class="filter_title"> 考试:</p>
                      <el-checkbox-group style="margin-top: auto;margin-bottom: auto" class="filter_item"
                                         v-model="examFilters"
                                         @change="onExamFilterChange" size="mini">
                        <template v-for="item in exam.obj">
                          <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                        </template>
                      </el-checkbox-group>
                      <div class="flex_start_center_row_container" style="margin-top: 4px">
                        <el-button style="margin-left: 10px" @click="examFilterSelectAll" size="mini">全选</el-button>
                        <el-button style="margin-left: 10px" @click="clearExamFilter" size="mini">全不选</el-button>
                      </div>
                    </div>
                    <div class="flex_start_col_container" style="margin-bottom: 4px">
                      <p class="filter_title">年级/阶段：</p>
                      <el-checkbox-group class="filter_item" v-model="stageFilters" @change="onStageFilterChange"
                                         size="small"
                                         style="margin-top: auto;margin-bottom: auto">
                        <template v-for="item in stage.obj">
                          <el-checkbox-button :label="item.id">{{ item.name }}</el-checkbox-button>
                        </template>
                      </el-checkbox-group>
                      <div class="flex_start_row_container" style="margin-top: 4px">
                        <el-button style="margin-left: 10px" @click="stageFilterSelectAll" size="mini">全选</el-button>
                        <el-button style="margin-left: 10px" @click="clearStageFilter" size="mini">全不选</el-button>
                      </div>
                    </div>
                    <div class="flex_start_col_container" style="margin-bottom: 4px;">
                      <div class="flex_start_row_container" style="margin-top: auto;margin-bottom: auto">
                        <p class="filter_title" style="font-size: medium">具体学科：</p>

                        <template v-if="displaySubjects.length===0">
                          <p class="filter_title">暂无学科</p>
                          <el-button style="height: 30px;margin-top: auto;margin-bottom: auto"
                                     @click="subjectSelectTotal"
                                     size="mini">所有学科
                          </el-button>
                        </template>
                        <template v-else>
                          <el-button @click="toggleDisplay" size="mini"
                                     style="height: 30px;margin-top: auto;margin-bottom: auto">
                            <template v-if="!show_subject_filter">展示</template>
                            <template v-if="show_subject_filter">隐藏</template>
                          </el-button>
                        </template>
                      </div>
                      <transition name="van-fade">
                        <div v-show="show_subject_filter" class="flex_start_col_container" style="margin-bottom: 4px">
                          <el-checkbox-group v-model="subjectFilters" @change="onSubjctFilterChange" size="small"
                                             style="margin-top: auto;margin-bottom: auto">
                            <template v-for="subject in subjects">
                              <el-checkbox :label="subject.id" v-if="isDisplaySubject(subject)">
                                {{ showSubjectName(subject) }}<br>
                              </el-checkbox>
                            </template>
                          </el-checkbox-group>
                          <div class="flex_start_row_container" style="margin-top: 4px">
                            <el-button v-if="displaySubjects.length!==0" style="margin-left: 10px"
                                       @click="subjectFilterSelectAll"
                                       size="mini">全选
                            </el-button>
                            <el-button v-if="displaySubjects.length!==0" style="margin-left: 10px"
                                       @click="clearSubjectFilter"
                                       size="mini">全不选
                            </el-button>
                            <el-button style="margin-left: 10px" @click="subjectSelectTotal" size="mini">所有学科
                            </el-button>
                          </div>
                        </div>

                      </transition>
                    </div>


                  </div>

                </div>
                <div class="flex_start_col_container hover_shadow white_div hidden-md-and-up" style="padding: 10px">
                  <el-radio-group v-model="sortOption" style="margin: 3px">
                    <el-radio-button label="rating">评分排序</el-radio-button>
                    <el-radio-button label="price_rising">价格升序</el-radio-button>
                    <el-radio-button label="price_falling">价格降序</el-radio-button>
                  </el-radio-group>
                  <el-checkbox-group class="filter_item" style="margin-top: 14px; margin-left: 50px"
                                     v-model="filterList"
                                     @change="onSortFilterChange">
                    <el-checkbox label="price_changable">可议价</el-checkbox>
                    <el-checkbox label="free_try">免费试听</el-checkbox>
                    <el-checkbox label="video">有试讲视频</el-checkbox>
                    <el-checkbox label="discount">提供折扣</el-checkbox>
                    <el-checkbox label="no_limit" :disabled="false">无限制</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="flex_start_row_container hover_shadow white_div hidden-sm-and-down" style="padding: 10px">
                  <el-radio-group v-model="sortOption" style="margin: 3px">
                    <el-radio-button label="rating">评分排序</el-radio-button>
                    <el-radio-button label="price_rising">价格升序</el-radio-button>
                    <el-radio-button label="price_falling">价格降序</el-radio-button>
                  </el-radio-group>
                  <el-checkbox-group class="filter_item" style="margin-top: 14px; margin-left: 50px"
                                     v-model="filterList"
                                     @change="onSortFilterChange">
                    <el-checkbox label="price_changable">可议价</el-checkbox>
                    <el-checkbox label="free_try">免费试听</el-checkbox>
                    <el-checkbox label="video">有试讲视频</el-checkbox>
                    <el-checkbox label="discount">提供折扣</el-checkbox>
                    <el-checkbox label="no_limit" :disabled="false">无限制</el-checkbox>
                  </el-checkbox-group>
                </div>
                <!--            <template v-for="(teacher,idx) in teachers">-->
                <!--              <div class="space_between_row_container">-->
                <!--                <template v-if="idx%2===0">-->
                <!--                  <TeacherPreview v-if="displayTeacherList.indexOf(teacher.id) !== -1" :teacher="teachers[idx]"-->
                <!--                                  @goToDetail="goToDetail"/>-->
                <!--                  <template v-if="idx+1<=teachers.length-1">-->
                <!--                    <TeacherPreview v-if="displayTeacherList.indexOf(teachers[idx+1].id) !== -1"-->
                <!--                                    :teacher="teachers[idx+1]"-->
                <!--                                    @goToDetail="goToDetail"/>-->
                <!--                  </template>-->
                <!--                </template>-->
                <!--              </div>-->
                <!--            </template>-->
                <div class="space_between_row_container hidden-sm-and-down" style=" flex-wrap:wrap">
                  <template v-for="teacher in realDisplayTeachers">
                    <!--                    <div class="teacher_preview_container">-->
                    <TeacherPreview :teacher="teacher"
                                    @goToDetail="goToDetail" class="teacher_preview_container"/>
                    <!--                    </div>-->
                  </template>
                </div>
                <div class="center_col_container hidden-md-and-up">
                  <template v-for="teacher in realDisplayTeachers">
                    <!--                    <p>可以看见我（外部）{{idx}}</p>-->
                    <TeacherPreview :teacher="teacher"
                                    @goToDetail="goToDetail" class="teacher_preview_container"/>
                  </template>
                </div>
                <template v-if="displayTeacherList.length===0">
                  <van-empty description="暂无符合条件的老师哦"/>
                </template>
              </div>
            </template>
            <template v-else>
              <TeacherDetail :id="teacher_id" @goBack="goBack" @contact="openDialog"/>
            </template>
          </template>
        </transition>
      </div>
    </div>
    <transition name="van-slide-up">
      <div v-show="showDialog" style="position: fixed;right: 30px;bottom: 50px;z-index: 1000"
           class="hidden-sm-and-down">
        <ServiceDialogBox ref="dialogbox" @close="closeDialog" width="400px" height="500px"/>
      </div>

    </transition>
    <transition name="van-slide-up">
      <div v-show="showDialog" style="position: fixed;z-index: 1000" class="hidden-md-and-up">
        <div class="center_col_container" style="width: 100%">
          <ServiceDialogBox ref="dialogbox2" @close="closeDialog" width="80%" height="400px"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TeacherPreview from "@/components/TeacherPreview";
import {get_subjects, get_teachers} from "@/utils/LSP_utils";
import TeacherDetail from "@/components/TeacherDetail";
import ServiceDialogBox from "@/components/ServiceDialogBox";
import Vue from "vue";
import {randomString} from "@/utils/utils";
import crypto_utils from "@/utils/crypto_utils";

export default {
  name: "LectuerSelectPage",
  components: {ServiceDialogBox, TeacherDetail, TeacherPreview},
  data() {
    return {
      realDisplayTeachers: [],
      teachers: [],
      examFilters: [],
      stageFilters: [],
      subjectFilters: [],
      // cataFilters: Object.keys(Vue.prototype.subject_cata_dict),
      cataFilters: [],
      show_teacher_detail: false,
      showDialog: false,
      teacher_id: 0,
      filterList: ["no_limit"],
      sortOption: "rating",
      stage: [],
      exam: [],
      subjects: [],
      displaySubjects: [],
      displayTeacherList: [],
      teacher_subject: [],
      subject_cata_dict: Vue.prototype.subject_cata_dict,
      show_subject_filter: false,
      is_pre_select: true,
      is_jumped: false,
      loading: false
    }
  },
  mounted() {
    let that = this
    get_subjects(that)
    if (this.$route.query.id) {
      this.teacher_id = crypto_utils.decrypt(this.$route.query.id).substring(8, this.$route.query.id.length)
      this.is_pre_select = false
      this.show_teacher_detail = true
      this.is_jumped = true
    }
  },
  watch: {
    subjectFilters() {

    },
    sortOption(sortOption) {
      get_teachers(this, {"sort": sortOption, "filter": this.filterList})
    },
    teachers(teachers) {
      var displayList = []
      for (var i = 0; i < teachers.length; i++) {
        var teacher = teachers[i]
        if (this.displayTeacherList.indexOf(teacher.id) !== -1) {
          displayList.push(teacher)
        }
      }
      this.realDisplayTeachers = displayList
    }
  },
  methods: {
    endPreSelect() {
      if (this.examFilters.length === 0 && this.stageFilters.length === 0 && this.cataFilters.length === 0) {
        this.subjectSelectTotal()
      } else {
        if (this.examFilters.length === 0) {
          this.examFilterSelectAll()
        }
        if (this.stageFilters.length === 0) {
          this.stageFilterSelectAll()
        }
        if (this.cataFilters.length === 0) {
          this.cataFilterSelectAll()
        }
      }
      this.is_pre_select = false
    },
    sortSubjects() {
      let subjectDict = {}
      for (let i = 0; i < this.subjects.length; i++) {
        let subject = this.subjects[i];
        subjectDict[this.showSubjectName(subject)] = subject
      }
      var names = Object.keys(subjectDict).sort();
      var sortedSubjects = []
      for (var i = 0; i < names.length; i++) {
        sortedSubjects.push(subjectDict[names[i]])
      }
      this.subjects = sortedSubjects
    },
    toggleDisplay() {
      this.show_subject_filter = !this.show_subject_filter
    },
    updateDisplaySubjects() {
      var displaySubjects = []
      for (var i = 0; i < this.subjects.length; i++) {
        if (this.isDisplaySubject(this.subjects[i])) {
          displaySubjects.push(this.subjects[i].id)
        }
      }
      this.displaySubjects = displaySubjects
    },
    updateRealDisplayTeachers() {
      var displayList = []
      for (var i = 0; i < this.teachers.length; i++) {
        var teacher = this.teachers[i]
        if (this.displayTeacherList.indexOf(teacher.id) !== -1) {
          displayList.push(teacher)
        }
      }
      this.realDisplayTeachers = displayList
    },
    showSubjectName(subject) {
      if (!subject.stage_id) {
        return this.exam.idToName[subject.exam_id] + " " + subject.name
      }
      return this.exam.idToName[subject.exam_id] + " " + this.stage.idToName[subject.stage_id] + " " + subject.name
    },
    isDisplaySubject(subject) {
      if (!subject.stage_id) {
        return this.examFilters.indexOf(subject.exam_id) !== -1 && this.cataFilters.indexOf(subject.subject_cata) !== -1
      }
      return this.examFilters.indexOf(subject.exam_id) !== -1
          && this.stageFilters.indexOf(subject.stage_id) !== -1
          && this.cataFilters.indexOf(subject.subject_cata) !== -1
    },
    onSubjctFilterChange() {
      var teacher_list = []
      for (var i = 0; i < this.subjectFilters.length; i++) {
        var id = this.subjectFilters[i]
        try {
          for (var j = 0; j < this.teacher_subject[id].length; j++) {
            if (teacher_list.indexOf(this.teacher_subject[id][j]) === -1) {
              teacher_list.push(this.teacher_subject[id][j])
            }
          }
        } catch (e) {

        }
      }
      this.displayTeacherList = teacher_list
      this.updateDisplaySubjects()
      this.updateRealDisplayTeachers()
    },
    clearExtraSubjectOptions() {
      var subjectFilters = []
      var subjectDisplayFilters = []
      var oldSubjectFilters = this.subjectFilters
      var subjectDict = {}

      // 新进来的
      for (var i = 0; i < this.subjects.length; i++) {
        if (this.isDisplaySubject(this.subjects[i]) && this.subjectDisplayFilters.indexOf(this.subjects[i].id) === -1) {
          subjectFilters.push(this.subjects[i].id)
        }
        subjectDict[this.subjects[i].id] = this.subjects[i]
      }
      // 老的
      for (var i = 0; i < oldSubjectFilters.length; i++) {
        if (this.isDisplaySubject(subjectDict[oldSubjectFilters[i]])) {
          subjectFilters.push(oldSubjectFilters[i])
        }
      }
      for (var i = 0; i < this.subjects.length; i++) {
        if (this.isDisplaySubject(this.subjects[i])) {
          subjectDisplayFilters.push(this.subjects[i].id)
        }
      }
      this.subjectDisplayFilters = subjectDisplayFilters
      this.subjectFilters = subjectFilters
      this.onSubjctFilterChange()
    },
    clearCataFilter() {
      this.cataFilters = []
      this.clearExtraSubjectOptions()
    },
    clearStageFilter() {
      this.stageFilters = []
      this.clearExtraSubjectOptions()
    },
    clearExamFilter() {
      this.examFilters = []
      this.clearExtraSubjectOptions()
    },
    clearSubjectFilter() {
      this.subjectFilters = []
      this.onSubjctFilterChange()
    },
    onCataFilterChange() {
      this.clearExtraSubjectOptions()
    },
    onStageFilterChange() {
      this.clearExtraSubjectOptions()
    },
    onExamFilterChange() {
      if (this.exam.idToName[this.examFilters[this.examFilters.length - 1]] === "标化") {
        this.enableLangCata()
      }
      this.clearExtraSubjectOptions()
    },
    enableLangCata() {
      if (this.cataFilters.indexOf("Language") === -1) {
        this.cataFilters.push("Language")
      }
    },
    cataFilterSelectAll() {
      this.cataFilters = Object.keys(Vue.prototype.subject_cata_dict)
      this.clearExtraSubjectOptions()
    },
    examFilterSelectAll() {
      for (var i = 0; i < this.exam.obj.length; i++) {
        this.examFilters.push(this.exam.obj[i].id)
      }
      this.enableLangCata()
      this.clearExtraSubjectOptions()
    },
    stageFilterSelectAll() {
      for (var i = 0; i < this.stage.obj.length; i++) {
        this.stageFilters.push(this.stage.obj[i].id)
      }
      this.clearExtraSubjectOptions()
    },
    subjectSelectTotal() {
      this.stageFilterSelectAll()
      this.examFilterSelectAll()
      this.cataFilterSelectAll()
      this.subjectFilterSelectAll()
    },
    subjectFilterSelectAll() {
      for (var i = 0; i < this.subjects.length; i++) {
        this.subjectFilters.push(this.subjects[i].id)
      }
      var displayTeacherList = []
      for (var i = 0; i < this.teachers.length; i++) {
        displayTeacherList.push(this.teachers[i].id)
      }
      this.displayTeacherList = displayTeacherList
      this.subjectDisplayFilters = this.subjectFilters
      this.onSubjctFilterChange()
    },
    onSortFilterChange() {
      var filterList = this.filterList
      if (filterList.length === 0) {
        filterList = ["no_limit"]
      } else if (filterList[filterList.length - 1] === "no_limit") {
        filterList = ["no_limit"]
      } else if (filterList !== ["no_limit"]) {
        delete filterList[filterList.indexOf("no_limit")]
      }
      filterList = filterList.filter(String)
      this.filterList = filterList
      get_teachers(this,
          {
            "sort":
            this.sortOption, "filter":
            this.filterList
          }
      )
    },
    closeDialog() {
      this.showDialog = false
    },
    openDialog() {
      this.showDialog = true
      let that = this
      this.$nextTick(function () {
        that.$refs.dialogbox.addResponse(that.contact_const.book_try, 1, 800)
        that.$refs.dialogbox.addResponse(that.contact_const.contact_qrcode, 2, 1200)
        that.$refs.dialogbox2.addResponse(that.contact_const.book_try, 1, 800)
        that.$refs.dialogbox2.addResponse(that.contact_const.contact_qrcode, 2, 1200)
      })
    }
    ,
    goToDetail(id) {
      this.teacher_id = id
      // this.show_teacher_detail = true
      let new_id = crypto_utils.encrypt(randomString(8) + id.toString())
      // this.$router.replace({
      //   query: {'id': new_id}
      // })

      // 使用新打开页面
      let routeUrl = this.$router.resolve({
        path: "/discover",
        query: {id:new_id}
      });
      window.open(routeUrl.href, '_blank');
    }
    ,
    goBack() {
      this.show_teacher_detail = false
      if (this.is_jumped) {
        this.is_pre_select = true
        this.is_jumped = false
      }
      document.title = "在线择师——EasyPaper教育科技"
      this.$router.replace({
        query: {}
      })
    }
  }
}
</script>

<style scoped>
.filter_container {
  width: 500px;
}

.filter_container .filter_item {
  margin-bottom: 10px;
}

.filter_title {
  margin-right: 5px;
  margin-left: 6px;
  width: 100px;
  color: grey;
}

.white_div {
  background-color: white;
}

@media screen and (max-width: 600px) {
  .base_container {
    width: 95%;
  }

  .base_container2 {
    width: 95%;
  }

  .teacher_preview_container {
    width: 90%;
  }
}

@media screen and (min-width: 600px) {
  .base_container {
    width: 65%;
  }

  .base_container2 {
    width: 70%;
  }

  .teacher_preview_container {
    width: 450px;
  }
}
</style>