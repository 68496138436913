<template>
  <div class="page_basic_container center_col_container" v-loading="detailLoading" style="width: 100%;">


    <div class="hidden-sm-and-down center_col_container" style="width: 100%;">
      <div class="hover_shadow center_col_container" style="width: 85%;margin-top: 30px;border-radius: 20px">
        <el-alert
            :title="change_hint"
            type="warning">
        </el-alert>
        <div class="space_around_row_container" style="width: 100%;position: relative">
          <div style="width: 25%">
            <el-link @click="goBack" :underline="false" type="info"
                     style="font-size: 16px;color: black;margin-top: 7px;margin-left: 10px;font-weight: 600"><i
                class="el-icon-back">返回</i>
            </el-link>
            <div class="basic_information">
              <img class="avatar_image" style="width: 120px;height: auto"
                   :src="teacher.detailed_avatar_url">
              <div style="font-size: 20px;font-weight: 600">{{ teacher.nickName }}</div>
              <div style="font-size: 18px;margin-bottom: 5px;margin-top: 5px">{{ teacher.edu_degree }}
              </div>
              <div class="flex_start_col_container basic_description">
        <span style="font-size: 15px"><b>擅长科目：</b>{{ teacher.subject }}<template
            v-if="teacher.subject_supplement"> ({{ teacher.subject_supplement }})</template></span>
                <span class="price_container" style="font-size: 15px"><b>费用：</b>{{ teacher.min_price }}<template
                    v-if="teacher.max_price!==teacher.min_price">-{{ teacher.max_price }}</template>/小时</span>
                <template v-if="teacher.personal_tag">
                       <span><b>个人标签：</b>
                        <template v-for="(tag,idx) in teacher.personal_tag.split(',')">
              <el-tag style="margin-left: 5px;" v-if="idx%4===0" effect="dark" size="mini" type="danger">
                {{ tag }}
              </el-tag>
              <el-tag style="margin-left: 5px;" v-if="idx%4===1" effect="dark" size="mini"
                      type="light">
                {{ tag }}
              </el-tag>
              <el-tag style="margin-left: 5px;" v-if="idx%4===2" effect="dark" size="mini"
                      type="warning">
                {{ tag }}
              </el-tag>
              <el-tag style="margin-left: 5px;" v-if="idx%4===3" effect="dark" size="mini"
                      type="success">
                {{ tag }}
              </el-tag>
            </template>
                       </span>
                </template>

                <span v-if="teacher.authentication&&teacher.authentication!=='无'"><b>Ezp认证：</b>
                <el-tag style="margin-left: 5px;" effect="dark" size="mini" type="danger">
               {{ teacher.authentication }}
              </el-tag></span>
                <span v-if="teacher.start_time"><b>加入时间：</b>
              {{ teacher.start_time }}
            </span>
                <span><b>学生评分：</b> <el-rate
                    v-model="teacher.rating_grade/20"
                    style="margin-top: 5px"
                    disabled
                    :max="5"
                    show-score
                    text-color="#ff9900"
                    score-template="{value}">
              </el-rate>
              </span>
                <span><el-tag v-if="teacher.is_discount" effect="dark" size="mini" type="danger">提供折扣</el-tag>
          <el-tag style="margin-left: 5px;" v-if="teacher.is_price_changable" effect="dark" size="mini"
                  type="light">可议价</el-tag>
          <el-tag style="margin-left: 5px;" v-if="teacher.is_free_try" effect="dark" size="mini"
                  type="warning">免费试听</el-tag>
          <el-tag style="margin-left: 5px;" v-if="teacher.lesson_video_url" effect="dark" size="mini"
                  type="success">有试讲视频</el-tag></span>
                <span v-if="teacher.available_degree" style="margin-top: 10px"><b>可供约课时间：</b></span>
                <div v-if="teacher.available_degree"  class="flex_start_row_container" style="width: 100%;">
                  <el-progress :percentage="teacher.available_degree" :format="empty"
                               :color="customColors" style="width: 80%;margin-bottom: auto;margin-top: auto"></el-progress>
                  <span style="font-size: 10px;color: dimgray;margin-bottom: auto;margin-top: auto">{{ getDegreeHint(teacher.available_degree) + " (" + teacher.available_degree }}%)</span>
                </div>

              </div>
            </div>
          </div>
          <div class="split_line"></div>
          <div class="flex_start_col_container description" style="width: 75%;position: relative;padding-bottom: 50px">
            <div v-if="teacher.lesson_video_url!==''&&teacher.lesson_video_url"
                 class="description_block"><b style="font-size: 20px">试讲视频：</b>
              <br>
              <div>
                <iframe class="hover_shadow" style="height: 300px;width: 500px"
                        :src="teacher.lesson_video_url"
                        scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
              </div>
              <p style="color: dimgray;font-size: 13px">无法观看？可以联系人工小助手修复或发送备用链接</p>
            </div>
            <div class="description_block" v-if="teacher.characteristic_detail">
              <b style="font-size: 20px">风格简介：</b><br>
              <span v-html="teacher.characteristic_detail.replace(/\n/g,'<br>')"></span></div>
            <div class="description_block" v-if="teacher.education_detail">
              <b style="font-size: 20px">教育经历：</b><br>
              <span v-html="teacher.education_detail.replace(/\n/g,'<br>')"></span>
            </div>
            <div class="description_block" v-if="teacher.experience_detail">
              <b style="font-size: 20px">辅导经历：</b><br>
              <span v-html="teacher.experience_detail.replace(/\n/g,'<br>')"></span>
            </div>
            <div class="description_block" v-if="teacher.ability_display">
              <b style="font-size: 20px">能力展示：</b><br>
              <span v-html="teacher.ability_display.replace(/\n/g,'<br>')"></span>
            </div>
            <div class="description_block" v-if="teacher.past_grade_detail" style="width: 100%">
              <b style="font-size: 20px">过往成绩：</b><br>
              <div v-html="teacher.past_grade_detail.replace(/\n/g,'<br>')" style="width: 90%"></div>
            </div>
            <div class="description_block" v-if="teacher.reward_display">
              <b style="font-size: 20px">证书展示：</b><br>
              <span v-html="teacher.reward_display.replace(/\n/g,'<br>')"></span>
            </div>
            <div class="description_block" >
              <b style="font-size: 20px">收费定价：</b><br>
              <span><b>最低起约时间：</b>{{teacher.min_order_lesson_hour}}小时</span><br>
              <span><b>单次约课额外费用：</b>原价增加{{teacher.min_order_lesson_price_up}}%（单次约课指每次独立约课，一节课一付款）</span><br>
              <span v-if="teacher.price_detail" v-html="teacher.price_detail.replace(/\n/g,'<br>')"></span>
            </div>
            <div class="center_col_container" style="position: absolute;bottom: 30px;width: 100%;left: 0px">
              <el-button @click="contact" v-if="teacher.is_free_try" type="primary" round>立即咨询预约<b>免费试听</b></el-button>
              <el-button @click="contact" v-if="!teacher.is_free_try" type="primary" round>立即咨询小助手预约试听</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="hidden-md-and-up center_col_container" style="width: 100%;">
      <div class="hover_shadow center_col_container" style="width: 95%;margin-top: 30px;border-radius: 20px">
        <div class="center_col_container" style="width: 100%;position: relative">
          <el-alert v-if="teacher.available_degree"
                    :title="change_hint"
              type="warning">
          </el-alert>
          <div style="width: 100%">
            <el-link @click="goBack" :underline="false" type="info"
                     style="font-size: 16px;color: black;margin-top: 7px;margin-left: 10px;font-weight: 600"><i
                class="el-icon-back">返回</i>
            </el-link>
            <div class="basic_information">
              <img class="avatar_image" style="width: 120px;height: auto"
                   :src="teacher.detailed_avatar_url">
              <div style="font-size: 20px;font-weight: 600">{{ teacher.nickName }}</div>
              <div style="font-size: 18px;margin-bottom: 5px;margin-top: 5px">{{ teacher.edu_degree }}
              </div>
              <div class="flex_start_col_container basic_description">
        <span style="font-size: 15px"><b>擅长科目：</b>{{ teacher.subject }}<template
            v-if="teacher.subject_supplement"> ({{ teacher.subject_supplement }})</template></span>
                <span class="price_container" style="font-size: 15px"><b>费用：</b>{{ teacher.min_price }}<template
                    v-if="teacher.max_price!==teacher.min_price">-{{ teacher.max_price }}</template>/小时</span>

                <template v-if="teacher.personal_tag">
                       <span><b>个人标签：</b>
                        <template v-for="(tag,idx) in teacher.personal_tag.split(',')">
              <el-tag style="margin-left: 5px;" v-if="idx%4===0" effect="dark" size="mini" type="danger">
                {{ tag }}
              </el-tag>
              <el-tag style="margin-left: 5px;" v-if="idx%4===1" effect="dark" size="mini"
                      type="light">
                {{ tag }}
              </el-tag>
              <el-tag style="margin-left: 5px;" v-if="idx%4===2" effect="dark" size="mini"
                      type="warning">
                {{ tag }}
              </el-tag>
              <el-tag style="margin-left: 5px;" v-if="idx%4===3" effect="dark" size="mini"
                      type="success">
                {{ tag }}
              </el-tag>
            </template>
                       </span>
                </template>

                <span v-if="teacher.authentication&&teacher.authentication!=='无'"><b>Ezp认证：</b>
                <el-tag style="margin-left: 5px;" effect="dark" size="mini" type="danger">
               {{ teacher.authentication }}
              </el-tag></span>
                <span v-if="teacher.start_time"><b>加入时间：</b>
              {{ teacher.start_time }}
            </span>
                <span><b>学生评分：</b> <el-rate
                    v-model="teacher.rating_grade/20"
                    style="margin-top: 5px"
                    disabled
                    :max="5"
                    show-score
                    text-color="#ff9900"
                    score-template="{value}">
              </el-rate>
              </span>
                <span><el-tag v-if="teacher.is_discount" effect="dark" size="mini" type="danger">提供折扣</el-tag>
          <el-tag style="margin-left: 5px;" v-if="teacher.is_price_changable" effect="dark" size="mini"
                  type="light">可议价</el-tag>
          <el-tag style="margin-left: 5px;" v-if="teacher.is_free_try" effect="dark" size="mini"
                  type="warning">免费试听</el-tag>
          <el-tag style="margin-left: 5px;" v-if="teacher.lesson_video_url" effect="dark" size="mini"
                  type="success">有试讲视频</el-tag></span>
                <span v-if="teacher.available_degree" style="margin-top: 10px"><b>可供约课时间：</b></span>
                <div v-if="teacher.available_degree" class="flex_start_row_container" style="width: 100%;">
                  <el-progress :percentage="teacher.available_degree" :format="empty"
                               :color="customColors" style="width: 80%;margin-bottom: auto;margin-top: auto"></el-progress>
                  <span style="font-size: 10px;color: dimgray;margin-bottom: auto;margin-top: auto">{{ getDegreeHint(teacher.available_degree) + " (" + teacher.available_degree }}%)</span>
                </div>
              </div>
            </div>
            <div v-if="teacher.lesson_video_url!==''&&teacher.lesson_video_url"
                 class="description_block"><b style="font-size: 20px">试讲视频：</b>
              <br>
              <div>
                <iframe class="hover_shadow" style="height: 300px;width: 500px"
                        :src="teacher.lesson_video_url"
                        scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
              </div>
              <p style="color: dimgray;font-size: 13px">无法观看？可以联系人工小助手修复或发送备用链接</p>
              <!--              <a>{{ teacher.lesson_video_url }}</a>-->
            </div>

          </div>
          <div class="center_col_container" style="width: 90%;padding-bottom: 100px;">
            <div class="flex_start_col_container" style="width: 100%">
              <div class="description_block" v-if="teacher.characteristic_detail">
                <b style="font-size: 20px">风格简介：</b><br>
                <span v-html="teacher.characteristic_detail.replace(/\n/g,'<br>')"></span></div>
              <br>
              <div class="description_block" v-if="teacher.education_detail">
                <b style="font-size: 20px">教育经历：</b><br>
                <span v-html="teacher.education_detail.replace(/\n/g,'<br>')"></span>
              </div>
              <br>
              <div class="description_block" v-if="teacher.experience_detail">
                <b style="font-size: 20px">辅导经历：</b><br>
                <span v-html="teacher.experience_detail.replace(/\n/g,'<br>')"></span>
              </div>
              <br>
              <div class="description_block" v-if="teacher.ability_display">
                <b style="font-size: 20px">能力展示：</b><br>
                <span v-html="teacher.ability_display.replace(/\n/g,'<br>')"></span>
              </div>
              <br>
              <div class="description_block" v-if="teacher.past_grade_detail" style="width: 100%">
                <b style="font-size: 20px">过往成绩：</b><br>
                <div v-html="teacher.past_grade_detail.replace(/\n/g,'<br>')" style="width: 90%"></div>
              </div>
              <br>
              <div class="description_block" v-if="teacher.reward_display">
                <b style="font-size: 20px">证书展示：</b><br>
                <span v-html="teacher.reward_display.replace(/\n/g,'<br>')"></span>
              </div>
              <br>
              <div class="description_block" >
                <b style="font-size: 20px">收费定价：</b><br>
                <span><b>最低起约时间：</b>{{teacher.min_order_lesson_hour}}小时</span><br>
                <span><b>单次约课额外费用：</b>原价增加{{teacher.min_order_lesson_price_up}}%（单次约课指每次独立约课，一节课一付款）</span><br>
                <span v-if="teacher.price_detail" v-html="teacher.price_detail.replace(/\n/g,'<br>')"></span>
              </div>
              <br>
              <div class="center_col_container" style="position: absolute;bottom: 30px;width: 100%;left: 0px">
                <el-button @click="contact" v-if="teacher.is_free_try" type="primary" round>立即咨询预约试听</el-button>
                <el-button @click="contact" v-if="!teacher.is_free_try" type="primary" round>立即咨询小助手</el-button>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="split_line"></div>-->
        <!--        <div class="center_col_container" style="width: 100%">-->
        <!--          <div class="flex_start_col_container description"-->
        <!--               style="width: 90%;position: relative;padding-bottom: 50px;padding-right: 20px">-->

        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {get_teacher_detail} from "@/utils/LSP_utils";
import ServiceDialogBox from "@/components/ServiceDialogBox";
import Vue from "vue";

export default {
  name: "TeacherDetail",
  components: {ServiceDialogBox},
  props: ["id", "target_teacher"],
  data() {
    return {
      customColors: [
        {color: '#f56c6c', percentage: 40},
        {color: '#e6a23c', percentage: 60},
        {color: '#1989fa', percentage: 70},
        {color: '#6f7ad3', percentage: 80},
        {color: '#5cb87a', percentage: 90}
      ],
      change_hint:"近期对试听方案、定价收费规则有所修改。可以参考FAQ常见问答与相关页面，详细咨询EasyPaper小助手。",
      teacher: {
        "id": 10,
        "nickName": "加载中...",
        "avatar_url": "https://easy-paper.com/download/avatar/ezp_logo.png",
        "is_online": 1,
        "edu_degree": "加载中...",
        "subject": "加载中...",
        "subject_cata": "Language",
        "add_in_time": "2021-06-30T22:38:34.000Z",
        "detailed_avatar_url": "https://easy-paper.com/download/avatar/ezp_logo.png",
        "education_detail": "示例老师",
        "experience_detail": "加载中...",
        "characteristic_detail": "加载中...",
        "ability_display": "加载中...",
        "reward_display": "",
        "ranking": 100,
        "rating_grade": 0,
        "max_price": 100,
        "min_price": 100,
        "price_detail": "",
        "is_discount": 1,
        "is_price_changable": 1,
        "past_grade_detail": "",
        "is_free_try": 0,
        "lesson_video_url": null,
        "subject_supplement": null,
        "authentication": "加载中...",
        "comment_detail": "",
        "like_counts": 0,
        "start_time": null,
        "view_counts": 5,
        "personal_tag": "",
        "available_degree":85,
      },
      detailLoading: false,
    }
  },
  mounted() {
    if (this.target_teacher) {
      this.teacher = this.target_teacher
    } else {
      get_teacher_detail(this.id, this)
    }

  },
  methods: {
    empty(pecentage){
      return ""
    },
    getDegreeHint(percentage) {
      percentage = parseFloat(percentage)
      if (percentage === 0) {
        return "不可约"
      } else if (percentage > 0 && percentage <= 20) {
        return "较难约"
      } else if (percentage > 20 && percentage <= 40) {
        return "难约"
      } else if (percentage > 40 && percentage <= 60) {
        return "可试约"
      } else if (percentage > 60 && percentage <= 80) {
        return "可约"
      } else if (percentage > 80 && percentage <= 100) {
        return "完全可约"
      }
    },
    changeTitle() {
      document.title = this.teacher.nickName + " " + this.teacher.edu_degree + "  领先的国际教育辅导网站——EasyPass"
    },
    goTop() {
      window.scrollTo(0, 0);
    },
    goBack() {
      this.$emit("goBack")
    },
    contact() {
      if(Vue.prototype.is_login){
        this.goTop()
        this.$emit("contact")
      }else{
        this.$router.replace({
          path: '/user/login',
          query: {
            redirect: this.$router.currentRoute.fullPath,
            message: "请先登陆！"
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.description {
  padding: 20px;
  padding-left: 60px;
}

.description .description_block {
  margin-bottom: 20px;
}

.basic_description span {
  margin-bottom: 6px;
}

.basic_information {
  padding: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.split_line {
  background-color: gray;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 1px;
  height: auto;
}

.description_block img {
  width: 90%;
}
</style>