import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
import ElementUI from 'element-ui';
import './config/global.css'
import './config/user.css'
import routers from "@/router";
import configs from "@/config/configs";
import VueRouter from 'vue-router'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueCookies from "vue-cookies";
import SlideVerify from 'vue-monoplasty-slide-verify';
import QRCode from 'qrcodejs2'
Vue.prototype.$qrCode = QRCode;
Vue.use(VueCookies);
Vue.use(SlideVerify);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.prototype.axios = axios
Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(configs);

import ICS from 'vue-ics'

let options = {uidDomain: "easypass.zqwei-tech.cn"}
Vue.use(ICS, options)
import fullCalendar from 'vue-fullcalendar'
Vue.component('full-calendar', fullCalendar)

const router = new VueRouter({
    mode: 'history',
    routes: routers
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展

    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    // console.log("da")
                    Vue.prototype.$EventBus.$emit("login_status", false);
                    router.replace({
                        path: '/user/login',
                        query: {
                            redirect: router.currentRoute.fullPath,
                            message: "请先登陆！"
                        }
                    });
                    break;

            }
            return Promise.reject(error.response);
        }
    })

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
