<template>
  <div class="center_col_container" style="padding: 30px">
    <div class="center_col_container whole">
      <div class="flex_start_col_container boxes" style="padding: 30px">
        <span style="font-size: 17px;margin-bottom: 10px;color: #333333">尊敬的%s你好~</span>
        <span style="color: dimgray;font-size: 15px;padding: 20px">
          %s
        </span>
      </div>
      <div class="center_col_container">
        <img src="../assets/Easypaper.png" alt="easypaper logo" class="rounded-circle img-fluid" style="width:40px">
        <span style="font-size: 12px;color: dimgray">EasyPaper科技</span>
        <div class="space_between_row_container bottom_a">
          <a href="https://easytutor.zqwei-tech.cn/contact">联系我们</a>
          <a href="https://easy-paper.com">网站主页</a>
          <a href="https://easytutor.zqwei-tech.cn/product">产品主页</a>
        </div>
        <span
            style="font-size: 9px;color: gray;padding: 3px">Copyright @EasyPaper ©2020-2021 All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Email"
}
</script>

<style scoped>
.bottom_a {
  font-size: 13px;
  color: #8c939d;
}

.whole {
  width: 75%;
  padding: 30px;
  border-width: 30px 2px 2px 2px;
  border-style: solid;
  border-color: rgb(214, 232, 192);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgb(214, 232, 192), 0 6px 6px rgb(63, 130, 76);
}

.boxes {
  border: 2px dotted rgb(214, 232, 192);
  border-radius: 20px;
  /*padding: 30px;*/
  margin: 20px;
  width: 75%;
  background-color: rgba(214, 232, 192, 0.25)
}

h2 {
  font-size: 35px;
  color: rgb(63, 130, 76);
}

p {
  font-size: 20px;
}

button {
  background-color: #fdb6cd;
  border: white;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
}

a {
  color: dimgray;
  padding: 5px;
}

.color_bar{
  height: 4px;
  background-color: gray;
}

.space_between_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.space_between_start_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
}

.close_start_row_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
}

.hover_shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.hover_shadow:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.flex_start_col_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.center_col_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*.center_start_col_container{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*}*/

.space_around_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}

.flex_start_row_container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.flex_end_row_container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
}

.flex_end_col_container{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
}

.flex_start_center_row_container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
}

.flex_start_center_col_container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

</style>