<template>
  <div class="center_col_container" style="width: 100%">
    <BackgroundHeader text="学生信息登记"/>
    <div class="hidden-sm-and-down center_col_container" style="width: 100%">
      <StudentRegister style="width: 40%"/>
    </div>
    <div class="hidden-md-and-up center_col_container">
      <StudentRegister/>
    </div>
  </div>
</template>

<script>
import StudentRegister from "@/components/StudentRegister";
import BackgroundHeader from "@/components/BackgroundHeader";

export default {
  name: "StudentPage",
  components: {BackgroundHeader, StudentRegister}
}
</script>

<style scoped>

</style>