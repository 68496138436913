<template>
  <div class="center_col_container">
    <BackgroundHeader text="常见问答 FAQ"/>
    <div class="hidden-md-and-up">
      <div class="center_col_container">
        <div class="flex_start_col_container" style="width: 90%;padding: 50px;padding-top: 70px">
          <van-collapse v-model="activeNames">
            <template v-for="(faq_item ,idx) in faq">
              <van-collapse-item :name='idx' style="padding-bottom: 10px">
                <template #title>
                  <div class="faq_title2">{{ faq_item[0] }}</div>
                </template>
                <span style="color:  #909399;line-height: 1.9" v-html="getBrText(faq_item[1])">
                </span>
              </van-collapse-item>
            </template>
          </van-collapse>
        </div>
        <div class="flex_start_col_container" style="width: 90%;padding: 50px">
          <div class="center_col_container">
            <span class="faq_title2">👇👇👇其他问题欢迎咨询小助手👇👇👇</span>
          </div>
          <ServiceDialogBox width="100%" height="400px" :showClose="false"/>
        </div>
      </div>
    </div>
    <div class="hidden-sm-and-down">
      <div class="space_around_row_container">
        <div class="flex_start_col_container" style="width: 50%;padding: 50px;padding-top: 70px">
          <van-collapse v-model="activeNames">
            <template v-for="(faq_item ,idx) in faq">
              <van-collapse-item :name='idx' style="padding-bottom: 10px">
                <template #title>
                  <div class="faq_title">{{ faq_item[0] }}</div>
                </template>
                <span style="color:  #909399;line-height: 1.9" v-html="getBrText(faq_item[1])">
                </span>

              </van-collapse-item>
            </template>
          </van-collapse>
        </div>
        <div class="flex_start_col_container" style="width: 50%;padding: 50px">
          <div class="center_col_container">
            <span class="faq_title">👇👇👇其他问题欢迎咨询小助手👇👇👇</span>
          </div>
          <ServiceDialogBox width="100%" height="400px" :showClose="false"/>
        </div>
      </div>
    </div>
    <LessonProcessDisplay/>
  </div>
</template>

<script>
import ServiceDialogBox from "@/components/ServiceDialogBox";
import BackgroundHeader from "@/components/BackgroundHeader";
import ProcessDisplay from "@/components/ProcessDisplay";
import LessonProcessDisplay from "@/components/display_module/LessonProcessDisplay";

export default {
  name: "FAQ",
  components: {LessonProcessDisplay, ProcessDisplay, BackgroundHeader, ServiceDialogBox},
  data() {
    return {
      activeNames: ['1'],
      faq: [["如何预约试听?可以免费试听吗？", "大部分老师会提供免费试听，试听时间是由老师和学生的具体时间约定决定，" +
      "一般是试讲30分钟。具体操作只需要添加我们的小助手就可以了，微信号zqwei-tech，也可以问AI客服要微信二维码的！"],
          ['免费试听是什么流程？',"免费试听本身是免费的，但需要缴纳一小部分试听课押金，具体试听课押金规则如下：" +
          "\n\n因为自免费试听开始后，很多老师明明讲的很好但是学生因为各种需求不明确、突然失联、时间冲突、或者其他个人原因导致不来了，特别是很多学生都觉得老师们的付出都很无所谓而白白消耗老师宝贵的试听课程时间和与之对应的备课时间，所以自本规则开始后拉高免费试听课的门槛，杜绝无效的免费试听白白消耗老师们的时间和精力，故增加押金规则。\n" +
          "\n" +
          "1.押金约为30min试听费课时费附近价格，一般为200-500元之间不等\n" +
          "2.如果明确是老师水平问题不行导致后期不打算跟进，确认后系统自动原路退还押金。如果是其他个人原因导致不跟进，押金部分将被当作按正常课时计费。\n" +
          "3.如果后期决定长期跟进（报课大于最低约课时长），押金部分可以抵扣对应的金额，故此30min的试听课为免费试听课。"],
          ["如何使用EasyPaper网校系统？有没有教程？","有的，可以在 https://easytutor.zqwei-tech.cn/tutorial 查看视频教程，也可以在用户中心中点击使用教程进行查看完整教程。"],
        ["老师价格是怎么回事，为啥有些有个区间段？", "老师的价格是由老师自身在平台入驻的时候决定的，区间段一般是由老师" +
        "不同辅导的课程所确定，如雅思299/小时，但是面试辅导则是349/小时。"],
          ["最低约课时长是什么？单独约课的规则是？","免费试听的初衷是为了有长期上课需求的学生确认是否合适而设立的，但此前总有学生借着免费试听的方法只上非常少的课时而后就失联了，这对平台老师是极不公平的，故仅对超过最低约课时长的学生采用标注原价格，对于单独约课的小额订单则需要收取额外费用，具体详见每个老师的页面底部收费信息，或咨询小助手详情。"],
        ["老师都靠谱吗？", "我们老师的信息均公开、透明，个人实力、成绩以及过往的辅导经验均一目了然，以平台海量学生的历史反馈为质量做背书，且大部分支持免费试听，先试试看总归不亏的嘛~"],
        ["怎么上课的？", "我们平台提供的是在线课程，主要是使用腾讯会议/zoom并辅助EasyPaper CMS网校系统进行排课教学"],
        ["可以优惠吗，什么是可议价/提供折扣？", "我们一般是多报多优惠的！可议价和提供折扣一般是指可以和老师协商是否存在一种模式来降低" +
        "原本老师的价格，如一次性多报课，特殊辅导服务等。"],
        ["退费政策是什么？", "如果是老师水平的问题，我们支持中途退费（按原始课时非优惠原价计费退剩余差额），也可以通过我们退还/补齐差价更换其他老师。但为了保护入驻老师的权益，无理由/个人原因/非老师水平问题（如突然不想上了、自身计划有变化等）的退费，不支持退费，仅接受转课至下一名学生。" +
        ""],
        ["提供什么课程？", "可以在我们的择师页面上看到的！点击”具体学科“的展示按钮可以显示详尽的学科分类。"],
        ["有雅思PTE或者竞赛吗？", "有的，可以在平台上自由选择，也可以咨询一下我们的人工小助手（微信号：zqwei-tech）。"],
        ["可以换老师吗？", "可以的！转课剩余的差价可以补齐~"],
        ["一节课多久？", "这个要看老师和学生之间的选择，只要商议好就可以，一般是2小时居多，也有1-1.5小时的，" +
        "详细对接或者咨询的事宜要和我们的小助手联系对接。"]
      ],
    }
  },
  //老师均仅希望接受长期学生，不能接受不是自己原因导致的退费，特别是为了防止贪图小便宜的人通过多报课的优惠折扣，但是又实际只上几节课就要退费，损害平台和老师的部分是不退哦~
  methods:{
    getBrText(text){
      return text.replace(/\n/g, '<br>')
    }
  }
}
</script>

<style scoped>
.faq_title {
  font-size: 25px;
  color: #606266;
  font-weight: 300;
}

.faq_title2{
  font-size: 18px;
  color: #606266;
  font-weight: 300;
}
</style>