<template>
  <van-image style="width: 100%;"
             class="clickable_item"
             :src="src"
             @click="previewImage"
  >

    <template v-slot:loading>
      <div class="margin-top:20px;margin-bottom:20px">
        <van-loading type="spinner" size="40"/>
      </div>
    </template>
  </van-image>
</template>

<script>
import {ImagePreview} from "vant";
import Vue from "vue";

export default {
  name: "DisplayImage",
  props: ['src'],
  methods:{
    previewImage() {
      this.previewInstance = ImagePreview({
        images: [this.src], closeable: true
      });
    },
  }
}
</script>

<style scoped>

</style>