<template>
  <div class="center_col_container">
    <el-steps align-center class="center_row_container" :active="active" finish-status="success"
              style="width: 100%">
      <template v-for="(item,idx) in info">
      <el-step :title="item['title']" :description="item['des']" >
      </el-step>
      </template>
    </el-steps>
    <div class="flex_start_col_container" v-for="(html,idx) in info" style="width: 100%">
      <span class="text_container" v-if="idx===active" v-html="getBrText(html['html'])"></span>
    </div>
    <div class="space_between_row_container" style="margin-top: 15px">
      <el-button @click="active--" v-if="active>0">上一步</el-button>
      <el-button @click="active++" v-if="active<info.length-1">下一步</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessDisplay",
  props:["info"],
  data() {
    return {
      active: 0
    }
  },
  methods:{
    getBrText(text){
      return text.replace(/\n/g, '<br>')
    }
  }
}
</script>

<style scoped>

@media screen and (max-width: 600px) {
  .text_container{
    font-size: 15px;
    color: dimgray;
    line-height: 30px;
    padding: 10px;
  }
}

@media screen and (min-width: 600px) {
  .text_container{
    font-size: 20px;
    color: dimgray;
    line-height: 50px;
    padding: 25px;
  }
}
</style>