<template>
  <div class="center_col_container page_basic_container" style="padding: 60px">
    <div style="padding: 15px;">
      <i class="el-icon-circle-check" style="font-size: 60px;color: green"></i>
    </div>
    <span style="font-size: 30px;font-weight: 500">支付成功</span>
    <span style="font-size: 16px;font-weight: 400;color: dimgray;margin-top: 10px">课程付款成功！请按时上课噢~</span>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess"
}
</script>

<style scoped>

</style>