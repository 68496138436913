<template>
  <div class="center_col_container hover_shadow" style="padding: 50px;border-radius: 30px">
    <img src="../assets/small_e/correct2.jpg" style="width: 200px;height: auto;margin-bottom: 10px">
    <span style="font-size: 20px;font-weight: 600">{{ user.username }}</span>
    <span style="font-size: 13px;color: #8c939d" v-if="user.status">已激活认证</span>
    <span style="font-size: 13px;color: #8c939d" v-if="!user.status">未认证</span>
    <div class="flex_start_col_container user_info" style="margin-top: 10px">
      <span class="label">用户名 <span class="content">{{ user.username }}</span></span>
      <span class="label">邮箱 <span class="content">{{ user.email }}</span></span>
      <span class="label">注册时间 <span class="content">{{ getDisplayDate(user.date_joined) }}</span></span>
      <span class="label">最后登陆时间 <span class="content">{{ getDisplayDate(user.last_login) }}</span></span>
      <div class="center_col_container">
        <el-button @click="changePass" round type="primary" style="margin-top: 10px;width: 100%">修改密码</el-button>
        <el-button v-if="!finance.status" @click="updateFinance" round type="danger"
                   style="margin-top: 10px;width: 100%">立即完善财务信息
        </el-button>
        <el-button v-else @click="showFinance" round type="danger" style=";margin-top: 10px;width: 100%">查看财务信息
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherUserInfo",
  props: ["user", "finance"],
  methods: {
    changePass() {
      this.$emit("change_pass")
    },
    showFinance() {
      this.$emit("show_finance")
    },
    updateFinance() {
      this.$router.push("/user/financial")
    },
    getDisplayDate(time) {
      let time_obj = new Date(time)
      return time_obj.toLocaleString()
    },
  }
}
</script>

<style scoped>
.user_info .label {
  padding: 10px;
  color: #111111;
  font-weight: bold;
  font-size: 16px;
}

.user_info .label .content {
  margin-left: 5px;
  color: dimgray;
  font-size: 14px;
}
</style>