import Vue from "vue";
import axios from "axios";

export function get_combinations(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/check_student_combination/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
            }
        )
        .catch(function (error) { // 请求失败处理
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function get_lessons(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/get_student_lessons/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
            }
        )
        .catch(function (error) { // 请求失败处理
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function get_student_info(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/get_student_info/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
            }
        )
        .catch(function (error) { // 请求失败处理
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function get_user_center(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/get_user_center/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
            that.loading = false
            if (res.data.mode === "visitor") {
                that.$router.push("/user/role_register")
                return
            }
            // console.log(res.data)
            let lessons = res.data.info.lessons
            let lesson_table = []
            let combinations = res.data.info.combinations
            let combination_dict = {}
            for (var idx in combinations) {
                combination_dict[combinations[idx].id] = combinations[idx]
            }
            for (var idx in lessons) {
                let lesson = lessons[idx]
                let start_time = new Date(lesson["start_time"])
                let finish_time = new Date(lesson["finish_time"])
                if (lesson["lesson_type"] === "trial") {
                    lesson["subject"] = lesson["subject"] + "(试听课)"
                } else if (lesson["lesson_type"] === "class") {
                    lesson["subject"] = lesson["subject"] + "(班课)"
                }
                lesson["start_time"] = start_time.toLocaleTimeString()
                lesson["start_date"] = start_time.toLocaleDateString()
                lesson["finish_time"] = finish_time.toLocaleTimeString()
                if (!lesson['trial_student_name'] === '') {
                    lesson['trial_student'] = "未命名"
                } else {
                    lesson['trial_student'] = lesson['trial_student_name']
                }
                if (res.data.mode === 'teacher') {
                    if (lesson['combination']) {
                        lesson['student'] = combination_dict[lesson['combination']]['student']
                        lesson['student_name'] = combination_dict[lesson['combination']]['student'].name
                    } else if (lesson.lesson_type === "trial") {
                        lesson['student'] = {"name": lesson['trial_student']}
                        lesson['student_name'] = lesson['trial_student']
                    } else if (lesson.lesson_type === "class") {
                        lesson['student'] = {"name": "/"}
                        lesson['student_name'] = "/"
                    } else {
                        lesson['student'] = {"name": "未知"}
                        lesson['student_name'] = "未知"
                    }
                }
                lesson_table.push(lesson)
            }
            let f_lessons = res.data.info.f_lessons
            let f_lesson_table = []
            for (var idx in f_lessons) {
                let f_lesson = f_lessons[idx]
                let start_time = new Date(f_lesson["start_time"])
                let finish_time = new Date(f_lesson["finish_time"])
                if (f_lesson["lesson_type"] === "trial") {
                    f_lesson["subject"] = f_lesson["subject"] + "(试听课)"
                } else if (f_lesson["lesson_type"] === "class") {
                    f_lesson["subject"] = f_lesson["subject"] + "(班课)"
                }
                f_lesson["start_time"] = start_time.toLocaleTimeString()
                f_lesson["start_date"] = start_time.toLocaleDateString()
                f_lesson["finish_time"] = finish_time.toLocaleTimeString()
                if (!f_lesson['trial_student']) {
                    f_lesson['trial_student'] = "未命名"
                }
                if (res.data.mode === 'teacher') {
                    if (f_lesson['combination']) {
                        f_lesson['student'] = combination_dict[f_lesson['combination']]['student']
                        f_lesson['student_name'] = combination_dict[f_lesson['combination']]['student'].name
                    } else if (f_lesson.lesson_type === "trial") {
                        f_lesson['student'] = {"name": f_lesson['trial_student']}
                        f_lesson['student_name'] = f_lesson['trial_student']
                    } else if (f_lesson.lesson_type === "class") {
                        f_lesson['student'] = {"name": "/"}
                        f_lesson['student_name'] = "/"
                    } else {
                        f_lesson['student'] = {"name": "未知"}
                        f_lesson['student_name'] = "未知"
                    }
                }
                f_lesson_table.push(f_lesson)
            }

            let info = res.data.info
            info.lessons = lesson_table
            info.f_lessons = f_lesson_table
            info["combination_dict"] = combination_dict
            that.user_type = res.data.mode
            if (res.data.mode === "student") {
                that.$EventBus.$emit("student_user_center", info);
            } else if (res.data.mode === "teacher") {
                that.$EventBus.$emit("teacher_user_center", info);
            }
        })
        .catch(function (error) { // 请求失败处理
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function confirm_lesson_time(lesson_id, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/confirm_lesson_time/"
    that.dialog_loading = true
    axios
        .get(url, {params: {lesson: lesson_id}, withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
                get_user_center(that)
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            that.orderTimeDialogVisible = false
        })
        .catch(function (error) { // 请求失败处理
            that.loading = false
            that.dialog_loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function deconfirm_lesson_time(lesson_id, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/deconfirm_lesson_time/"
    that.dialog_loading = true
    axios
        .get(url, {params: {lesson: lesson_id}, withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
                get_user_center(that)
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            if (res.data.status) {
                that.time_setting_lesson_id = lesson_id
                that.timeSettingDialogVisible = true
            }
            that.orderTimeDialogVisible = false
        })
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function notice_teacher_provide_info(lesson_id, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/notice_teacher_provide_info/"
    that.dialog_loading = true
    axios
        .get(url, {params: {lesson: lesson_id}, withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
            }
            that.checkInfoDialogVisible = false
            that.lessonInfoDialogVisible = false
        })
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function download_user_ics(that) {
    let ics_url = Vue.prototype.api.pyroot_url + "netschool/api/get_user_ics/"
    const url = Vue.prototype.api.pyroot_url + "netschool/user/login_status/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.status) {
                    window.open(ics_url, '_blank')
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function submit_for_lesson_info(lesson_id, info, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/submit_for_lesson_info/"
    that.dialog_loading = true
    var formData = new FormData();
    for (let key in info) {
        formData.append(key, info[key])
    }
    formData.append("lesson", lesson_id)
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
                get_user_center(that)
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            that.lessonSettingDialogVisible = false
        })
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function reorder_lesson_time(lesson_id, timestamp, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/reorder_lesson_time/"
    that.dialog_loading = true
    var formData = new FormData();
    formData.append("time", timestamp)
    formData.append("lesson", lesson_id)
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
                get_user_center(that)
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            that.timeSettingDialogVisible = false
        })
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function create_new_lesson(com_id, timestamp, duration_hour, that, lessonset = null) {
    const url = Vue.prototype.api.pyroot_url + "netschool/create_new_lesson/"
    that.dialog_loading = true
    var formData = new FormData();
    formData.append("duration_hour", duration_hour)
    formData.append("start_time", timestamp)
    formData.append("combination", com_id)
    if (lessonset) {
        formData.append("lessonset", lessonset)
    }
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
                get_user_center(that)
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            that.orderLessonDialogVisible = false
        })
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function update_financial_info(info, that) {
    const url = Vue.prototype.api.pyroot_url + "finance/update_financial_info/"
    that.loading = true
    var formData = new FormData();
    for (let key in info) {
        formData.append(key, info[key])
    }
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
            that.loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            if (res.data.status) {
                that.entering = false
            }
        })
        .catch(function (error) { // 请求失败处理
            that.loading = false
            that.$message.error("网络链接不畅！")
        });
}

export function submit_feedback(lesson_id, feedback, that, rating = -1) {
    const url = Vue.prototype.api.pyroot_url + "netschool/submit_for_feedback_info/"
    that.dialog_loading = true
    var formData = new FormData();
    if (rating !== -1) {
        formData.append("rating", rating)
    }
    formData.append("lesson", lesson_id)
    formData.append("feedback", feedback)
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
            that.dialog_loading = false
            if (res.data.successMsg) {
                that.$message.success(res.data.successMsg)
                get_user_center(that)
                that.feedbackEnterDialogVisible = false
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg)
            }
            that.lesson_feedback = ""
            if (rating !== -1) {
                that.lesson_rating = 5
            }
        })
        .catch(function (error) { // 请求失败处理
            that.dialog_loading = false
            that.$message.error("网络链接不畅！")
        });
}