<template>
  <div class="background" style="width: 100%;height: 450px">
    <div class="overlay hidden-sm-and-down" style="position: relative"  >
      <span style="position: absolute;bottom: 120px;left: 140px;font-size: 40px;font-weight: 600;color: white">
        {{ text }}
      </span>
    </div>
    <div class="overlay hidden-md-and-up center_col_container" style="position: relative"  >
      <span style="font-size: 40px;font-weight: 600;color: white">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundHeader",
  props: ["text"]
}
</script>

<style scoped>
.background {
  background: url("https://wp.easypass.zqwei-tech.cn/wp-content/themes/consultup/images/sub-header.jpg") repeat scroll center 0 #143745;
}

.overlay {
  background: linear-gradient(to bottom, #051b44 0%, rgba(5, 27, 68, 0.5) 50%, rgba(5, 27, 68, 0.8) 100%);
  height: 100%;
  position: relative;
  width: 100%;
}

</style>