<template>
  <div class="center_col_container page_basic_container" style="padding: 20px;padding-top: 40px" v-loading="loading">
    <div class="flex_start_row_container"
         style="padding: 20px;width: 80%;border-radius: 10px;background-color: #f1f1f1;">
      <div style="padding: 15px;">
        <i class="el-icon-circle-check" style="font-size: 60px;color: green"></i>
      </div>
      <div style="margin: auto;margin-left: 10px" class="flex_start_col_container">
        <span style="font-size: 18px;margin-bottom: 10px">你的课程排入课表系统！请尽快完成课时费付款</span>
        <span style="font-size: 16px">金额：<span style="font-size: 18px;color: red">￥{{ fee }}</span></span>
      </div>
    </div>
    <!--    <div class="flex_start_row_container"-->
    <!--         style="padding: 20px;width: 80%;border-radius: 10px;background-color: #f1f1f1;">-->
    <!--      <span style="font-size: 15px;color: dimgray;margin-bottom: 20px;padding: 3px">请使用下面的付款方式</span>-->
    <!--    </div>-->
    <el-dialog
        title="微信扫码支付"
        :visible.sync="showQRCode"
        width="30%"
        :before-close="handleClose">
      <div class="center_col_container">
        <div style="width: 45%;padding-right: 20px">
          <DisplayImage style="padding-right: 20px"
                        :src="'https://pyserver.easypass.zqwei-tech.cn/qrcode/?url='+wechat_qrcode_data"
          ></DisplayImage>
        </div>
        <span style="font-size: 16px;margin-bottom: 3px;font-weight: 550">请打开手机微信，摄像头扫码支付</span>
        <span>（<b style="color: red">*</b>不可通过保存二维码图片然后相册扫码进行支付）</span>
        <span style="margin-top: 10px">倒计时 {{ showCountDown(count_down_minute) }}:{{
            showCountDown(count_down_second)
          }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="showQRCode = false">取 消</el-button>
  </span>
    </el-dialog>
    <div class="flex_start_col_container" style="width: 80%;padding-top: 40px;margin-left: -25px">
      <div class="flex_start_col_container" style="margin-right: auto">
        <span class="hidden-sm-and-down" style="font-size: 15px;color: dimgray;margin-bottom: 20px;padding: 3px">请使用下面的付款方式</span>
        <span class="hidden-md-and-up" style="font-size: 15px;color: dimgray;margin-bottom: 20px;padding: 3px">请使用下面的付款方式（仅电脑网页支持微信支付，推荐使用电脑端进行支付！）</span>

        <div class="flex_start_row_container clickable_item hidden-sm-and-down ">
          <div class="space_around_row_container hover_shadow" @click="goToAliPay"
               style="padding: 5px;padding-left: 10px;padding-right: 10px;border-radius: 10px">
            <img src="../../assets/pay/alipay.png" alt="支付宝" style="width: 100px;height: auto">
            <div>
              <img src="../../assets/pay/按钮标签.png" alt="支付宝" style="width: 20px;height: auto">
            </div>
          </div>
          <div class="space_around_row_container hover_shadow" @click="wechatPay"
               style="padding: 5px;padding-left: 10px;padding-right: 10px;border-radius: 10px;margin-left: 20px">
            <img src="../../assets/pay/wechat.jpg" alt="微信支付" style="width: 100px;height: auto">
            <div>
              <img src="../../assets/pay/按钮标签.png" alt="微信支付" style="width: 20px;height: auto">
            </div>
          </div>
        </div>
        <div class="flex_start_row_container clickable_item hidden-md-and-up" @click="goToAliPay">
          <div class="space_around_row_container hover_shadow"
               style="padding: 5px;padding-left: 10px;padding-right: 10px;border-radius: 10px">
            <img src="../../assets/pay/alipay.png" alt="支付宝" style="width: 50px;height: auto">
            <div>
              <img src="../../assets/pay/按钮标签.png" alt="支付宝" style="width: 10px;height: auto">
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top:60px;">
        <el-button @click="payUseless" class="hidden-sm-and-down" type="primary" round>立即付款</el-button>
        <el-button @click="goToAliPay" class="hidden-md-and-up" type="primary" round>立即付款</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_transfer_payment_url,
  get_wechat_native_transfer_payment_data,
  pay_for_out_trade_no
} from "@/utils/payment_utils";
import DisplayImage from "@/components/utils/DisplayImage";

const timeout_min = 5
const timeout_sec = 0
export default {
  name: "PaymentCenter",
  components: {DisplayImage},
  data() {
    return {
      loading: true,
      pay_url: "",
      fee: 0,
      subject: "",
      wechat_qrcode_data: "",
      showQRCode: false,
      count_down_minute: timeout_min,
      count_down_second: timeout_sec,
      out_trade_no: ""
    }
  },
  methods: {
    payUseless() {
      this.$message.warning("请选择使用微信支付或支付宝支付完成本次付款！")
    },
    goToAliPay() {
      if (!this.out_trade_no) {
        get_transfer_payment_url(this.fee, this.subject, this)
      } else {
        pay_for_out_trade_no(this, this.out_trade_no, "alipay")
      }
    },
    wechatPay() {
      if (this.wechat_qrcode_data) {
        this.showQRCode = true
      } else {
        if (this.out_trade_no) {
          pay_for_out_trade_no(this, this.out_trade_no, "wechat")
        } else {
          get_wechat_native_transfer_payment_data(this.fee, this.subject, this)
        }
      }
    },
    showCountDown(input) {
      if (input.toString().length === 1) {
        return "0" + input.toString()
      }
      return input.toString()
    },
    countDown() {
      let that = this
      if (this.count_down_second <= 0) {
        if (this.count_down_minute <= 0) {
          // 超时
          this.$message.error("微信支付超时！请重新点击付款！")
          this.showQRCode = false
          this.wechat_qrcode_data = ""
          setTimeout(function () {
            that.count_down_second = timeout_sec
            that.count_down_minute = timeout_min
          }, 1000)

          return
        }
        this.count_down_minute -= 1
        this.count_down_second = 59
      } else {
        this.count_down_second -= 1
      }
      setTimeout(that.countDown, 1000)
    },
    creatQrCode(code) {
      this.showQRCode = true
    },
    handleClose() {
      this.showQRCode = false
    }
  },
  mounted() {
    let fee = 0.01
    let subject = "服务付款"
    if (this.$route.query.fee) {
      fee = parseFloat(this.$route.query.fee)
    }
    this.fee = fee
    if (this.$route.query.subject) {
      subject = this.$route.query.subject
    }
    if (this.$route.query.out_trade_no) {
      this.out_trade_no = this.$route.query.out_trade_no
    }
    this.subject = subject
    this.loading = false
  }
}
</script>

<style scoped>
.qrcode {
  display: inline-block;
}

.qrcode img {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 6px;
  box-sizing: border-box;
}

</style>