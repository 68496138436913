<template>
  <div class="header">
    <div class="img hidden-sm-and-down">
      <img src="@/assets/Easypaper.png" alt="">
    </div>
    <div class="hidden-sm-and-down" style="width: 100%">
      <el-row type="flex" class="row-bg" justify="space-between" style="width: 100%">
        <el-col :span="18">
          <el-menu
              mode="horizontal"
              @select="handleSelect"
              background-color="#fff"
              text-color="#333"
              active-text-color="#0084ff"
              :default-active="active_item"
              style="flex:1; height: 60px"
          >
            <el-menu-item v-for="item in navList" :key="item.key" :index="item.name"
                          style="padding-right: 13px; padding-left: 13px;">
              <router-link :to=item.name tag="span">{{ item.navItem }}</router-link>
            </el-menu-item>
            <!--            <el-submenu index="" class="el-submenu">-->
            <!--              <template slot="title">-->
            <!--                <span class="el-submenu">{{ $t('page.language') }}</span>-->
            <!--              </template>-->
            <!--              <el-menu-item @click="toEn">English</el-menu-item>-->
            <!--              <el-menu-item @click="toZh">中文</el-menu-item>-->
            <!--            </el-submenu>-->
          </el-menu>
        </el-col>
        <el-col :span="6" style="margin-left: auto">
          <el-menu
              mode="horizontal"
              @select="handleUserSelect"
              background-color="#fff"
              text-color="#333"
              active-text-color="#333"
              default-active="100"
              style="flex:1; height: 60px;margin-left: auto"
          >
            <template v-if="!this.is_login">
              <el-menu-item @click="login" style="padding-right: 13px; padding-left: 13px;" index="1">
                <router-link to="/user/login" tag="span">登陆</router-link>
              </el-menu-item>
              <el-menu-item @click="register" style="padding-right: 13px; padding-left: 13px;" index="2">
                <router-link to="/user/register" tag="span">注册</router-link>
              </el-menu-item>
            </template>
            <template v-else>
              <el-menu-item @click="netschool" style="padding-right: 13px; padding-left: 13px;" index="3">
                <router-link to="/user/center" tag="span">用户中心</router-link>
              </el-menu-item>
              <el-menu-item @click="logout" style="padding-right: 13px; padding-left: 13px;" index="4">
                <router-link to="/user/logout" tag="span">登出</router-link>
              </el-menu-item>
            </template>

          </el-menu>
        </el-col>
      </el-row>
    </div>
    <div class="hidden-md-and-up" style="width: 100%;">
      <div class="space_between_row_container">
        <div class="flex_start_row_container">
          <img src="@/assets/Easypaper.png" alt="">
          <h4 style="font-family: 'DengXian Light'">EasyPaper</h4>
        </div>
        <div style="padding-right: 20px">
          <el-dropdown @command="handleDropMenuSelect" style="margin-top: 10px">
          <span class="el-dropdown-link">
            <el-button plain style="height: 40px">菜单<i
                class="el-icon-s-unfold el-icon--right"></i></el-button>
          </span>
            <el-dropdown-menu
                slot="dropdown"
                mode="horizontal"
                background-color="#fff"
                text-color="#333"
                active-text-color="#0084ff"
                style="flex:1"
            >
              <el-dropdown-item :command="item.name" v-for="item in navList" :key="item.key" :index="item.name">
                <router-link :to=item.name tag="span">{{ item.navItem }}</router-link>
              </el-dropdown-item>

              <template v-if="!this.is_login">
                <el-dropdown-item divided @click="login" index="1l">
                  <router-link to="/user/login" tag="span">登陆</router-link>
                </el-dropdown-item>
                <el-dropdown-item @click="register" index="2l">
                  <router-link to="/user/register" tag="span">注册</router-link>
                </el-dropdown-item>
              </template>
              <template v-else>
                <el-dropdown-item divided @click="netschool" index="3l">
                  <router-link to="/user/center" tag="span">用户中心</router-link>
                </el-dropdown-item>
                <el-dropdown-item @click="logout" index="4l">
                  <router-link to="/user/logout" tag="span">登出</router-link>
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crypto_utils from "@/utils/crypto_utils";
import Vue from "vue";
import {is_list_in_sth} from "@/utils/utils";
import {user_login_session, user_login_status} from "@/utils/user_utils";

export default {
  data() {
    const is_show_search = this.$router.currentRoute.fullPath !== "/papersearch"
    return {
      active_item: this.$router.currentRoute.fullPath,
      is_login: false,
      navList: [
        {name: '/home', navItem: "首页", key: "1"},
        {name: '/discover', navItem: "在线择师", key: "2"},
        {name: '/service', navItem: "其他服务", key: "3"},
        {name: '/faq', navItem: "常见问题", key: "4"},
        {name: '/business', navItem: "入驻平台", key: "5"},
        {name: '/products', navItem: "智能教辅", key: "6"},
        {name: '/contact', navItem: "联系我们", key: "7"}
      ],
      navNameList: []
    }
  },
  watch: {
    $route(to, from) {
      let that = this
      if (this.$router.currentRoute.fullPath === "/" || this.$router.currentRoute.fullPath === '') {
        this.active_item = "/home"
        return
      }
      if (is_list_in_sth(this.$router.currentRoute.fullPath, this.navNameList)) {
        for (var i = 0; i < this.navNameList.length; i++) {
          if (this.$router.currentRoute.fullPath.indexOf(this.navNameList[i]) !== -1) {
            this.active_item = this.navNameList[i]
          }
        }
        // this.active_item = this.$router.currentRoute.fullPath
      } else {
        // this.active_item = "nothing"
      }
    },
  },
  mounted() {
    var temp = []
    for (var i = 0; i < this.navList.length; i++) {
      temp.push(this.navList[i].name)
    }
    this.navNameList = temp
    this.$EventBus.$on("login_status", (msg) => {
      this.is_login = msg
      Vue.prototype.is_login = msg
    });
  },
  methods: {
    handleSelect(key, keyPath) {
      Vue.prototype.valid_click += 1
      if (this.$router.currentRoute !== keyPath[0]) {
        this.$router.push(keyPath[0])
      }
    },
    handleUserSelect(key, keyPath) {
      // console.log(keyPath)
    },
    login() {
      this.$router.push("/user/login")
    },
    register() {
      this.$router.push("/user/register")
    },
    logout() {
      this.$router.push("/user/logout")
    },
    netschool() {
      this.$router.push("/user/center")
    },
    handleDropMenuSelect(command) {
      // if (command === "toEn") {
      //   this.$i18n.locale = 'en'
      //   localStorage.setItem('locale', 'en')
      //   return;
      // } else if (command === "toZh") {
      //   this.$i18n.locale = 'zh'
      //   localStorage.setItem('locale', 'zh')
      //   return
      // }
      if (this.$router.currentRoute !== command) {
        this.$router.push(command)
      }
    },
  }
}
</script>

<style>
.el-menu-item {
  font-size: 13px !important;
}

.el-menu-item.is-active {
  color: #ea5b2c !important;
  font-size: 13px !important;
}

.el-submenu {
  font-size: 13px !important;
}

.el-submenu .is-active {
  color: #ea5b2c !important;
  font-size: 13px !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #ea5b2c !important;
}

.header {
  top: 0px;
  height: 60px;
  display: flex;
  width: 100%;
  align-content: center;
  border-bottom: solid 1px #e6e6e6;
}

.img {
  background: #ffffff;
  padding-left: 12.5%;
  padding-right: 20px;
  /*border-bottom: solid 1 px #e6e6e6;*/
}

img {
  width: 40px;
  height: 40px;
  padding: 10px;
}

.NavSearchContainer {
  margin-top: 11px;
  width: 200px;
  display: flex;
  padding-right: 5%;
  float: right;
}

.reduced_navbar {
  display: flex;
  flex-direction: row;
  justify-content: right;
}


</style>
