<template>
  <div class="flex_start_col_container">
    <el-input :value="'老师：'+this.com.teacher_name"
              style="margin-bottom: 10px;color: #333333" :readonly="true">
    </el-input>
    <el-input :value="'课程：'+this.com.subject"
              style="margin-bottom: 10px;color: #333333" :readonly="true">
    </el-input>
    <el-date-picker
        v-model="date"
        type="date"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        style="margin-bottom: 10px;width: 100%">
    </el-date-picker>
    <el-time-select
        placeholder="课程开始时间"
        v-model="time"
        :picker-options="{
      start: '00:00',
      step: '00:15',
      end: '23:45'
    }"
        style="margin-bottom: 10px;width: 100%">
    </el-time-select>
    <el-select v-model="duration" placeholder="课程时长" style="margin-bottom: 20px">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled">
      </el-option>
    </el-select>
    <template v-if="getAvailableStatus()">
      <el-button type="info" :disabled="true">等待选择</el-button>
    </template>
    <template v-else>
      <el-button type="primary" @click="submit">提交预约</el-button>
    </template>
    <el-dialog title="警告" :visible.sync="dialogVisible"
               width="30%">
      <div class="flex_start_col_container">
        <span style="padding: 10px">你选择的时间为半夜或凌晨！一般不合适作为学生上课时间＞︿＜</span>
        <span style="font-weight: 600;padding: 10px">是否确认为当前时间？({{ time }})</span>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="disconfirm">返回重选</el-button>
      <el-button type="primary" @click="confirm">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "LessonOrdering",
  props:["com"],
  data() {
    return {
      date: "",
      time: "",
      date_obj: {},
      dialogVisible: false,
      duration: null,
      options: [{
        value: 0.5,
        label: '0.5小时'
      }, {
        value: 1,
        label: '1小时'
      }, {
        value: 1.5,
        label: '1.5小时'
      }, {
        value: 2,
        label: '2小时'
      }, {
        value: 2.5,
        label: '2.5小时'
      }, {
        value: 3,
        label: '3小时'
      }],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 24 * 3600 * 1000 <= Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '明天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        },
          {
            text: '三天后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 3);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
      },
    }
  },
  methods: {
    getAvailableStatus() {
      return this.date === "" || this.time === "" || this.duration ==="";
    },
    confirm() {
      this.dialogVisible = false
      this.$emit("submit", this.date_obj,this.duration)
    },
    disconfirm() {
      this.$message.warning("返回重选！")
      this.dialogVisible = false
      this.date = ""
      this.time = ""
      this.duration=""
    },
    submit() {
      let date = this.date
      let hour = parseInt(this.time.toString().split(":")[0])
      date.setHours(parseInt(this.time.toString().split(":")[0]))
      date.setMinutes(parseInt(this.time.toString().split(":")[1]))
      date.setSeconds(0)
      this.date_obj = date
      if (hour >= 22 || hour <= 6) {
        this.dialogVisible = true
        return
      }
      this.$emit("submit", this.date_obj,this.duration)
    }
  }
}
</script>

<style scoped>

</style>