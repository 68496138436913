<template>
  <div class="center_col_container" style="padding: 30px;width: 70%">
    <span style="font-size: 30px;font-weight: 600;padding: 30px;margin-bottom: 40px">上课流程</span>
    <ProcessDisplay :info="process" style="width: 100%"></ProcessDisplay>
  </div>
</template>

<script>
import ProcessDisplay from "@/components/ProcessDisplay";

export default {
  name: "LessonProcessDisplay",
  components: {ProcessDisplay},
  data() {
    return {
      process: [{
        "title": "步骤①", "des": "注册账号", "html": "首先需要注册EasyPaper平台账号\n" +
            "后续平台约课/缴费/查看上课信息/反馈都在这里，所以需要先注册一下账号，并激活登陆，填写学生身份即可进入下一步。"
      },
        {"title": "步骤②", "des": "选择老师", "html": "可以在EasyPaper网站上查看老师信息并自由选择合适的老师~也可以和小助手详细学生需求以给出相应的推荐！"},
        {
          "title": "步骤③", "des": "约定试听", "html": "和课程小助手确定试听时间与试听内容\n" +
              "添加小助手微信（zqwei-tech)，确定学生基本需求/水平与试听内容，并约定试听时间。完成后学生可以在网校中看到课程信息。"
        },
        {"title": "步骤④", "des": "试听课", "html": "通过试听课简单熟悉网校系统排课/上课方式，并查看是否适应选择的老师的上课风格等内容，如果感觉老师不合适可以重新预约试听~"},
        {"title": "步骤⑤", "des": "确认排课", "html": "确定这个老师没有问题后，可以确定后续排课计划，如每周什么时间上多久，或在网校中使用单独约课功能进行提前约定时间进行上课。"},
        {"title": "步骤⑥", "des": "开始上课", "html": "根据计划开始稳定上课~后续有任何问题可以联系小助手进行解决！"}
      ],
    }
  }
}
</script>

<style scoped>

</style>