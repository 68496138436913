<template>
  <div class="center_col_container">
  <BackgroundHeader text="联系我们"/>
  <div class="center_col_container" style="width:80%">
    <h3 style="color: dimgray;margin-top: 20px">可以添加小助手微信：zqwei-tech<br>或发送邮件至zqwei_tech@163.com</h3>
    <div class="hidden-md-and-up">
    <img src="../assets/contact_qrcode.jpg" style="width: 70%;height: auto">
    </div>
    <div class="hidden-sm-and-down">
      <img src="../assets/contact_qrcode.jpg" style="width: 400px;height: auto">
    </div>
  </div>
  </div>
</template>

<script>
import BackgroundHeader from "@/components/BackgroundHeader";
export default {
  name: "About",
  components: {BackgroundHeader}
}
</script>

<style scoped>

</style>