import {getDecryptServerData, getSfapiUrlWithData} from "@/utils/utils";
import axios from "axios";
import Vue from "vue";
import th from "element-ui/src/locale/lang/th";

export function user_register(params, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/register/"
    that.loading = true
    var formData = new FormData();
    for (let key in params) {
        formData.append(key, params[key])
    }
    axios
        .post(url, formData)
        .then(function (res) {
                that.loading = false
                if (res.data.successMsg) {
                    that.$message({
                        showClose: true,
                        message: res.data.successMsg,
                        type: 'success',
                        duration: 800
                    });

                }
                if (res.data.errMsg) {
                    that.$message.error(res.data.errMsg)
                }
                if (res.data.status) {
                    that.has_register = true
                    // that.$router.push("/user/login")
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function user_login(params, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/login/"
    that.loading = true
    var formData = new FormData();
    for (let key in params) {
        formData.append(key, params[key])
    }
    formData.append("csrftoken", that.$cookies.get('csrftoken'))
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
            that.loading = false
            if (res.data.status) {
                if (res.data.role !== 'visitor') {
                    that.goToUserCenter()
                } else {
                    that.$router.push("/user/role_register")
                }
                if (res.data.successMsg) {
                    Vue.prototype.is_login = true
                    that.$message({
                        showClose: true,
                        message: res.data.successMsg,
                        type: 'success',
                        duration: 800
                    });
                    that.$EventBus.$emit("login_status", true);
                }
            } else {
                that.errMsg = res.data.errMsg
            }
            if (res.data.errMsg) {
                that.$message.error(res.data.errMsg);
            }
        })
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function user_logout(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/logout/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.status) {
                    that.$message({
                        showClose: true,
                        message: "登出成功",
                        type: 'success',
                        duration: 800
                    });
                    that.startCountDown()
                    that.$EventBus.$emit("login_status", false);
                } else {
                    that.$message.error("登出失败！");
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function user_login_status(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/login_status/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.status) {
                    that.goToUserCenter()
                    Vue.prototype.user_type = res.data.role
                    that.$EventBus.$emit("login_status", true);
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function user_login_session(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/login_status/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.status) {
                    Vue.prototype.user_type = res.data.role
                }
                that.$EventBus.$emit("login_status", res.data.status);
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function user_activate(code, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/activate/" + code + "/"
    that.loading = true
    axios
        .get(url, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                that.activate_success = res.data.status
                if (res.data.status) {
                    that.startCountDown()
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function user_forget_pass(data, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/forget_pass/"
    that.loading = true
    var formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key])
    }
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.errMsg) {
                    that.$message.error(res.data.errMsg)
                }
                if (res.data.successMsg) {
                    that.$message.success(res.data.successMsg)
                }
                if (res.data.status) {
                    that.step = 1
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function verify_forget_code(data, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/verify_forget_code/"
    that.loading = true
    var formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key])
    }
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.errMsg) {
                    that.$message.error(res.data.errMsg)
                }
                if (res.data.status) {
                    that.step = 2
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function change_pass(data, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/change_pass/"
    that.loading = true
    var formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key])
    }
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.errMsg) {
                    that.$message.error(res.data.errMsg)
                }
                if (res.data.successMsg) {
                    that.$message.success(res.data.successMsg)
                }
                if (res.data.status) {
                    that.step = 3
                    that.startCountDown()
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function auth_change_pass(that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/auth_change_pass/"
    that.loading = true
    var formData = new FormData();
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.errMsg) {
                    that.$message.error(res.data.errMsg)
                }
                if (res.data.successMsg) {
                    that.$message.success(res.data.successMsg)
                }
                if (res.data.status) {
                    that.user = res.data.user
                    that.step = 1
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}

export function role_register(student, that) {
    const url = Vue.prototype.api.pyroot_url + "netschool/user/role_register/"
    that.loading = true
    var formData = new FormData();
    for (let key in student) {
        formData.append(key, student[key])
    }
    formData.append("role", "student")
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (res.data.errMsg) {
                    that.$message.error(res.data.errMsg)
                }
                if (res.data.successMsg) {
                    that.$message.success(res.data.successMsg)
                }
                if (res.data.status) {
                    that.$router.push("/user/center")
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            that.$message.error("网络错误！请稍后再试！")
            that.loading = false
        });
}