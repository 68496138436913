<template>
  <div class="center_col_container">
    <BackgroundHeader text="上课流程"/>
    <LessonProcessDisplay/>
  </div>
</template>

<script>
import BackgroundHeader from "@/components/BackgroundHeader";
import LessonProcessDisplay from "@/components/display_module/LessonProcessDisplay";
export default {
  name: "LessonProcessPage",
  components: {LessonProcessDisplay, BackgroundHeader}
}
</script>

<style scoped>

</style>