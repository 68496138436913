<template>
  <div class="center_col_container">
    <div style="padding: 40px;background-color: #fefefe" class="center_col_container">
      <div class="center_col_container" style="width:80%;text-align: center">
        <span style="font-size: 30px">我们的智能教辅/平台</span>
        <span style="color: dimgray;font-size: 15px;margin-top: 5px">欢迎使用体验并提出建议~</span>
      </div>

      <div class="space_between_row_container " style="flex-wrap:wrap;width: 100%">

        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/Easypaper.png" style="width: 50px;height: 50px;border-radius: 50px">
          <span class="product_title">EasyPaper APP</span>
          <span class="product_description">领先的国际教育学习引擎</span>
          <img src="../assets/easypaper_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://easy-paper.com/')" class="product_btn">
            查看详情
          </el-button>
        </div>

        <div class="center_col_container hover_shadow prod_cell">
          <img src="https://astar-world.com/storage/logo.png?1615374784" style="width: auto;height: 30px">
          <span class="product_title">AstarWorld综合论坛</span>
          <span class="product_description">国际教育信息互通/问题解答一站式平台</span>
          <img src="../assets/astarworld_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://astar-world.com/')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <!--      </div>-->
        <!--    </div>-->
        <!--    <div class="space_between_row_container " style="flex-wrap:wrap">-->
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/easyuni_logo.png" style="width: 50px;height: 50px">
          <span class="product_title">EasyUni申请助手</span>
          <span class="product_description">大学择校/信息资源一站式平台，申请流程全公开！</span>
          <img src="../assets/easyuni_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://mp.weixin.qq.com/s/WZ51oOzbRIHPaAQBphK-9g')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/easywords.png" style="width: 50px;height: 50px">
          <span class="product_title">EasyWords学科单词</span>
          <span class="product_description">学科单词背诵神器</span>
          <img src="../assets/easywords_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://easywords.zqwei-tech.cn/h5/pc.html')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/ezp_orange.png" style="width: 40px;height: 40px;border-radius: 40px">
          <span class="product_title">EasyPaper在线平台</span>
          <span class="product_description">国际教育资源整合平台</span>
          <img src="../assets/easypass_prod.png" style="width: 100%;height: auto">
          <el-button @click="$router.push('home')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/Easypaper.png" style="width: 40px;height: 40px;border-radius: 40px">
          <span class="product_title">EasyPaper网校系统</span>
          <span class="product_description">约课/排课/结算/反馈一条龙系统</span>
          <img src="../assets/wangxiao_prod.png" style="width: 100%;height: auto">
          <el-button @click="$router.push('user/center')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/ezp_green.png" style="width: 40px;height: 40px;border-radius: 40px">
          <span class="product_title">EasyPaper资料书库</span>
          <span class="product_description">免费而全的课外电子书/学科笔记/学习资料集中图书库</span>
          <img src="../assets/lib_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://easy-paper.com/library')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/ezp_black.png" style="width: 40px;height: 40px;border-radius: 40px">
          <span class="product_title">EasyPaper在线自习室</span>
          <span class="product_description">在线学习，一同进步！</span>
          <img src="../assets/zixishi_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://easy-paper.com/online_chat')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/zixueshe.png" style="width: 50px;height: 50px">
          <span class="product_title">A-level自学社</span>
          <span class="product_description">免费教程视频，助力自学考出高分！</span>
          <img src="../assets/zixueshe_prod.png" style="width: 100%;height: auto">
          <el-button @click="goToPage('https://space.bilibili.com/1591849681')" class="product_btn">
            查看详情
          </el-button>
        </div>
        <div class="center_col_container hover_shadow prod_cell">
          <img src="../assets/safe_logo.png" style="width: 50px;height: 50px">
          <span class="product_title">EasyPaper网络安全系统</span>
          <span class="product_description">保护数据安全，数字资产实时追踪</span>
          <img src="../assets/websafety_prod.png" style="width: 100%;height: auto">
          <el-button @click="$message.info('该产品无法查看介绍，详情请联系EasyPaper小助手！')" class="product_btn">
            查看详情
          </el-button>
        </div>
      </div>
    </div>
    <div class="center_col_container hidden-sm-and-down" style="width: 100%">
      <span style="font-size: 30px">在线体验</span>
      <div class="space_around_row_container" style="flex-wrap: wrap;width: 90%">
        <div class="iphone_iframe">
          <iframe
              id="iframeId0" src="https://easyuni.zqwei-tech.cn/h5/#/" frameborder="0"
              class="pc iframe" scrolling="auto" style="height: 100%;width: 100%">
          </iframe>
        </div>
        <div class="iphone_iframe">
          <iframe
              id="iframeId1" src="https://easy-paper.com/h5/#/" frameborder="0"
              class="pc iframe" scrolling="auto" style="height: 100%;width: 100%">
          </iframe>
        </div>
        <div class="iphone_iframe">
          <iframe
              id="iframeId2" src="https://easywords.zqwei-tech.cn/h5/#/" frameborder="0"
              class="pc iframe" scrolling="auto" style="height: 100%;width: 100%">
          </iframe>
        </div>
        <!--        <div class="iphone_iframe">-->
        <!--          <iframe-->
        <!--              id="iframeId3" src="https://astar-world.com" frameborder="0"-->
        <!--              class="pc iframe" scrolling="auto" style="height: 100%;width: 100%">-->
        <!--          </iframe>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

</template>

<script>
import BackgroundHeader from "@/components/BackgroundHeader";

export default {
  name: "Products",
  components: {BackgroundHeader},
  methods: {
    goToPage(url) {
      window.open(url, "_blank")
    }
  }
}
</script>

<style scoped>
.prod_cell {
  margin: 15px;
  padding: 25px;
  width: 270px
}

.product_btn {
  margin-top: 20px;
}

.product_title {
  font-size: 18px;
  font-weight: 400;
}

.product_description {
  margin-top: 5px;
  color: dimgray;
  font-size: 15px;
}

.iphone_iframe {
  margin: 60px;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('https://www.uviewui.com/common/iPhoneX_model.png');
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-sizing: border-box;
  color: rgb(44, 62, 80);
  display: block;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
  font-size: 16px;
  height: 800.85px;
  padding-bottom: 18px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 48px;
  width: 410px;
  z-index: 10;
}
</style>