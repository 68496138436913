<template>
  <div class="center_col_container page_basic_container" style="padding: 60px">
    <div style="padding: 15px;">
      <i class="el-icon-circle-close" style="font-size: 60px;color: red"></i>
    </div>
    <span style="font-size: 30px;font-weight: 500">支付失败</span>
    <span style="font-size: 16px;font-weight: 400;color: dimgray;margin-top: 10px">课程付款支付失败，请联系小助手进行处理</span>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess"
}
</script>

<style scoped>

</style>