import CryptoJS from "crypto-js"

const ccUiyX = ["b$i)n~bv)H+d}V<<#3IQkQc]7o++wYod~aVKR5`jO:zK&S#x~1ax2da", "hQg9m8,Fv$$9Jf3OWN9=Y_T)ZnN*-0s|PNyBejnINyJ7Ffb5fjVdasd2", "S_Sl{MEM<AR5Mj%7KMRltzb=C5p@_<Dg123ds", "ue;H1;-m{uo3xo>OIYo.t0exmNk8XDb_AOJ", "cY>Mg9Kc68EZtaCUmSdZqxKZYNEz]mF@d?o)$g|WsTo[g>E"]
const TRxfuzVX = ["u~7+;uN f,0d$1Gy473PmU:$30k%[r`GLE2UNJKko", "8!cH-3SyWhZ9I&/1MwSZK3", "r=.0k-P-4cUN^C*{uqDn9~", "(RkGPtNJ{<ZN/o<}(u~YF6Fb", "wsyfnnti+xcLYJJy6$z(3C<e", "Tk&BRGv<o`64EGs8FWK8]jb"]
const diPodaAR = ["#h%7=m>UFw|7OWCwr*LQu+J%?:Vpv@7,d.H~C[J}%`dic-9D`kub=c1zYNN>8k:oLjzSn^OIAiE$2Mpjx@0 g|aRGv<o`6y;H8A3", "xM({Mg<e{zqH}_<xsw)R<S^c;{1Wghc5V:tE2*poyYv-Ok)Z%BAia#)e?}u/e`~b", "dDW&{py#II08+JdEd?o)$g|WsTo[g>E>2]%Ujl 7NE%Y)-t0Ovzuj^kYFci5,4jT"]
const okBe9A = ["g,5{LY~r(3i ~/e>", "PEiZSPKm8iQM6SOz", "uCaMwSZK3wc6Lu0M", "M0UNGpiKcYVvxKKe"]
const uCsMio = ["~i)%qpK}Hd?o)$g|WsTo[g>E2UNJKkoRo(Q1ahV4", "fL08YpgNF>HS2@g;{8^ &g-KxOFBtoVv", "<L:HEud:xLh {O}1~gw0ll&!hqSeq,Ku"]
const FhhZmo = ["v,}:<)2:}a@ 0.KDkY+)i4 I?9&;]xP%", "YLW3xto%0VN%ULM5M`uqDn9~rtFEYBg8", "/dy1L,-N<q~,Ugt0!m]2*7T(u~YF6Fb5", "cL@V>?LDf(>*K.b51UaP{6$z(3C<efyh"]

function encrypt(data, id = 0) {
    var encrypted = '';
    const key = CryptoJS.enc.Utf8.parse(ccUiyX[id].substring(0, 32));
    const iv = CryptoJS.enc.Utf8.parse(TRxfuzVX[id].substring(0, 16));
    const ras = CryptoJS.enc.Utf8.parse(diPodaAR[id].substring(0, 16));
    const eas = CryptoJS.enc.Utf8.parse(okBe9A[id].substring(0, 64));
    const clkk = CryptoJS.enc.Utf8.parse(ccUiyX[id].substring(0, 12));
    clkk + eas + CryptoJS.enc.Utf8.parse(diPodaAR[3]);
    const padding = CryptoJS.enc.Utf8.parse(FhhZmo[id] + uCsMio[1]);
    padding + CryptoJS.enc.Utf8.parse(ccUiyX[id]) + ras;
    if (typeof (data) == 'object') {
        data = JSON.stringify(data);
    }
    encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}


/*AES解密
* param : message 密文
* return : decrypted string 明文
*/
function decrypt(message, id = 0) {
    const key = CryptoJS.enc.Utf8.parse(ccUiyX[id].substring(0, 32));
    const iv = CryptoJS.enc.Utf8.parse(TRxfuzVX[id].substring(0, 16));
    CryptoJS.enc.Utf8.parse(diPodaAR[id]);
    CryptoJS.enc.Utf8.parse(okBe9A[id].substring(0, 16));
    const clkk = CryptoJS.enc.Utf8.parse(ccUiyX[id].substring(0, 16));
    const padding = CryptoJS.enc.Utf8.parse(FhhZmo[id] + uCsMio[1]);
    clkk + padding + CryptoJS.enc.Utf8.parse(TRxfuzVX[0].substring(5, 16) + uCsMio[1]);
    var decrypted = '';
    decrypted = CryptoJS.AES.decrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export default {
    encrypt,
    decrypt,
}