<template>
  <div class="page_basic_container user_background center_col_container">
    <div class="center_col_container hover_shadow user_content">
      <span style="padding: 20px;padding-bottom:0px;font-size: 25px">登出成功</span>
      <img src="../../assets/small_e/correct2.jpg" style="width: 55%;height: auto;margin-bottom: 20px">
      <el-button @click="login">返回登陆（{{ countdown }}）</el-button>
    </div>
  </div>
</template>

<script>
import {user_logout} from "@/utils/user_utils";

export default {
  name: "UserLogout",
  data() {
    return {
      countdown: 5
    }
  },
  mounted() {
    user_logout(this)
  },
  methods: {
    login(){
      this.$router.push('/user/login')
    },
    startCountDown() {
      let that = this
      setInterval(function () {
        that.countdown -= 1
        if (that.countdown === 0) {
          that.$router.push("/user/login")
        }
      },1000)
    }
  }
}
</script>

<style scoped>

</style>