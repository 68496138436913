<template>
  <div class="center_col_container" style="width: 100%;padding: 10px">
    <h1>入驻申请登记表</h1>
    <div class="center_col_container" style="padding: 10px">
      <el-link href="https://easytutor.zqwei-tech.cn/downloads/%E8%80%81%E5%B8%88%E6%B0%B4%E5%B9%B3%E4%B8%8E%E4%BB%B7%E6%A0%BC%E5%AE%A1%E6%A0%B8.pdf" style="padding: 4px;font-size: 18px" type="primary">点击查看老师审核与价格指导方法<i class="el-icon-view el-icon-download"></i> </el-link>
      <el-link href="https://easytutor.zqwei-tech.cn/downloads/%E8%AF%95%E5%90%AC%E8%AF%BE%E4%BB%8B%E7%BB%8D.pdf" style="padding: 4px;font-size: 18px" type="primary">点击查看试听课介绍<i class="el-icon-view el-icon-download"></i> </el-link>
      <el-link href="https://easytutor.zqwei-tech.cn/downloads/%E5%B9%B3%E5%8F%B0%E8%80%81%E5%B8%88%E5%85%A5%E9%A9%BB%E6%B5%81%E7%A8%8B.pdf" style="padding: 4px;font-size: 18px" type="primary">点击查看入驻流程介绍<i class="el-icon-view el-icon-download"></i> </el-link>
    </div>
    <transition name="van-slide-up">
      <div class="center_col_container" v-show="submitted" style="width: 80%;text-align: center">
        <i class="el-icon-circle-check" style="font-size: 60px;color: green"></i>
        <span style="padding: 20px;font-size: 20px;font-weight: bold">你已提交本表单，请与小助手进一步联系~</span>
        <span style="padding: 10px;font-size: 17px;color: dimgray">文件信息 如头像，说明图片上传通道暂未开启，请手动发送至小助手</span>
        <el-button type="success" @click="onBack" style="margin-top: 30px;margin-bottom: 20px">返回查看表单</el-button>
        <el-button type="primary" @click="goToUserCenter" style="margin-top: 10px;margin-bottom: 20px">前往用户中心
        </el-button>
      </div>
    </transition>
    <transition name="van-slide-up">
      <div v-show="!submitted" class="center_col_container" style="width: 100%">
        <el-form v-loading="loading" ref="form" :rules="rules" label-position="top" :model="teacher"
                 label-width="auto"
                 :style="'width:'+width ">
          <div class="center_col_container">
            <h3>（底部提供基础预览，部分框支持Html，预计需要>3min）</h3>
          </div>
          <h3>注：其余头像/图片素材上传通道暂未开启，请文件微信发至小助手~（微信：zqwei-tech)</h3>
          <el-form-item label="导师昵称" prop="nickName">
            <span class="form_text">请输入昵称，如英文名+老师，中文名+老师，姓+老师</span>
            <el-input v-model="teacher.nickName" placeholder="例如：Tom老师，张老师，张三老师"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="contact">
            <span class="form_text">微信/手机号/其他方式 任一(注明是哪个渠道哈，方便小助手联系你）'</span>
            <el-input v-model="teacher.contact" placeholder="微信/手机号/其他方式 任一"></el-input>
          </el-form-item>
          <!--    <el-form-item label="头像">-->
          <!--      <el-upload-->
          <!--          class="avatar-uploader"-->
          <!--          action="https://jsonplaceholder.typicode.com/posts/"-->
          <!--          :on-change="handleAvatarChange"-->
          <!--          :show-file-list="false"-->
          <!--          :on-success="handleAvatarSuccess"-->
          <!--          :before-upload="beforeAvatarUpload">-->
          <!--        <img v-if="preview_image" :src="preview_image" class="avatar">-->
          <!--        <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          <!--      </el-upload>-->
          <!--    </el-form-item>-->
          <el-form-item label="就读/毕业院校" prop="edu_degree">
            <el-input v-model="teacher.edu_degree" placeholder="如帝国理工 电子电气工程/牛津大学 工程系/香港大学 金融专业等"></el-input>
          </el-form-item>
          <el-form-item label="可教授科目概述" prop="subject">
            <span class="form_text">写可教授科目的大概即可，如'数学，高数，雅思'</span>
            <el-input v-model="teacher.subject" placeholder="数学，高数，雅思"></el-input>
          </el-form-item>
          <el-form-item label="具体可教授科目（重要）" prop="subject_teachable_description">
            <span class="form_text">精确到考试局/年级（ig，al等），如CAIE/Edexcel Alevel 生物&化学/ 雅思，PTE等，系统筛选排序使用</span>
            <el-input v-model="teacher.subject_teachable_description"></el-input>
          </el-form-item>

          <!--    <el-form-item label="具体可教授科目（重要）" prop="subject_description">-->
          <!--      <el-input v-model="teacher.subject"-->
          <!--                placeholder="如CAIE alevel/ig 高数/物理，edxecel IAL 数学"></el-input>-->
          <!--    </el-form-item>-->
          <el-form-item label="所属学科领域分类" prop="subject_cata">
            <el-radio-group v-model="teacher.subject_cata">
              <template v-for="item in subject_cata_list">
                <el-radio :label="item[0]">{{ item[1] }}</el-radio>
              </template>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="教授备注" prop="subject_supplement">
            <span class="form_text">补充说明自身的实力，如雅思8.5，Bhpo超级金奖，全校Top5%等...</span>
            <el-input v-model="teacher.subject_supplement" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="个人/教学特色简述" prop="characteristic_detail" @validate="updatePrice">
            <span class="form_text">必填，简述个人情况，展示教学风格，理念等，将在预览与详情页面第一位置展示（详见下方预览）。如遇到困难可以简单列举一些素材，小助手可帮你写作补全</span>
            <el-input type="textarea" v-model="teacher.characteristic_detail"
                      placeholder="示例模板：大家好我是xxx老师，xxx学校xxx系就读...之前也辅导过不少学生，学生都特别喜欢哦...大家多多支持我哦！"></el-input>
          </el-form-item>
          <el-form-item label="个人标签" prop="personal_tag">
            <span class="form_text">个人标签，如G5,HKU,吉他狂人,航模发烧友,量化等等，请使用英文逗号分割~</span>
            <el-input v-model="teacher.personal_tag" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="最低价格（每小时计算/CNY)" prop="expected_min_price" @validate="updatePrice">
            <span class="form_text">期望收费情况，如没有特殊情况请保持最低价格与最高价格相同~(自2021年12月起，为防止教学水平不足&价格虚高，
              我们对老师的价格信息需要通过新的审核机制)</span>
            <el-input v-model.number="teacher.expected_min_price" placeholder=""></el-input>
          </el-form-item>
          <div class="flex_start_col_container" style="color: dimgray;font-size: 14px">
            <el-checkbox v-model="price_interval_checked">是否采用价格区间</el-checkbox>
            <span style="margin-bottom: 6px;margin-top: 6px"><span style="color: black;font-weight: 600">注意：</span> 最终佣金价格由实际成交价格为准，网站展示佣金大约为30%上下浮动，因促销或砍价最后成交可能会在0-10%</span>
            <span v-if="teacher.expected_min_price"
                  style="margin-bottom: 5px"> 当前预估学费：<b>{{ getNewPrice(teacher.expected_min_price) }}/小时</b></span>
          </div>
          <template v-if="price_interval_checked">
            <el-form-item label="最高价格（每小时计算/CNY)" prop="expected_max_price"
                          @validate="updatePrice">
              <span class="form_text">期望收费情况，如有不同服务提供需要不同种的收费，请填写价格区间，并给小助手注明~</span>
              <el-input v-model.number="teacher.expected_max_price" placeholder=""></el-input>
            </el-form-item>
            <div class="flex_start_col_container" style="color: dimgray;font-size: 14px"
                 v-if="teacher.expected_max_price">
              <span> 当前预估最高学费：<b>{{ getNewPrice(teacher.expected_max_price) }}/小时</b></span>
            </div>
          </template>
          <el-form-item label="教育经历">
            <span class="form_text">填写教育经历，如就读于xxx高中，xxx大学，xxx学校攻读硕博等</span>
            <el-input type="textarea" v-model="teacher.education_detail"
                      placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="辅导经历">
            <span class="form_text">辅导他人的经历，如曾教过xxx学生，在xxx机构任职，反馈良好等</span>
            <el-input type="textarea" v-model="teacher.experience_detail"
                      placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="能力展示">
            <span class="form_text">能够展示自己实力的部分，包括个人笔记，个人经历，个人成就等</span>
            <el-input type="textarea" v-model="teacher.ability_display"
                      placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="获奖/证书展示">
            <span class="form_text">获奖，证书填写，推荐图片（如有图片请压缩包发给小助手！）</span>
            <el-input type="textarea" v-model="teacher.reward_display" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="过去成绩详情">
            <span class="form_text">填写个人Alevel成绩，大学成绩，奖学金均可</span>
            <el-input type="textarea" v-model="teacher.past_grade_detail" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="价格细节">
            <span class="form_text">课程收费/优惠详细</span>
            <el-input type="textarea" v-model="teacher.price_detail" placeholder="如15节连报送10%折扣"></el-input>
          </el-form-item>
          <el-form-item label="是否免费试听">
            <el-radio-group v-model="teacher.is_free_try">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否可议价（讲价or特殊情况低价收费）">
            <el-radio-group v-model="teacher.is_price_changable">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否提供折扣（多报多优惠）">
            <el-radio-group v-model="teacher.is_discount">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="试讲视频链接" class="flex_start_col_container">
            <el-input v-model="teacher.lesson_video_url" placeholder="请填写试讲视频嵌入链接，若不清楚什么是嵌入链接欢迎咨询小助手"></el-input>
            <img src="../assets/qianru.png" style="width: 100%;height: auto">
          </el-form-item>
          <el-form-item class="center_col_container">
            <el-button type="primary" @click="onSubmit">提交审核</el-button>
          </el-form-item>

        </el-form>

        <div class="center_col_container" style="width: 100%">
          <h3>预览</h3>
          <TeacherPreview :teacher="teacher"/>
          <TeacherDetail :target_teacher="teacher"/>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import TeacherPreview from "@/components/TeacherPreview";
import TeacherDetail from "@/components/TeacherDetail";
import {getDecryptServerData, getFileBase64} from "@/utils/utils";
import axios from "axios";

export default {
  name: "TeacherRegister",
  components: {TeacherDetail, TeacherPreview},
  watch: {
    'teacher.expected_min_price'() {
      this.teacher.min_price = this.getNewPrice(this.teacher.expected_min_price)
      if (!this.price_interval_checked) {
        this.teacher.max_price = this.getNewPrice(this.teacher.expected_min_price)
      }
    },
    'teacher.expected_max_price'() {
      this.teacher.max_price = this.getNewPrice(this.teacher.expected_max_price)
    },
  },
  props: ["width", "is_role_register"],
  data() {
    return {
      price_interval_checked: false,
      submitted: false,
      loading: false,
      subject_cata_list: [["Biology&Chemistry", "生物化学"], ["Math&Computer", "数学计算机"], ["Economics&Business", "经济商科"],
        ["Language", "语言类"],
        ["Physics&Engineering", "物理工程材料"], ["OtherScience", "其他理科学科类"], ["OtherNoneScience", "其他人文学科类"]],
      info: {
        "contact": ""
      },
      teacher: {
        "nickName": "",
        "edu_degree": "",
        "subject": "",
        "subject_cata": "Biology&Chemistry",
        "education_detail": "",
        "experience_detail": "",
        "characteristic_detail": "",
        "subject_teachable_description": "",
        "ability_display": "",
        "reward_display": "",
        "expected_min_price": "",
        "expected_max_price": "",
        "min_price": null,
        "max_price": null,
        "ranking": 100,
        "rating_grade": 100,
        "price_detail": "",
        "is_discount": 1,
        "is_price_changable": 1,
        "past_grade_detail": "",
        "is_free_try": 1,
        "lesson_video_url": null,
        "subject_supplement": null,
        "comment_detail": "",
        "start_time": null,
        "view_counts": Math.ceil(Math.random() * 200),
        "personal_tag": "",
        "avatar_url": "https://easy-paper.com/download/avatar/ezp_logo.png",
        "detailed_avatar_url": "https://easy-paper.com/download/avatar/ezp_logo.png",
      },

      rules: {
        nickName: [
          {required: true, message: '请输入昵称', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 3 到 15 个字符', trigger: 'blur'}
        ],
        contact:
            {required: true, message: '请输入联系方式，以便让小助手联系您', trigger: 'blur'},
        edu_degree:
            {required: true, message: '请输入您的在读/毕业院校与专业', trigger: 'blur'},
        subject:
            {required: true, message: '请输入学科信息', trigger: 'blur'},
        subject_teachable_description:
            {required: true, message: '请输入学科信息', trigger: 'blur'},
        subject_supplement:
            {required: false, message: '请输入联系方式，以便让小助手联系您', trigger: 'blur'},
        expected_min_price:
            [{required: true, message: '请输入最低价格', trigger: 'blur'},
              {type: 'number',min:0, max:300.1, message: "无效的价格数字，请重新输入！注：未完成认证情况下，最低价格的最高价格为300，如需更高价格可在其他地方备注，通过审核即可生效！详见评定方案！"}],
        expected_max_price:
            [{required: true, message: "价格必须为数字值", trigger: 'blur'},
             {min:0, max: 400.1, type: 'number', message: '无效的价格数字，请重新输入！注：未完成认证情况下，最高价格的最高价格为400，如需更高价格可在其他地方备注，通过审核即可生效！详见评定方案！'}],
        characteristic_detail: [{required: true, message: '请输入个人介绍', trigger: 'blur'},
          {min: 20, max: 5000, message: '长度在 20 到 5000 个字符', trigger: 'blur'}],
        subject_cata:
            {required: true, message: '请选择具体学科分类~', trigger: 'blur'},

      }
    }
  },
  methods: {
    updatePrice() {
      this.teacher.min_price = this.getNewPrice(this.teacher.expected_min_price)
      this.teacher.max_price = this.getNewPrice(this.teacher.expected_max_price)
    },
    onBack() {
      this.submitted = false
    },
    goToUserCenter() {
      this.$router.push("/user/center")
      this.$EventBus.$emit("user_center")
    },
    getNewPrice(price) {
      price = parseInt(price / 0.7)
      var string = price.toString().substring(0, price.toString().length - 1) + "9"
      let p_price = parseInt(string)
      if (p_price.toString()[0] === (p_price - 10).toString()[0]) {
        return p_price
      }
      return p_price - 10
    },
    onSubmit() {
      let that = this
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const url = that.api.root_url + "add_teacher"
          const py_url = that.api.pyroot_url + "netschool/registered_teacher_role_register/"
          var new_teacher = Object.assign({}, that.teacher)
          new_teacher.subject_teachable_description = new_teacher.subject_teachable_description + "\n\n最低价格：" + this.teacher.expected_min_price
          if (this.teacher.expected_max_price) {
            new_teacher.subject_teachable_description = new_teacher.subject_teachable_description + "\n最高价格：" + this.teacher.expected_max_price
          }
          // delete new_teacher.avatar_url
          // delete new_teacher.detailed_avatar_url
          delete new_teacher.expected_max_price
          delete new_teacher.expected_min_price
          that.loading = true
          new_teacher.rating_grade = 75
          if (!this.is_role_register) {
            axios.post(url, new_teacher)
                .then(function (res) {
                      that.loading = false
                      that.$message.success("提交成功，可联系小助手咨询进展,微信：zqwei-tech")
                      that.submitted = true
                    }
                )
                .catch(function (error) { // 请求失败处理
                  that.loading = false
                  that.$message.error("提交错误，请检查网络连接！")
                });
          } else {
            var formData = new FormData();
            for (let key in new_teacher) {
              formData.append(key, new_teacher[key])
            }
            axios.post(py_url, formData, {withCredentials: true})
                .then(function (res) {
                      that.loading = false
                      that.$message.success("提交成功，可联系小助手咨询进展,微信：zqwei-tech")
                      that.submitted = true
                    }
                )
                .catch(function (error) { // 请求失败处理
                  that.loading = false
                  that.$message.error("提交错误，请检查网络连接！")
                });
          }

        } else {
          this.$message.error("请确认完成必填项要求后提交表单！")
          return false;
        }
      });

    },
    convertToHtml(input) {
      return input.replace(/\n/g, "<br>")
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      //
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isJPG && isLt2M;
    },
    handleAvatarChange(file, fileList) {
      getFileBase64(file.raw).then(res => {
        console.log(res)
        if (res.substring(0, 40).indexOf("image") !== -1) {
          this.preview_image = res
        } else {
          this.$message.error(this.$t("photosearch.file_encoding_error"))
        }
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.form_text {
  color: dimgray;
}
</style>