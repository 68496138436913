import {getDecryptServerData, getSfapiUrlWithData} from "@/utils/utils";
import axios from "axios";
import Vue from "vue";
import th from "element-ui/src/locale/lang/th";
import da from "element-ui/src/locale/lang/da";

export function pay_for_out_trade_no(that, out_trade_no, payment_cata) {
    const url = Vue.prototype.api.pyroot_url + "payment/pay_for_out_trade_no/"
    let _type = ""
    if (Vue.prototype.isMobile) {
        _type = "mobile"
    } else {
        _type = "PC"
    }
    var formData = new FormData();
    let data = {"out_trade_no": out_trade_no, "payment_cata": payment_cata, "way": _type}
    for (let key in data) {
        formData.append(key, data[key])
    }
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                if (payment_cata === "alipay") {
                    that.pay_url = res.data.url
                    window.open(res.data.url, '_self')
                } else {
                    that.countDown()
                    that.wechat_qrcode_data = res.data.data
                    that.creatQrCode(res.data.data)
                    get_wechat_native_payment_result(that, res.data.out_trade_no)
                }
            }
        )
        .catch(function (error) { // 请求失败处理
            console.log(error)
        });
}

export function get_wechat_native_transfer_payment_data(fee, subject, that) {
    const url = Vue.prototype.api.pyroot_url + "payment/create_wechat_native_for_transfer"
    let params = {"fee": fee, "name": subject}
    that.loading = true
    axios
        .get(url, {params: params})
        .then(function (res) {
                that.loading = false
                that.countDown()
                that.wechat_qrcode_data = res.data.data
                that.creatQrCode(res.data.data)
                get_wechat_native_payment_result(that, res.data.out_trade_no)
            }
        )
        .catch(function (error) { // 请求失败处理
        });
}

export function get_wechat_native_payment_result(that, out_trade_no, timeout = 180, current_time = 0) {
    const url = Vue.prototype.api.pyroot_url + "payment/check_wx_payment_result"
    let params = {"out_trade_no": out_trade_no}
    axios
        .get(url, {params: params, timeout: timeout * 1500})
        .then(function (res) {
                if (res.data.status) {
                    if (res.data.valid) {
                        that.$router.push("/payment/success")
                    } else {
                        setTimeout(function () {
                            get_wechat_native_payment_result(that, out_trade_no)
                        }, 1500)
                    }
                } else {

                }

            }
        )
        .catch(function (error) { // 请求失败处理
        });
}

export function get_transfer_payment_url(fee, subject, that) {
    const url = Vue.prototype.api.pyroot_url + "payment/create_paymentorder_for_transfer"
    let _type = ""
    if (Vue.prototype.isMobile) {
        _type = "mobile"
    } else {
        _type = "PC"
    }
    let params = {"fee": fee, "subject": subject, "way": _type}
    that.loading = true
    axios
        .get(url, {params: params})
        .then(function (res) {
                that.loading = false
                that.pay_url = res.data.url
                // console.log(that.pay_url)
                window.open(res.data.url, '_self')
            }
        )
        .catch(function (error) { // 请求失败处理

        });
}

export function get_single_lesson_payment_url(id, that) {
    const url = Vue.prototype.api.pyroot_url + "payment/pay_for_single_lesson/"
    let _type = ""
    if (Vue.prototype.isMobile) {
        _type = "mobile"
    } else {
        _type = "PC"
    }
    var formData = new FormData();
    let data = {"id": id, "way": _type}
    for (let key in data) {
        formData.append(key, data[key])
    }
    that.loading = true
    axios
        .post(url, formData, {withCredentials: true})
        .then(function (res) {
                that.loading = false
                // old 过时
                // that.pay_url = res.data.url
                // // console.log(that.pay_url)
                //
                // window.open(res.data.url, '_self')

                that.$router.push({
                    path: '/payment/center',
                    query: {"fee": res.data.fee, "out_trade_no": res.data.out_trade_no}
                })
            }
        )
        .catch(function (error) { // 请求失败处理

        });
}


// let params = {"out_trade_no": out_trade_no, "timeout": timeout}
// axios
//     .get(url, {params: params, timeout: timeout * 1500})
//     .then(function (res) {
//         console.log(res.data)
//             if (res.data.status) {
//             } else {
//                 if (res.data.valid) {
//                     window.open("https://pyserver.easypass.zqwei-tech.cn/payment/payment_callback/", '_self')
//                 } else {
//                     console.log("超时！")
//                 }
//             }
//
//         }
//     )
//     .catch(function (error) { // 请求失败处理
//         console.log(error)
//         window.open("https://pyserver.easypass.zqwei-tech.cn/payment/payment_callback/", '_self')
//     });