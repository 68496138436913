<template>
  <div class="user_background center_col_container page_basic_container" style="padding-top: 30px">
    <div class="center_col_container hover_shadow tf_et" v-loading="loading"
         style="padding: 40px;margin: 20px;background-color: white;border-radius: 5px">
      <span style="padding: 10px;font-size: 20px">账户登录</span>
      <el-link style="padding: 10px;padding-top: 0px" @click="register" :underline="false" type="info">没有账户？点此注册</el-link>
      <div class="center_col_container" style="width: 100%;">
        <el-input v-model="username" placeholder="用户名/账户" style="width: 100%;margin: 10px"></el-input>
        <el-input v-model="password" placeholder="密码" show-password style="width: 100%;margin: 10px;"></el-input>
        <el-button style="margin: 20px;width: 100%;" type="warning" plain round @click="post_login">登录</el-button>
        <el-link @click="forgetPass" :underline="false" type="info">已有账户，忘记密码？</el-link>
      </div>
    </div>
    <el-dialog
        title="一个简单的验证"
        class="center_col_container"
        :visible.sync="dialogVisible"
        width="350px">
      <div class="center_col_container">
        <SlideVerify ref="slideblock" slider-text="向右滑动"
                     @success="onVerifySuccess"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {user_login, user_login_status, user_logout} from "@/utils/user_utils";
import SlideCaptcha from "@/components/anti_crawler/SlideCaptcha";
import ClickCaptcha from "@/components/anti_crawler/ClickCaptcha";
import Vue from "vue";

export default {
  name: "UserLogin",
  components: {ClickCaptcha, SlideCaptcha},
  data() {
    return {
      password: "",
      username: "",
      loading: false,
      dialogVisible: false
    }
  },
  methods: {
    onVerifySuccess() {
      this.dialogVisible = false
      this.$refs.slideblock.reset()
      user_login({"password": this.password, "username": this.username, "is_auto_login": true}, this)
    },
    post_login() {
      this.dialogVisible = true
    },
    goToUserCenter() {
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect)
      } else {
        this.$router.push("/user/center")
        this.$EventBus.$emit("user_center")
      }
    },
    logout() {
      user_logout(this)
    },
    register(){
      this.$router.push("/user/register")
    },
    forgetPass() {
      this.$router.push("/user/forget_pass")
    }
  },
  mounted() {
    if (this.$route.query.message) {
      this.$message.warning(this.$route.query.message)
    }
    user_login_status(this)
  }
}
</script>

<style scoped>

</style>