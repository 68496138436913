import PageNotFound from "@/page/PageNotFound";
import Home from "@/page/Home";
import LectuerSelectPage from "@/page/LectuerSelectPage";
import FAQ from "@/page/FAQ";
import Business from "@/page/Business";
import Products from "@/page/Products";
import About from "@/page/About";
import OtherService from "@/page/OtherService";
import StudentPage from "@/page/StudentPage";
import CountDown from "@/page/CountDown";
import TransferPayment from "@/page/payment/TransferPayment";
import PaymentSuccess from "@/page/payment/PaymentSuccess";
import PaymentFail from "@/page/payment/PaymentFail";
import UserRegister from "@/page/user/UserRegister";
import UserLogin from "@/page/user/UserLogin";
import UserCenter from "@/page/user/UserCenter";
import UserLogout from "@/page/user/UserLogout";
import UserRoleRegister from "@/page/user/UserRoleRegister";
import UserActivate from "@/page/user/UserActivate";
import UserForgetPass from "@/page/user/UserForgetPass";
import UserChangePass from "@/page/user/UserChangePass";
import Email from "@/page/Email";
import FinancialInfoForm from "@/page/financial/FinancialInfoForm";
import CMSTutorial from "@/page/CMSTutorial";
import LessonProcessPage from "@/page/tutorials/LessonProcessPage";
import PaymentCenter from "@/page/payment/PaymentCenter";

const routers = [
    {
        path: '/',
        component: Home,
        meta: {
            title: "A-level国际教育一对一补习资源平台，领先的国际教育辅导网站——EasyPass",
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            title: "国际教育一对一补习资源平台——EasyPass首页",
        }
    },
    {
        path: '/discover',
        name: 'discover',
        component: LectuerSelectPage,
        meta: {
            title: "在线择师——领先的A-level资源整合平台 EasyPass",
        }
    },
    {
        path: '/service',
        name: 'service',
        component: OtherService,
        meta: {
            title: "其他服务——领先的A-level资源整合平台 EasyPass",
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
        meta: {
            title: "常见问答——领先的A-level资源整合平台 EasyPass",
        }
    },
    {
        path: '/business',
        name: 'business',
        component: Business,
        meta: {
            title: "入驻平台——领先的A-level资源整合平台 EasyPass",
        }
    },
    {
        path: '/products',
        name: 'products',
        component: Products,
        meta: {
            title: "我们的智能教辅产品——EasyPaper教育科技",
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: About,
        meta: {
            title: "联系我们——EasyPaper教育科技",
        }
    },
    {
        path: '/student',
        name: 'student',
        component: StudentPage,
        meta: {
            title: "学生信息登记——EasyPaper教育科技",
        }
    },
    {
        path: '/countdown',
        name: 'countdown',
        component: CountDown,
        meta: {
            title: "倒计时",
        }
    },
    {
        path: '/payment/transfer',
        name: 'transfer',
        component: TransferPayment,
        meta: {
            title: "课时费支付",
        }
    },
    {
        path: '/payment/center',
        name: 'payment_center',
        component: PaymentCenter,
        meta: {
            title: "网校收银台",
        }
    },
    {
        path: '/payment/success',
        name: 'payment_success',
        component: PaymentSuccess,
        meta: {
            title: "支付成功",
        }
    },
    {
        path: '/payment/fail',
        name: 'payment_fail',
        component: PaymentFail,
        meta: {
            title: "支付成功",
        }
    },
    {
        path: '/user/login',
        name: 'user_login',
        component: UserLogin,
        meta: {
            title: "EasyPaper账户登陆",
        }
    },
    {
        path: '/user/register',
        name: 'user_register',
        component: UserRegister,
        meta: {
            title: "EasyPaper账户注册",
        }
    },
    {
        path: '/user/center',
        name: 'user_center',
        component: UserCenter,
        meta: {
            title: "EasyPaper用户网校中心",
        }
    },
    {
        path: '/user/logout',
        name: 'user_logout',
        component: UserLogout,
        meta: {
            title: "EasyPaper账户登出",
        }
    },
    {
        path: '/user/role_register',
        name: 'user_role_register',
        component: UserRoleRegister,
        meta: {
            title: "EasyPaper身份选择注册",
        }
    },
    {
        path: '/user/activate',
        name: 'user_activate',
        component: UserActivate,
        meta: {
            title: "EasyPaper账户激活",
        }
    },
    {
        path: '/user/forget_pass',
        name: 'user_forget_pass',
        component: UserForgetPass,
        meta: {
            title: "EasyPaper找回密码",
        }
    },
    {
        path: '/user/change_pass',
        name: 'user_change_pass',
        component: UserChangePass,
        meta: {
            title: "EasyPaper找回密码",
        }
    },
    {
        path: '/user/email',
        name: 'email',
        component: Email,
        meta: {
            title: "EasyPaper找回密码",
        }
    },
    {
        path: '/user/financial',
        name: 'financial',
        component: FinancialInfoForm,
        meta: {
            title: "EasyPaper财务信息登记",
        }
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: CMSTutorial,
        meta: {
            title: "网校使用教程",
        }
    },
    {
        path: '/lesson_tutorial',
        name: 'lesson_tutorial',
        component: LessonProcessPage,
        meta: {
            title: "上课流程",
        }
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            title: "404 Not Found 查无此页面"
        }
    }
]
export default routers