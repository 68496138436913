import { render, staticRenderFns } from "./SlideCaptcha.vue?vue&type=template&id=770ad548&scoped=true&"
import script from "./SlideCaptcha.vue?vue&type=script&lang=js&"
export * from "./SlideCaptcha.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "770ad548",
  null
  
)

export default component.exports