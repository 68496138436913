<template>
  <div class="center_col_container">
    <BackgroundHeader text="使用教程"/>
    <div class="center_col_container" style="width: 100%">
      <span class="register">注册教程</span>
      <iframe class="hover_shadow" style="height: 500px;width: 850px"
              src="https://easytutor.zqwei-tech.cn/video/%E6%B3%A8%E5%86%8C.mp4"
              scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
    </div>
    <div class="center_col_container" style="width: 100%">
      <span class="register">老师端教程</span>
      <iframe class="hover_shadow" style="height: 500px;width: 850px"
              src="https://easytutor.zqwei-tech.cn/video/老师认证.mp4"
              scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
    </div>
    <div class="center_col_container" style="width: 100%">
      <span class="register">学生端教程</span>
      <iframe class="hover_shadow" style="height: 500px;width: 850px"
              src="https://easytutor.zqwei-tech.cn/video/学生认证.mp4"
              scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
    </div>
  </div>
</template>

<script>
import BackgroundHeader from "@/components/BackgroundHeader";

export default {
  name: "CMSTutorial",
  components: {BackgroundHeader}
}
</script>

<style scoped>
.register {
  font-size: 25px;
  font-weight: 600;
  padding: 15px;
  margin-top: 15px;
}
</style>