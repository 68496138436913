<template>
  <div class="my_center_col_container" style="padding: 40px">
    <transition name="el-zoom-in-top">
      <div v-show="!is_finish">
        <div class="my_center_col_container hover_shadow"
             style="border-radius: 15px;padding: 30px;padding-right: 50px;padding-left: 50px">
          <h1>倒计时</h1>
          <h3 style="color: dimgray;font-weight: 500">距离时间结束还有{{ count_down_number }}秒</h3>

          <p class="wait_text" v-if="is_start_counting">请耐心等待
            <b style="font-weight: bold">
              {{ count_down_loading_txt }}
            </b>
          </p>
          <p class="wait_text" v-else>请开始你的倒计时</p>

          <el-button style="margin-top: 30px" round type="danger" v-if="!is_start_counting" @click="count_down">开始倒计时
          </el-button>
        </div>
      </div>
    </transition>
    <transition name="el-zoom-in-top">
      <div v-show="is_finish">
        <h1>倒计时结束了！！！</h1>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  data() {
    return {
      count_down_number: 3,
      count_down_loading_txt: ".",
      is_start_counting: false,
      is_finish: false
    }
  },
  methods: {
    count_down() {
      let that = this
      this.is_start_counting = true

      let counter = setInterval(function () {
        if (that.count_down_number > 0) {
          that.count_down_number = that.count_down_number - 1
        } else {
          clearInterval(counter)
          that.is_finish = true
        }
        if (that.count_down_loading_txt === "...") {
          that.count_down_loading_txt = "."
        } else if (that.count_down_loading_txt === "..") {
          that.count_down_loading_txt = "..."
        } else {
          that.count_down_loading_txt = ".."
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.my_center_col_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wait_text {
  font-size: 15px;
  color: #8c939d;
  font-weight: 450;
}

.wait_text b {
  font-weight: 700
}
</style>