import crypto_utils from "@/utils/crypto_utils";
import Vue from "vue";

const mode = -3 + 4 + Math.floor(3.14) - Math.sqrt(4)
// const root_url = "http://localhost:8081/"
const root_url = "https://server.easypass.zqwei-tech.cn/"
// const pyroot_url = "http://localhost:8000/"
const pyroot_url ="https://pyserver.easypass.zqwei-tech.cn/"
const empty = [0, 0] //debug
const empty_time = [0, 0, 0]
const subject_cata_dict = {
    "Biology&Chemistry": "生物化学", "Math&Computer": "数学计算机", "Economics&Business": "经济商科",
    "Language": "语言类",
    "Physics&Engineering": "物理工程材料", "OtherScience": "其他理科学科类", "OtherNoneScience": "其他人文学科类"
}
export default {
    install(Vue, options) {
        options
        Vue.prototype.api = {
            root_url: root_url,
            pyroot_url: pyroot_url,
            get_teachers_url: root_url + "get_teachers/",
            get_teacher_detail: root_url + "get_teacher_detail/",
            get_subjects: root_url + "get_subjects/",
            sf_get_teachers: "/get_teachers",
            sf_get_subjects: "/get_subjects",
            sp_get_teacher_detail: "/get_teacher_detail",
            sfapi: root_url + "sfapi/"
        }
        Vue.prototype.contact_const = {
            hello: "您好，我是Ezp第一代人工AI哦，可以回答你关于我们的课程&服务，教学，退款/优惠政策，上课相关，老师政策，升学规划等一系列问题哦~ 需要人工客服可以添加微信zqwei-tech咨询小助手~请问有什么可以帮到您的？",
            book_try: "预约试听/上课可以先添加小助手微信哦~ 微信号：zqwei-tech",
            contact_qrcode: require("../assets/contact_qrcode.jpg"),

        }
        Vue.prototype.const = {
            qp_initial_number: 3,
            qp_load_number: 3,
            sp_qp_page_number: 4,
            replace_target: "%fo@~[C.4L1.ZDcp",
            useless_string_len: 10,
            max_search_len: 50,
            mode: mode
        }
        Vue.prototype.subject_cata_dict = subject_cata_dict
        Vue.prototype.is_login = false
        Vue.prototype.$EventBus = new Vue()
    }
}

