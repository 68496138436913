<template>
  <div id="app">
    <Header/>
    <template v-if="!is_webdriver">
      <keep-alive include="Home,LectuerSelectPage,Business,FAQ,About,UserCenter,StudentCenter,TeacherCenter">
        <router-view></router-view>
      </keep-alive>
    </template>
    <template v-else>
      <PageNotFound/>
    </template>
    <Copyright/>
  </div>
</template>

<script>


import Header from "@/components/Header";
import Copyright from "@/components/Copyright";
import LectuerSelectPage from "@/page/LectuerSelectPage";
import Business from "@/page/Business";
import FAQ from "@/page/FAQ";
import About from "@/page/About";
import PageNotFound from "@/page/PageNotFound";
import crypto_utils from "@/utils/crypto_utils";
import Vue from "vue";
import {user_login_session} from "@/utils/user_utils";
import {updateWebDriverStatus} from "@/utils/utils";

export default {
  name: 'App',
  components: {
    PageNotFound,
    About,
    FAQ,
    Business,
    LectuerSelectPage,
    Copyright,
    Header
  },
  data() {
    return {
      is_webdriver: window.navigator.webdriver,
    }
  },
  methods: {
    isMobile() {
      const width = window.screen.width
      return width <= 500;
    }
  },
  mounted() {
    var original = window['console']['log'];
    var fake = function () {
      // original(message)
    }
    window['console']['log'] = fake;

    function abc() {
      bca();
    }

    function bca() {
      eval(crypto_utils.decrypt("Z/41gIv0nKxDjMhuGUkoog==", 2).substring(0, 8))
    }

    setInterval(abc, 193);
    updateWebDriverStatus(this)
    Vue.prototype.isMobile = this.isMobile()
    user_login_session(this)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
