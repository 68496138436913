<template>
  <div>
    <template v-for="idx in len">
      <el-checkbox ref="cap" v-if="idx===index"
                   :id="'cap'+idx.toString()+require('@/utils/utils').randomString(Math.floor(Math.random() * 5+4))"
                   v-model="checked" :label="text"
                   border></el-checkbox>
      <el-checkbox ref="cap" style="display:none;" v-if="idx!==index"
                   :id="'cap'+idx.toString()+require('@/utils/utils').randomString(Math.floor(Math.random() * 5+4))"
                   :label="text"
                   border></el-checkbox>
    </template>
  </div>
</template>

<script>
export default {
  name: "ClickCaptcha",
  data() {
    const len = Math.floor(Math.random() * 5 + 4)
    //:ref="'cap'+idx.toString()"
    return {
      text:this.$t('captcha.iamnot_robot'),
      len: len,
      index: Math.floor(Math.random() * (len - 1)),
      checked: false
    }
  },
  mounted() {
    let that = this
    setInterval(function () {
      // for (var i = 0; i < that.$refs.cap.length; i++) {
      //   // var id = that.$refs.cap[i].id
      //   // const next_id = 'cap' + "0".toString() + require('@/utils/utils').randomString(Math.floor(Math.random() * 5 + 4))
      //   // document.getElementById(id).setAttribute('id', next_id);
      //   // that.$refs.cap.style.display = "yes"
      //   // console.log(that.$refs["cap" + i.toString()])
      // }
      that.len = Math.floor(Math.random() * 5 + 4)
      that.index = Math.floor(Math.random() * (that.len - 2) + 1)
    }, 500)
  },
  watch: {
    checked(check) {
      if (check) {
        this.$emit("checked")
      }
    }
  }
}
</script>

<style scoped>

</style>