<template>
  <div class="flex_start_col_container hover_shadow base_container">
    <!--        <p>可以看见我（内部）</p>-->
    <div class="space_between_row_container">
      <div class="name_container">
        <div class="color_bar" style="width: auto;margin-bottom: 20px"></div>
        <i style="text-align: start;flex-direction: row"
           class="el-icon-user nickName hidden-sm-and-down"><span style="margin-left: 5px;margin-right: 10px;">{{
            teacher.nickName
          }}&emsp;&emsp;</span></i>
        <i style="text-align: start;flex-direction: row"
           class="el-icon-user nickName2 hidden-md-and-up"><span style="margin-left: 5px;margin-right: 10px;">{{
            teacher.nickName
          }}&emsp;&emsp;</span></i>
        <span style="margin-top: 20px">{{ teacher.edu_degree }}</span>
      </div>
      <img class="avatar_image" style="width: 120px;height: 120px;border-radius: 80px;"
           :src="teacher.avatar_url">
    </div>
    <div class="content_container">
      <span style="font-size: 12px"><b>擅长科目：</b>{{ teacher.subject }}<template
          v-if="teacher.subject_supplement"> ({{ teacher.subject_supplement }})</template></span>

      <span class="price_container" style="font-size: 12px">
        <b>费用：</b>
        {{ this.indirect_price }}
        <template v-if="!indirect_price">
        {{ teacher.min_price }}<template
            v-if="teacher.max_price!==teacher.min_price">-{{ teacher.max_price }}</template>/小时
        </template>
            <template v-if="indirect_price">
        {{ getNewPrice(teacher.min_price) }}<template
                v-if="teacher.max_price!==teacher.min_price">-{{ getNewPrice(teacher.max_price) }}</template>/小时
        </template>
      <el-tag v-if="teacher.is_discount" effect="dark" size="mini" type="danger">提供折扣</el-tag>
      <el-tag style="margin-left: 5px;" v-if="teacher.is_price_changable" effect="dark" size="mini"
              type="light">可议价</el-tag>
       <el-tag style="margin-left: 5px;" v-if="teacher.is_free_try" effect="dark" size="mini"
               type="warning">免费试听</el-tag>
      <el-tag style="margin-left: 5px;" v-if="teacher.lesson_video_url" effect="dark" size="mini"
              type="success">有试讲视频</el-tag></span>
      <span><b>学生评分：</b> <el-rate
          v-model="teacher.rating_grade/20"
          style="margin-top: 5px"
          disabled
          :max="5"
          show-score
          text-color="#ff9900"
          score-template="{value}">
    </el-rate></span>
      <div style="font-size: 12px;height: 82px;" class="description"><b>简介：</b>
        <span v-html="teacher.characteristic_detail.replace(/<br>/g,' ')"></span>
      </div>
      <div v-if="teacher.available_degree" class="flex_start_row_container" style="width: 100%;margin-top: 10px">
      <span v-if="teacher.available_degree" style="margin-bottom: auto;margin-top: auto"><b>可供约课时间：</b></span>
        <el-progress :percentage="teacher.available_degree" :format="empty"
                     :color="customColors" style="width: 60%;margin-bottom: auto;margin-top: auto"></el-progress>
       <span style="font-size: 10px;color: dimgray;margin-bottom: auto;margin-top: auto">{{ getDegreeHint(teacher.available_degree) + " (" + teacher.available_degree }}%)</span>
      </div>
    </div>
    <i class="el-icon-view"
       style="font-size: 13px;color: dimgray;left: 5px;padding-left: 5px">阅读{{ teacher.view_counts }}</i>
    <el-link @click="goToDetail" :underline="false" style="font-size: 16px" type="primary">查看详情</el-link>
  </div>
</template>

<script>
export default {
  name: "TeacherPreview",
  props: ["teacher", "indirect_price"],
  data() {
    return {
      value: 10,
      customColors: [
        {color: '#f56c6c', percentage: 40},
        {color: '#e6a23c', percentage: 60},
        {color: '#1989fa', percentage: 70},
        {color: '#6f7ad3', percentage: 80},
        {color: '#5cb87a', percentage: 90}
      ]
    }
  },
  methods: {
    empty(pecentage){
      return ""
    },
    getDegreeHint(percentage) {
      percentage = parseFloat(percentage)
      if (percentage === 0) {
        return "不可约"
      } else if (percentage > 0 && percentage <= 20) {
        return "较难约"
      } else if (percentage > 20 && percentage <= 40) {
        return "难约"
      } else if (percentage > 40 && percentage <= 60) {
        return "可试约"
      } else if (percentage > 60 && percentage <= 80) {
        return "可约"
      } else if (percentage > 80 && percentage <= 100) {
        return "完全可约"
      }
    },
    goToDetail() {
      this.$emit("goToDetail", this.teacher.id)
    },
    getNewPrice(price) {
      if (price) {
        return 0
      }
      price = parseFloat(price) / 0.8
      var string = price.toString().substring(0, price.toString().length - 1) + "9"
      return parseInt(string)
    },
  }
}
</script>

<style scoped>

.nickName {
  font-size: 25px;
}

.nickName2 {
  font-size: 19px;
}

.description {
  display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
  -webkit-line-clamp: 5; /*显示的行数；如果要设置2行加...则设置为2*/
  overflow: hidden; /*超出的文本隐藏*/
  text-overflow: ellipsis; /* 溢出用省略号*/
  -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
}

.avatar_image:after {
  position: absolute;
  width: 80px;
  height: 80px;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: url('../assets/Easypaper.png');
  background-size: 100%;
}

.content_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}

.price_container el-tag {
  margin-left: 5px;
}

.content_container span {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}


.flex_start_col_container .name_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.base_container {
  padding: 20px;
  border-radius: 30px;
  width: 450px;
  margin-top: 20px;
  background-color: white
}

</style>