<template>
  <div class="center_col_container">
    <BackgroundHeader text="平台入驻"/>
    <div class="hidden-sm-and-down">
      <div class="center_col_container" style="padding-top: 50px;padding-bottom: 50px">
        <div class="center_col_container base_container" style="background-color: #f7f7f7">
          <div class="space_between_start_row_container">
            <div class="center_col_container" style="width: 50%;padding: 45px">
              <img style="width: 100%;height: auto;margin-top: 0px"
                   src="../assets/团队协作统计.png">
            </div>
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">顶级的流量资源</span>
              <span style="color: grey;line-height: 2.0">
              EasyPaper教育科技旗下包括EasyPaper拍照搜题系列网站/APP/小程序，
              AstarWorld国际教育问答综合社区等，凭借其免费，实用且领先的功能（拍照搜题，专题训练，综合搜索）特性， <b>已经抢占并覆盖全国主要的国际教育学生，老师</b>。未来更多的合作学校与免费智能教辅产品
            如自媒体，线上图书馆，在线自习室，学科单词，申请助手，择校平台，考试管理系统，网校系统的落地，将进一步加强我们在这一方面的优势。在这一基础上，有这些产品对我们的背书，
              <b>我们完全有实力为我们入驻的老师带来全国顶尖的学生资源</b>，为你带来更多的曝光与收入。</span>
            </div>
          </div>
          <div class="space_between_start_row_container">
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">灵活的收入体系</span>
              <span style="color: grey;line-height: 2.0">老师可以完全根据自身情况/不同课程来选择个人时薪~
              我们口号是让所有学生都能享受到低廉，高质量的国际教育服务，所以我们的平台佣金相对传统机构来说很低，老师可获得课时费最高可达90%+！
                老师只需专注于上课本身，让我们来成为你们的忠实合作伙伴，为你打通市场，匹配合适的学生资源，实现双赢格局！
              同时，基于我们的技术优势，我们的网校系统支持按课次<b>立即结算到个人账号</b>，月末进行个税结算，比市场上常见机构按月结算的<b>更加高效灵活</b>~
            详细可以咨询我们的小助手~</span>
            </div>
            <div class="center_col_container" style="width: 50%;padding: 45px;margin-top: 10px">
              <img style="width: 100%;height: auto"
                   src="../assets/团队设计.png">
            </div>
          </div>
          <div class="space_between_start_row_container">
            <div class="center_col_container" style="width: 50%;padding: 45px;padding-top: 0px">
              <img style="width: 100%;height: auto;margin-top: -20px"
                   src="../assets/开发.png">
            </div>
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">入驻特权&平台扶持</span>
              <span style="color: grey;line-height: 2.0">
                我们不是机构，我们只是信息资源整合的平台，学生老师资源的搬运工。
              所有老师的定价信息由自身的质量与信息决定！我们的小助手也可以为老师提供一些参考的~
              上课时间，方式也可以很随意，看学生与老师的时间决定，而不会固定安排。还有一大福利在于，
              我们的老师都可以<b>免费享受EasyPaper教育科技教研团队的成果与资料</b>，
              包括海量真题，专题训练，教辅工具，我们内部的教程与资料等等...
            </span>
            </div>
          </div>
          <div class="space_between_start_row_container" style="position: relative">
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">怎么入驻？</span>
              <span style="color: grey;line-height: 2.0">
              怎么入驻呢？先点击下方’立即入驻‘注册账户然后提交老师个人信息后即可入驻成功啦！非常的简单快捷~
            </span>
              <div style="width: 35%;padding-top: 50px">
                <el-button size="large" type="success" @click="register">立即入驻</el-button>
              </div>
            </div>
            <div class="center_col_container" style="width: 50%;padding: 45px">
              <img style="width: 100%;height: auto;margin-top: 10px"
                   src="../assets/团队协作.png">
            </div>

          </div>

        </div>
        <transition name="van-slide-up">
        <TeacherRegister v-show="showRegister" width="50%" :is_role_register="false"/>
        </transition>
      </div>
    </div>
    <div class="hidden-md-and-up">
      <div class="center_col_container" style="width: 100%;background-color: #f7f7f7;padding: 10px">
        <div class="center_col_col_container">
          <div class="flex_start_col_container" style="width: 80%;padding: 45px">
            <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">顶级的流量资源</span>
            <span style="color: grey;line-height: 2.0">
              EasyPaper教育科技旗下包括EasyPaper拍照搜题系列网站/APP/小程序，
              AstarWorld国际教育问答综合社区等，凭借其免费，实用且领先的功能（拍照搜题，专题训练，综合搜索）特性， <b>已经抢占并覆盖全国主要的国际教育学生，老师</b>。未来更多的合作学校与免费智能教辅产品
            如自媒体，线上图书馆，在线自习室，学科单词，申请助手，择校平台，考试管理系统，网校系统的落地，将进一步加强我们在这一方面的优势。在这一基础上，有这些产品对我们的背书，
              <b>我们完全有实力为我们入驻的老师带来全国顶尖的学生资源</b>，为你带来更多的曝光与收入。</span>
          </div>
        </div>
        <div class="center_col_container" style="width: 80%;padding: 45px">
          <img style="width: 100%;height: auto;margin-top: 0px"
               src="../assets/团队协作统计.png">
        </div>
        <div class="center_col_col_container">
          <div class="flex_start_col_container" style="width: 80%;padding: 45px">
            <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">灵活的收入体系</span>
            <span style="color: grey;line-height: 2.0">老师可以完全根据自身情况/不同课程来选择个人时薪~
              我们口号是让所有学生都能享受到低廉，高质量的国际教育服务，所以我们的平台佣金相对传统机构来说很低，老师可获得课时费最高可达90%+！
                老师只需专注于上课本身，让我们来成为你们的忠实合作伙伴，为你打通市场，匹配合适的学生资源，实现双赢格局！
              同时，基于我们的技术优势，我们的网校系统支持按课次<b>立即结算到个人账号</b>，月末进行个税结算，比市场上常见机构按月结算的<b>更加高效灵活</b>~
            详细可以咨询我们的小助手~</span>
          </div>
          <div class="center_col_container" style="width: 80%;padding: 45px;margin-top: 10px">
            <img style="width: 100%;height: auto"
                 src="../assets/团队设计.png">
          </div>
        </div>
        <div class="center_col_col_container">

          <div class="flex_start_col_container" style="width: 80%;padding: 45px">
            <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">入驻特权&平台扶持</span>
            <span style="color: grey;line-height: 2.0">
               我们不是机构，我们只是信息资源整合的平台，学生老师资源的搬运工。
              所有老师的定价信息由自身的质量与信息决定！我们的小助手也可以为老师提供一些参考的~
              上课时间，方式也可以很随意，看学生与老师的时间决定，而不会固定安排。还有一大福利在于，
              我们的老师都可以<b>免费享受EasyPaper教育科技教研团队的成果与资料</b>，
              包括海量真题，专题训练，教辅工具，我们内部的教程与资料等等...
            </span>
          </div>
          <div class="center_col_container" style="width: 80%;padding: 45px;padding-top: 0px">
            <img style="width: 100%;height: auto;margin-top: -20px"
                 src="../assets/开发.png">
          </div>
        </div>
        <div class="center_col_col_container" style="position: relative">
          <div class="flex_start_col_container" style="width: 80%;padding: 45px">
            <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">怎么入驻？</span>
            <span style="color: grey;line-height: 2.0">
              怎么入驻呢？先点击下方’立即入驻‘注册账户然后提交老师个人信息后即可入驻成功啦！非常的简单快捷~
            </span>
            <div style="width: 35%;padding-top: 50px">
              <el-button size="large" type="success" @click="register">立即登记</el-button>
            </div>
          </div>
          <div class="center_col_container" style="width: 80%;padding: 45px">
            <img style="width: 100%;height: auto;margin-top: 10px"
                 src="../assets/团队协作.png">
          </div>
        </div>

      </div>
      <transition name="van-slide-up">
      <TeacherRegister v-show="showRegister" width="85%" :is_role_register="false"/>
        </transition>
    </div>
  </div>
</template>

<script>
import BackgroundHeader from "@/components/BackgroundHeader";
import TeacherRegister from "@/components/TeacherRegister";

export default {
  name: "Business",
  components: {TeacherRegister, BackgroundHeader},
  data() {
    return {
      showRegister: false
    }
  },
  methods: {
    register() {
      // this.showRegister = true
      this.$router.push("/user/register")
      // window.open('https://wp.easypass.zqwei-tech.cn/2020/04/joinus/', "_black")
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .base_container {
    width: 90%;
    padding: 20px;
  }
}

@media screen and (min-width: 600px) {
  .base_container {
    width: 70%;
    padding: 45px;

  }
}
</style>