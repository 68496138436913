<template>
  <div class="flex_start_col_container user_info">
    <!--  <span style="font-size: 20px;font-weight: 500">学生详细信息</span>-->
    <span class="label">学生昵称 </span>
    <span class="content">{{ info.name }}</span>
    <span class="label">年级 </span>
    <span class="content">{{ info.level }}</span>
    <span class="label">学校 </span>
    <span class="content">{{ info.school }}</span>
    <span class="label">所学科目 </span>
    <span class="content">{{ info.subject }}</span>
    <span class="label">细节信息 </span>
    <span class="content">{{ info.detail }}</span>
  </div>
</template>

<script>
export default {
  name: "StudentInfo",
  props: ["info"]
}
</script>

<style scoped>
.user_info .label {
  padding-top: 15px;
  padding: 10px;
  color: #111111;
  font-weight: bold;
  font-size: 16px;
}

.user_info .content {
  padding: 10px;
  color: dimgray;
  font-size: 14px;
}
</style>