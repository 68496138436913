<template>
  <div class="center_col_container" style="width: 100%;padding: 10px" v-loading="loading">
    <h1 v-if="!is_role_register">学生基础信息登记</h1>
    <h1 v-if="is_role_register">学生用户基础信息登记</h1>
    <template v-if="is_role_register">
      <h4 style="color:dimgray ">以下所填信息仅供试听课/正课老师了解学生基本情况使用</h4>
      <h5 style="color:#8c939d">（如需重新选择身份请刷新本页面）</h5>
    </template>
    <transition name="van-slide-up">
      <div class="center_col_container" v-show="submitted" style="width: 80%;text-align: center">
        <i class="el-icon-circle-check" style="font-size: 60px;color: green"></i>
        <span style="padding: 20px;font-size: 20px;font-weight: bold">你已提交本表单</span>
        <span style="padding: 10px;font-size: 17px;color: dimgray"></span>
        <el-button type="success" @click="onBack" style="margin-top: 30px;margin-bottom: 20px">返回查看表单</el-button>
      </div>
    </transition>
    <transition name="van-slide-up">
      <div v-show="!submitted" class="center_col_container" style="width: 100%">
        <el-form v-loading="loading" ref="form" :rules="rules" label-position="top" :model="student"
                 label-width="auto"
                 :style="'width:'+width ">
          <el-form-item label="学生昵称/姓名" prop="name">
            <!--            <span class="form_text">请输入昵称，如英文名或中文名</span>-->
            <el-input v-model="student.name" placeholder="英文名或中文名"></el-input>
          </el-form-item>

          <el-form-item label="联系方式" prop="contact">
            <!--            <span class="form_text">请输入联系方式</span>-->
            <el-input v-model="student.contact" placeholder="如微信/QQ等"></el-input>
          </el-form-item>

          <el-form-item label="毕业时间" prop="time">
            <!--            <span class="form_text">请输入毕业时间</span>-->
            <el-input v-model="student.time" placeholder="如2023年"></el-input>
          </el-form-item>

          <el-form-item label="在读学校" prop="school">
            <span class="form_text">请输入在读学校（帮助目标老师更好的了解你当前的情况）</span>
            <el-input v-model="student.school" placeholder="如领科教育上海校区"></el-input>
          </el-form-item>

          <el-form-item label="所在年级" prop="level">
            <!--            <span class="form_text">请输入所在年级</span>-->
            <el-input v-model="student.level" placeholder="如A2,AS,IG1，IG2等..."></el-input>
          </el-form-item>

          <el-form-item label="学生基本情况与需求" prop="detail">
            <span class="form_text">请输入你当前的水平与需要的基本需求与帮助要求，如需要咨询/需要学科复习/需要10月考试复习等</span>
            <el-input type="textarea" v-model="student.detail" placeholder=""></el-input>
          </el-form-item>

          <el-form-item label="所学科目" prop="subject">
            <span class="form_text">请输入学生当前所学的科目与考试局，如CAIE物理/化学或Edexcel高数/生物等等</span>
            <el-input v-model="student.subject" placeholder=""></el-input>
          </el-form-item>

          <el-form-item class="center_col_container">
            <el-button type="primary" @click="onSubmit">提交信息</el-button>
          </el-form-item>
        </el-form>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import {role_register} from "@/utils/user_utils";

export default {
  name: "StudentRegister",
  props: {
    is_role_register: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: "90%",
      submitted: false,
      loading: false,
      student: {
        name: "",
        subject: "",
        detail: "",
        time: "",
        level: "",
        school: "",
        contact: ""
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名或昵称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 15 个字符', trigger: 'blur'}
        ],
        subject:
            {required: true, message: '请输入学科信息', trigger: 'blur'},
        detail:
            {required: true, message: '请输入详细信息', trigger: 'blur'},
        time:
            {required: true, message: '请输入毕业时间', trigger: 'blur'},
        level:
            {required: true, message: '请输入年级', trigger: 'blur'},
        school:
            {required: true, message: '请输入在读学校', trigger: 'blur'},
        contact: {required: true, message: '请输入联系方式', trigger: 'blur'},

      }
    }
  }
  ,
  mounted() {
  }
  ,
  methods: {
    onSubmit() {
      let that = this
      var url = this.api.root_url + "add_student"
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (that.is_role_register) {
            role_register(that.student, that)
          } else {
            axios.post(url, this.student)
                .then(function (res) {
                      that.loading = false
                      that.$message.success("提交成功，可联系小助手咨询进展,微信：zqwei-tech")
                      that.submitted = true
                    }
                )
                .catch(function (error) { // 请求失败处理
                  that.loading = false
                  that.$message.error("提交错误，请检查网络连接！")
                });
          }

        }
      })
    }
    ,
    onBack() {
      this.submitted = false
    }
  }
}
</script>

<style scoped>
.form_text{
  color: #99a9bf;
}
</style>