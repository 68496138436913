import axios from "axios";
import crypto_utils from "@/utils/crypto_utils";
import utils, {getDecryptServerData, getSfapiUrl, getSfapiUrlWithData} from "@/utils/utils"
import Vue from "vue";
import {generateGetUrl} from "@/utils/utils";
import th from "element-ui/src/locale/lang/th";

export function get_teachers(that, params = {}, init = false) {
    let url = getSfapiUrlWithData(that.api.sf_get_teachers, params)
    axios
        .get(url)
        .then(function (res) {
                that.teachers = getDecryptServerData(res.data)
                if (init) {
                    // that.subjectSelectTotal()
                }
                that.updateRealDisplayTeachers()
            }
        )
        .catch(function (error) { // 请求失败处理
            // console.log(error)
            that.$message.error('网络连接不畅...请稍后重试');
        });
}

export function get_teacher_detail(id, that) {
    const url = getSfapiUrlWithData(that.api.sp_get_teacher_detail, {"id": id})
    that.detailLoading = true
    axios
        .get(url)
        .then(function (res) {
                let data = getDecryptServerData(res.data)
                if (data === "no id") {
                    that.goBack()
                    that.$message.error('无效的id号！该老师可能暂时下线了哦~');
                } else {
                    that.teacher = data
                    that.goTop()
                    that.changeTitle()
                }
                that.detailLoading = false
            }
        )
        .catch(function (error) { // 请求失败处理
            that.detailLoading = false
            that.goBack()
            that.$message.error('网络连接不畅...请稍后重试');
            // console.log(error)
        });
}

export function get_subjects(that, params = {}) {
    var url = that.api.get_subjects
    that.loading = true
    // url = generateGetUrl(url, params)
    url = getSfapiUrl(that.api.sf_get_subjects, {})
    axios
        .get(url)
        .then(function (res) {
                var data = getDecryptServerData(res.data)
                that.subjects = data.subjects
                that.loading = false
                var exam_dict = {}
                var stage_dict = {}
                var exam_dict_re = {}
                var stage_dict_re = {}
                var subject_teacher_dict = {}
                for (var i = 0; i < data.stage.length; i++) {
                    stage_dict[data.stage[i].id] = data.stage[i].name
                    stage_dict_re[data.stage[i].name] = data.stage[i].id
                }
                for (var i = 0; i < data.exam.length; i++) {
                    exam_dict[data.exam[i].id] = data.exam[i].name
                    exam_dict_re[data.exam[i].name] = data.exam[i].id
                }
                for (var i = 0; i < data.teacher_subject.length; i++) {
                    subject_teacher_dict[data.teacher_subject[i].subject_id] = []
                }
                for (var i = 0; i < data.teacher_subject.length; i++) {
                    subject_teacher_dict[data.teacher_subject[i].subject_id].push(data.teacher_subject[i].onetooneteacher_id)
                }
                that.teacher_subject = subject_teacher_dict
                that.exam = {idToName: exam_dict, nameToId: exam_dict_re, "obj": data.exam}
                that.stage = {idToName: stage_dict, nameToId: stage_dict_re, "obj": data.stage}
                that.sortSubjects()
                get_teachers(that, {"sort": that.sortOption, "filter": that.filterList}, true)
            }
        )
        .catch(function (error) { // 请求失败处理
            // console.log(error)
            that.$message.error('网络连接不畅...请稍后重试');
        });
}
