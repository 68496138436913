<template>
  <slide-verify :l="42"
                :r="10"
                :w="310"
                :h="155"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                :slider-text="this.$t('captcha.slide_right')"
  ></slide-verify>
</template>

<script>
export default {
  name: "SlideCaptcha",
  data() {
    return {
    }
  },
  methods: {
    onSuccess() {
      this.$emit("slide_success")
    },
    onFail() {
      this.$message.error(this.$t("captcha.verify_failed"))
    },
    onRefresh() {
    }
  }
}
</script>

<style scoped>

</style>