<template>
  <div class="page_basic_container user_background center_col_container">
    <div class="center_col_container tf_et"
         style="padding: 40px;margin: 20px;background-color: white;border-radius: 5px">
      <span style="font-size: 20px;font-weight: 500;padding: 10px">财务信息填报</span>
      <template v-if="entering">
        <el-form v-loading="loading" ref="form" :rules="rules" label-position="top" :model="info"
                 label-width="auto"
                 :style="'width:100%' ">
          <el-form-item label="个人真实姓名" prop="real_name">
            <el-input v-model="info.real_name" placeholder="请输入个人真实姓名，用以个人所得税代缴"></el-input>
          </el-form-item>

          <el-form-item label="个人居民身份证号" prop="id_number">
            <el-input v-model="info.id_number" placeholder="请输入个人身份证号，用以个人所得税代缴"></el-input>
          </el-form-item>

          <el-form-item label="个人手机号码" prop="phone">
            <el-input v-model="info.phone" placeholder="11位国内手机号码"></el-input>
          </el-form-item>

          <el-form-item label="支付宝实名姓名" prop="alipay_name">
            <el-input v-model="info.alipay_name" placeholder="支付宝账号实名，用于平台自动结算转账验证"></el-input>
          </el-form-item>

          <el-form-item label="支付宝账号" prop="alipay_account">
            <el-input v-model="info.alipay_account" placeholder="支付宝账号，用于平台自动结算"></el-input>
          </el-form-item>

          <el-form-item label="个人邮箱地址" prop="email">
            <el-input v-model="info.email" placeholder="用于接受财务结算信息"></el-input>
          </el-form-item>

          <el-form-item class="center_col_container">
            <el-button type="primary" @click="onSubmit">提交信息</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <span style="padding: 20px;padding-bottom:0px;font-size: 25px">你已成功提交信息！</span>
        <img src="../../assets/small_e/correct2.jpg" style="width: 55%;height: auto;margin-bottom: 20px">
        <el-button @click="$router.push('/user/center');$EventBus.$emit('user_center')">返回用户中心</el-button>
      </template>
    </div>
  </div>
</template>

<script>
import {role_register} from "@/utils/user_utils";
import axios from "axios";
import {update_financial_info} from "@/utils/netschool_utils";

export default {
  name: "FinancialInfoForm",
  data() {
    return {
      loading: false,
      entering: true,
      info: {
        real_name: "",
        alipay_account: "",
        alipay_name: "",
        email: "",
        phone: "",
        id_number: ""
      },
      rules: {
        real_name: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
          {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
        ],
        alipay_account:
            {required: true, message: '请输入支付宝账号', trigger: 'blur'},
        alipay_name:
            [{required: true, message: '请输入支付宝实名姓名', trigger: 'blur'},
              {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}],
        phone:
            [{required: true, message: '请输入11位中国手机号', trigger: 'blur'},
              {min: 11, max: 11, message: '请输入11位中国手机号', trigger: 'blur'}],
        id_number:
            [{required: true, message: '请输入身份证号码', trigger: 'blur'},
              {min: 18, max: 18, message: '身份证号码应该为18位！', trigger: 'blur'}]
      }
    }
  },
  methods: {
    onSubmit() {
      let that = this
      this.$refs["form"].validate((valid) => {
        if (valid) {
          update_financial_info(this.info, this)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>