<template>
  <div class="center_col_container">
    <div class="center_col_container background" style="height: 700px;width: 100%">
      <div style="width:100%;height:100%;"
           class="center_col_container overlay">
        <div class="center_col_container" style="width: 75%;text-align: center">
          <span
              style="font-size: 70px;margin-bottom: 15px;color: white;-webkit-text-stroke: 1.0px #fefefe;
              font-family: 'Segoe Print'">EasyPaper</span>
          <span
              style="font-size: 30px;margin-bottom: 25px;color: white;">致力于用科技赋能国际教育，解决您的一切问题</span>
          <!--          <el-button style="padding-left: 20px;padding-right:20px;font-size: 19px">立即开始</el-button>-->
        </div>
      </div>
    </div>
    <div class="hidden-md-and-up">
      <div class="center_col_container" style="background-color: #fefefe;padding-top: 50px;padding-bottom: 50px">
        <span class="section_title">我们的服务</span>
        <span style="color: grey">EasyPaper提供大量优质的申请辅导一条龙服务</span>
        <div class="center_col_container" style="width: 90%;padding: 10px">
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 10px;padding: 20px;padding-top: 25px;width: 80%">
            <i type="primary" class="el-icon-s-custom icon"></i>
            <span class="service_title">在线授课</span>
            <p style="color: dimgray">
              通过线上授课的方法让学生快速匹配合适老师并进行辅导学习，免去前往补习地点的时间浪费，而专注于学习本身~平台上国内外海量优质师资资源，供你随意选择！
            </p>
            <div class="nav">
              <a href="discover">READ MORE</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 10px;padding: 20px;padding-top: 25px;width: 80%">
            <i type="primary" class="el-icon-s-claim icon"></i>
            <span class="service_title">文书辅导</span>
            <p style="color: dimgray">
              我们的海量老师可以提供专业的文书辅导，多方建议，素材准备，内容修改，语言定稿等服务！让PS不再无从下手！
            </p>
            <div class="nav">
              <a href="service">Read More</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 10px;padding: 20px;padding-top: 25px;width: 80%">
            <i type="primary" class="el-icon-s-marketing icon"></i>
            <span class="service_title">模拟面试</span>
            <p style="color: dimgray">
              牛剑帝国精英老师在线辅导&模拟，以成功者和过来人的经历教你面试的各种应对技巧与特殊情况的方法，助力你的名校梦！
            </p>
            <div class="nav">
              <a href="service">Read More</a>
            </div>
          </div>
        </div>
        <div class="center_col_container" style="width: 90%;padding: 10px">
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 10px;padding: 20px;padding-top: 25px;width: 80%">
            <i type="primary" class="el-icon-s-opportunity icon"></i>
            <span class="service_title">智能教辅&免费题库</span>
            <p style="color: dimgray">
              EasyPaper拥有全网最大最全的题库，涵盖IB/AP/A-level主流考试局，且文件总量已超过100万之多，
              是目前国际教育界最流行的软件APP之一~
            </p>
            <div class="nav">
              <a href="https://easy-paper.com/">READ MORE</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 10px;padding: 20px;padding-top: 25px;width: 80%">
            <i type="primary" class="el-icon-s-promotion icon"></i>
            <span class="service_title">智能AI平台</span>
            <p style="color: dimgray">
              基于最新的人工智能分题系统，收录题目总量达数百万级，随着新一代自研OCR,词嵌入与向量型搜索引擎技术，
              将使得我们能够推出针对国际教育的史上最强且完全免费的AI智能学习平台。
            </p>
            <div class="nav">
              <a href="https://easy-paper.com/app_download">Read More</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 10px;padding: 20px;padding-top: 25px;width: 80%">
            <i type="primary" class="el-icon-s-help icon"></i>
            <span class="service_title">EasyPaper网校系统</span>
            <p style="color: dimgray">
              通过自身强大的技术实力，开发出完善的教育教研科技体系，减除了复杂的人工操作，使得EasyPaper真正成为一个自由的约课平台，以更好的服务于我们的客户。
            </p>
            <div class="nav">
              <a href="user/center">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="center_col_container" style="padding-top: 50px;padding-bottom: 50px">
        <div class="center_col_container" style="width: 95%;background-color: #f7f7f7;padding: 20px">
          <div class="center_col_container">
            <div class="flex_start_col_container" style="width: 90%;padding: 25px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">我们是谁？</span>
              <span style="color: grey;line-height: 2.0">EasyPaper教育科技致力于使用最先进的AI教育科技来赋能国际教育领域，
              让全天下的老师、学生、学校能用上免费优质的教育资源与软件。<b>我们是一家伪装成“教育公司”的纯创新新型人工智能科技公司。</b>
        本网站是EasyPaper教育科技旗下的综合型资源整合平台，包括在线一对一老师选择服务，教育服务对接等
              ，<b>没有机构分佣赚取信息差</b>，使得价格便宜且透明！</span>
            </div>
            <div class="center_col_container" style="width: 90%;padding: 25px">
              <img style="width: 100%;height: auto;margin-top: 0px"
                   src="../assets/团队协作面板.png">
            </div>
          </div>
          <div class="center_col_container">
            <div class="flex_start_col_container" style="width: 90%;padding: 25px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">传统机构的一些问题</span>
              <span style="color: grey;line-height: 2.0">经过我们团队半年的调研与研究，当前市面上存在多数教育机构，无论大小，
              其核心本质是通过收取高昂的信息差来赚取利润。
              根据我们的了解，基本绝大部分市面上的Alevel一对一服务价格都<b>堪称天价</b>，但机构老师的实际工资仅占到实际支付学费<b>的很小一部分</b>，
                这其实是因为大部分的利润实际是被用在了机构基础开销，服务营销与宣传上，包括<b>搜索引擎广告排名营销，人工客服</b>等，对服务品质提升没有实际作用。
              特别是即使费用已经如此昂贵，但还是学生遇到<b>“水老师”</b>不作为不好好上课备课的情况。这是因为在大部分机构里，每位同学的老师是由<b>机构分配的</b>，
                有时候能不能碰到好的老师有时候就是有点在赌运气。</span>
            </div>
            <div class="center_col_container" style="width: 90%;padding: 45px;margin-top: 10px">
              <img style="width: 100%;height: auto"
                   src="../assets/解决问题.png">
            </div>
          </div>
          <div class="center_col_container">
            <div class="flex_start_col_container" style="width: 90%;padding: 25px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">我们的解决方案</span>
              <span style="color: grey;line-height: 2.0">
              我们致力于通过科技的手段解决传统机构的存在的问题，故我们上线了EasyPaper在线平台，帮助各位家长与学生通过透明化的渠道在线选择合适的老师，没有中间商抽取利润并用于营销获客目的，
              <b>价格仅为传统教育机构的30%甚至更低。</b>老师经过层层审核和我们+学生反馈排名，他们的信息公开透明，以供家长自由选择，不再是传统机构的“黑箱”模型。
              并且多数老师提供免费试听，<b>且我们承诺不满意可以随时换人、额外课时立即退费</b>，着极大的保证了老师的质量与水平与上课的灵活性。
            </span>
            </div>
            <div class="center_col_container" style="width: 90%;padding: 25px">
              <img style="width: 100%;height: auto;margin-top: 10px"
                   src="../assets/女团队协作.png">
            </div>
          </div>
          <div class="center_col_container" style="position: relative">
            <div class="flex_start_col_container" style="width: 90%;padding: 25px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">我们的技术力</span>
              <span style="color: grey;line-height: 2.0">
              作为国际教育智能教辅的领先品牌，老师均享有以EasyPaper为主体的海量优质教研，笔记，专题练习，Pastpaper资料，从而全面提升平台入驻老师课堂质量，
                能将精力更好的放到一对一学生针对性补习上。除了智能教辅/软件/平台在课程方面我们的<b>EasyPaper网校体系已全线上线</b>，
              通过自动化的约课、排课、教学、反馈一条龙服务，以确保提供教学的质量，同时全流程系统会实时
              <b>将非优质，“水课”，质量低的老师清除出我们的系统</b>。
              另外，我们也会将全部资源将尽数投入科技研发，并通过大量免费且优质的产品争取学校、家长、学生用户的支持，故我们不需要令人反感方案的传统营销策略，
              <b>我们只集中精力在如何提升我们的技术力与对用户服务质量的提升上。</b>
              </span>
              <!--            作为一家以科技与人工智能技术立本的企业，我们的团队具备世界顶级的AI研发能力，而我们的主要精力会放在如何提升我们的服务质量与制作全新的智能教辅。-->
              <!--            平均一个软件/平台的开发周期均在15天以内，我们将在未来的时间里以半月一个产品的速度高速更新我们的免费服务来帮助国际教育的学生。-->
            </div>
            <div class="center_col_container" style="width: 90%;padding: 25px">
              <img style="width: 80%;height: auto;margin-top: 10px"
                   src="../assets/2.5D-C-区域技术市场.png">
            </div>
          </div>
        </div>
      </div>
      <div class="center_col_container jiaofu">
        <div class="center_col_container"
             style="background:rgba(80, 185, 206, 0.8);width: 100%;height: 100%;padding-top: 200px;padding-bottom: 200px">
          <div class="center_col_container" style="width: 90%;text-align: center">
            <span style="font-size: 35px;margin-bottom: 15px;color: white">我们的产品</span>
            <span style="font-size: 18px;margin-bottom: 25px;color: white;line-height:2.2">
          EasyPaper科技致力于通过使用智能教辅产品来帮助学生更好的完成学业，旗下产品包括EasyPaper资源对接平台，CMS网校教育管理系统，AstarWorld交流论坛，
          EasyPaper拍照搜题系列（网站/APP/小程序），在线自习室，资料书库，A-level自学社，EasyUni申请助手，EasyWords学科单词等...</span>
          </div>
          <el-button style="padding-left: 20px;padding-right:20px;font-size: 19px" @click="goToProdution">
            查看详情
          </el-button>
        </div>
      </div>
      <div class="center_col_container" style="padding-top: 100px;padding-bottom: 50px;width:100%;height:100%;">
        <div class="center_col_container" style="width: 60%;text-align: center">
          <span class="section_title"> 我们的老师</span>
          <span style="color: grey">我们的平台老师来自于世界知名学府，包括剑桥，牛津，帝国理工等等...</span>
        </div>
        <div class="space_between_row_container" style="width: 90%">
          <div style="position: relative;padding: 20px;width: 33%" class="teacher_container">
            <div style="position: relative;margin:auto">
              <img style="width: 100%;height: auto;"
                   src="http://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/03/lmz.jpg">
              <div style="width: 100%;height: 100%" class="inner-content">
                <div class="flex_start_col_container" style="position: absolute;bottom: 5px;padding: 20px">
                  <span style="color: white;font-weight: 600;font-size: 14px">M老师</span>
                  <span style="color: white;font-weight: 500;font-size: 12px">牛津大学 地球科学系</span>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative;padding: 20px;width: 33%" class="teacher_container">
            <div style="position: relative;margin:auto">
              <img style="width: 100%;height: auto"
                   src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/07/pitt-1.png">
              <div style="width: 100%;height: 100%" class="inner-content">
                <div class="flex_start_col_container" style="position: absolute;bottom: 5px;padding: 20px">
                  <span style="color: white;font-weight: 600;font-size: 14px">P老师</span>
                  <span style="color: white;font-weight: 500;font-size: 12px">香港大学商学院 金融会计专业</span>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative;padding: 20px;width: 33%" class="teacher_container">
            <div style="position: relative;margin:auto">
              <img style="width: 100%;height: auto"
                   src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/07/jetsq2.png">
              <div style="width: 100%;height: 100%" class="inner-content">
                <div class="flex_start_col_container" style="position: absolute;bottom: 5px;padding: 20px">
                  <span style="color: white;font-weight: 600;font-size: 14px">J老师</span>
                  <span style="color: white;font-weight: 500;font-size: 12px">牛津大学 数学系</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-button style="padding-left: 20px;padding-right:20px;font-size: 16px" @click="$router.push('discover')">
          查看全部
        </el-button>
      </div>
      <div class="center_col_container"
           style="background-color: #f1f1f1;padding-top: 100px;padding-bottom: 50px;width:100%;height:100%;">
        <span class="section_title"> 我们的学生</span>
        <span style="color: grey">看看来自真实学生的评价~</span>
        <div class="center_col_container" style="width:75%;padding-top: 20px">
          <div class="flex_start_col_container"
               style="width:100%;padding: 30px;margin: 10px;background-color: white;box-shadow: 2px 2px 2px rgba(0,0,0,.04);
             position: relative">
            <span style="font-weight: 600;font-size: 18px;margin-bottom: 20px;margin-top: 20px">P同学</span>
            <p style="font-size: 15px;color: dimgray;line-height:2.1;padding-bottom: 100px">
              EasyPaper给了我非常多的帮助，包括EasyPaper专题训练和从小助手那边了解到的补课老师，感觉是真滴良心。最感动的是他们的性价比好高，比外面的机构省了好多银子，可以直接对接到我喜欢的老师。（滑稽）让我在大考前能够安心准备与复习！直接起飞，强烈安利奥！
            </p>
            <div class="flex_start_center_row_container" style="margin-top: 20px;position: absolute;bottom: 20px">
              <div class="center_col_container">
                <img src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/03/tx2.jpeg"
                     class="avatar">
              </div>
              <div class="flex_start_col_container" style="margin-top: auto;margin-bottom: auto;margin-left: 15px">
                <span style="font-weight: 600;font-size: 17px;">P同学</span>
                <span style="font-size: 14px;color: dimgray">帝国理工电子电气工程录取 A*A*A*A*</span>
              </div>
            </div>
          </div>
          <div class="flex_start_col_container"
               style="width:100%;padding: 30px;margin: 10px;background-color: white;box-shadow: 2px 2px 2px rgba(0,0,0,.04);
;position: relative;">
            <span style="font-weight: 600;font-size: 18px;margin-bottom: 20px;margin-top: 20px">D同学</span>
            <p style="font-size: 15px;color: dimgray;line-height:2.1;padding-bottom: 100px">
              作为一名EasyPaper的老用户，第一次刚刚接触他们家补课老师的时候想不到小助手和老师的督促竟然这么到位。我一个懒癌晚期都能被逼成学习人。听说平台终于上线，这里先恭喜一下啦~总之走过路过千万别错过。点他收获惊喜，点他收获宝藏~
            </p>
            <div class="flex_start_center_row_container" style="margin-top: 20px;position: absolute;bottom: 20px">
              <div class="center_col_container">
                <img src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/03/tx1.jpg"
                     class="avatar">
              </div>
              <div class="flex_start_col_container" style="margin-top: auto;margin-bottom: auto;margin-left: 15px">
                <span style="font-weight: 600;font-size: 17px;">D同学</span>
                <span style="font-size: 14px;color: dimgray">香港大学工程系录取 A*A*A*A*</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_col_container"
           style="background-color:white;padding-top: 100px;padding-bottom: 50px;width:100%;height:100%;">
        <div class="center_col_container" style="width:80%">
          <span class="section_title">还有疑问？</span>
          <span style="color: grey;margin-bottom: 10px">欢迎自己来看看或者咨询我们的小E客服助手哦~</span>
        </div>
        <ServiceDialogBox width="80%" height="400px" :show-close="false"></ServiceDialogBox>
        <el-button round plain type="primary" style="margin-top: 20px" @click="$router.push('discover')">
          点我开始在线择师
        </el-button>
      </div>
    </div>
    <div class="hidden-sm-and-down">
      <div class="center_col_container" style="background-color: #fefefe;padding-top: 50px;padding-bottom: 50px">
        <span class="section_title">我们的服务</span>
        <span style="color: grey">EasyPaper提供大量优质的申请辅导一条龙服务</span>
        <div class="space_around_row_container" style="width: 80%;padding: 10px">
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 30px;padding: 30px;padding-top: 45px;width: 33%">
            <i type="primary" class="el-icon-s-custom icon"></i>
            <span class="service_title">在线授课</span>
            <p style="color: dimgray">
              通过线上授课的方法让学生快速匹配合适老师并进行辅导学习，免去前往补习地点的时间浪费，而专注于学习本身~平台上国内外海量优质师资资源，供你随意选择！
            </p>
            <div class="nav">
              <a href="discover">READ MORE</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 30px;padding: 30px;padding-top: 45px;width: 33%">
            <i type="primary" class="el-icon-s-claim icon"></i>
            <span class="service_title">文书辅导</span>
            <p style="color: dimgray">
              我们的海量老师可以提供专业的文书辅导，多方建议，素材准备，内容修改，语言定稿等服务！让PS不再无从下手！
            </p>
            <div class="nav">
              <a href="service">Read More</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 30px;padding: 30px;padding-top: 45px;width: 33%">
            <i type="primary" class="el-icon-s-marketing icon"></i>
            <span class="service_title">模拟面试</span>
            <p style="color: dimgray">
              牛剑帝国精英老师在线辅导&模拟，以成功者和过来人的经历教你面试的各种应对技巧与特殊情况的方法，助力你的名校梦！
            </p>
            <div class="nav">
              <a href="service">Read More</a>
            </div>
          </div>
        </div>
        <div class="space_around_row_container" style="width: 80%;padding: 10px">
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 30px;padding: 30px;padding-top: 45px;width: 33%">
            <i type="primary" class="el-icon-s-opportunity icon"></i>
            <span class="service_title">智能教辅&免费题库</span>
            <p style="color: dimgray">
              EasyPaper拥有全网最大最全的题库，涵盖IB/AP/A-level主流考试局，且文件总量已超过100万之多，
              是目前国际教育界最流行的软件APP之一~
            </p>
            <div class="nav">
              <a href="https://easy-paper.com/">READ MORE</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 30px;padding: 30px;padding-top: 45px;width: 33%">
            <i type="primary" class="el-icon-s-promotion icon"></i>
            <span class="service_title">智能AI平台</span>
            <p style="color: dimgray">
              基于最新的人工智能分题系统，收录题目总量达数百万级，随着新一代自研OCR,词嵌入与向量型搜索引擎技术，
              将使得我们能够推出针对国际教育的史上最强且完全免费的AI智能学习平台。
            </p>
            <div class="nav">
              <a href="https://easy-paper.com/app_download">Read More</a>
            </div>
          </div>
          <div class="flex_start_col_container hover_shadow service_cell"
               style="margin: 30px;padding: 30px;padding-top: 45px;width: 33%">
            <i type="primary" class="el-icon-s-help icon"></i>
            <span class="service_title">EasyPaper网校系统</span>
            <p style="color: dimgray">
              通过自身强大的技术实力，开发出完善的教育教研科技体系，减除了复杂的人工操作，使得EasyPaper真正成为一个自由的约课平台，以更好的服务于我们的客户。
            </p>
            <div class="nav">
              <a href="user/center">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="center_col_container" style="padding-top: 50px;padding-bottom: 50px">
        <div class="center_col_container" style="width: 70%;background-color: #f7f7f7;padding: 45px">
          <div class="space_between_start_row_container">
            <div class="center_col_container" style="width: 50%;padding: 45px">
              <img style="width: 100%;height: auto;margin-top: 0px"
                   src="../assets/团队协作面板.png">
            </div>
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">我们是谁？</span>
              <span style="color: grey;line-height: 2.0">EasyPaper教育科技致力于使用最先进的AI教育科技来赋能国际教育领域，
              让全天下的老师、学生、学校能用上免费优质的教育资源与软件。<b>我们是一家伪装成“教育公司”的纯创新新型人工智能科技公司。</b>
        本网站是EasyPaper教育科技旗下的综合型资源整合平台，包括在线一对一老师选择服务，教育服务对接等
              ，<b>没有机构赚高额信息差，低廉的平台费</b>，使得价格便宜且透明！</span>
            </div>
          </div>
          <div class="space_between_start_row_container">
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">传统机构的一些问题</span>
              <span style="color: grey;line-height: 2.0">经过我们团队半年的调研与研究，当前市面上存在多数教育机构，无论大小，
              其核心本质是通过收取高昂的信息差来赚取利润。
              根据我们的了解，基本绝大部分市面上的Alevel一对一服务价格都<b>堪称天价</b>，但机构老师的实际工资仅占到实际支付学费<b>的很小一部分</b>，
                这其实是因为大部分的利润实际是被用在了机构基础开销，服务营销与宣传上，包括<b>搜索引擎广告排名营销，人工客服</b>等，对服务品质提升没有实际作用。
              特别是即使费用已经如此昂贵，但还是学生遇到<b>“水老师”</b>不作为不好好上课备课的情况。这是因为在大部分机构里，每位同学的老师是由<b>机构分配的</b>，
                有时候能不能碰到好的老师有时候就是有点在赌运气。</span>
            </div>
            <div class="center_col_container" style="width: 50%;padding: 45px;margin-top: 10px">
              <img style="width: 100%;height: auto"
                   src="../assets/解决问题.png">
            </div>
          </div>
          <div class="space_between_start_row_container">
            <div class="center_col_container" style="width: 50%;padding: 45px">
              <img style="width: 100%;height: auto;margin-top: 10px"
                   src="../assets/女团队协作.png">
            </div>
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">我们的解决方案</span>
              <span style="color: grey;line-height: 2.0">
              为了更好的以我们的技术力来帮助广大学生家长获得更好的教育资源，优化服务质量，最终我们上线了EasyPaper资源对接平台，
                旨在帮助各位家长与学生通过透明化的渠道在线选择合适的老师，没有中间机构抽取利润并用于营销获客目的，
              <b>价格仅为传统教育机构的1/4-1/3甚至更低。</b>老师经过层层审核和我们+学生反馈排名，并基于智能算法定期筛选推送，变更排名。
                他们的信息公开透明，可供家长自由选择，故而不再是传统机构的“黑箱”择师模型。
              并且多数老师提供免费试听，<b>且我们承诺因老师教学水平原因导致的不满意可以随时换人、或是进行退费处理</b>，这极大的保证了老师的质量与水平。
            </span>
            </div>
          </div>
          <div class="space_between_start_row_container" style="position: relative">
            <div class="flex_start_col_container" style="width: 50%;padding: 45px">
              <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">我们的技术力</span>
              <span style="color: grey;line-height: 2.0">
              作为国际教育智能教辅的领先品牌，老师均享有以EasyPaper为主体的海量优质教研，笔记，专题练习，Pastpaper资料，从而全面提升平台入驻老师课堂质量，
                能将精力更好的放到一对一学生针对性补习上。除了智能教辅/软件/平台，在课程方面我们的<b>EasyPaper网校体系已全线上线</b>，
              通过自动化的约课、排课、教学、反馈一条龙服务，以确保提供教学的质量，同时全流程系统会实时
              <b>将非优质，“水课”，质量低的老师清除出我们的系统</b>。
              另外，我们也会将全部资源将尽数投入科技研发，并通过大量免费且优质的产品争取学校、家长、学生用户的支持，故我们不需要令人反感方案的传统营销策略，
              <b>我们只集中精力在如何提升我们的技术力与对用户服务质量的提升上。</b>
              </span>
              <!--            作为一家以科技与人工智能技术立本的企业，我们的团队具备世界顶级的AI研发能力，而我们的主要精力会放在如何提升我们的服务质量与制作全新的智能教辅。-->
              <!--            平均一个软件/平台的开发周期均在15天以内，我们将在未来的时间里以半月一个产品的速度高速更新我们的免费服务来帮助国际教育的学生。-->
            </div>
            <div class="center_col_container" style="width: 50%;padding: 45px">
              <img style="width: 80%;height: auto;margin-top: 10px"
                   src="../assets/2.5D-C-区域技术市场.png">
            </div>
          </div>
        </div>
      </div>
      <div class="center_col_container jiaofu">
        <div class="center_col_container"
             style="background:rgba(80, 185, 206, 0.8);width: 100%;height: 100%;padding-top: 200px;padding-bottom: 200px">
          <div class="center_col_container" style="width: 60%;text-align: center">
            <span style="font-size: 35px;margin-bottom: 15px;color: white">我们的产品</span>
            <span style="font-size: 18px;margin-bottom: 25px;color: white;line-height:2.2">
           EasyPaper科技致力于通过使用智能教辅产品来帮助学生更好的完成学业，旗下产品包括EasyPaper资源平台，AstarWorld交流论坛，
          EasyPaper拍照搜题系列（网站/APP/小程序），在线自习室，资料书库，A-level自学社，EasyUni申请助手，EasyWords学科单词等...</span>
          </div>
          <el-button style="padding-left: 20px;padding-right:20px;font-size: 19px" @click="goToProdution">
            查看详情
          </el-button>
        </div>
      </div>
      <div class="center_col_container" style="padding-top: 100px;padding-bottom: 50px;width:100%;height:100%;">
        <span class="section_title">我们的老师</span>
        <span style="color: grey">我们的平台老师来自于世界知名学府，包括剑桥，牛津，帝国理工等等...</span>
        <div class="space_between_row_container" style="width: 76%">
          <div style="position: relative;padding: 20px;width: 33%" class="teacher_container">
            <div style="position: relative;margin:auto">
              <img style="width: 100%;height: auto;"
                   src="http://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/03/lmz.jpg">
              <div style="width: 100%;height: 100%" class="inner-content">
                <div class="flex_start_col_container" style="position: absolute;bottom: 20px;padding: 20px">
                  <span style="color: white;font-weight: 600;font-size: 22px">M老师</span>
                  <span style="color: white;font-weight: 500;font-size: 18px">牛津大学 地球科学系</span>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative;padding: 20px;width: 33%" class="teacher_container">
            <div style="position: relative;margin:auto">
              <img style="width: 100%;height: auto"
                   src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/07/pitt-1.png">
              <div style="width: 100%;height: 100%" class="inner-content">
                <div class="flex_start_col_container" style="position: absolute;bottom: 20px;padding: 20px">
                  <span style="color: white;font-weight: 600;font-size: 22px">P老师</span>
                  <span style="color: white;font-weight: 500;font-size: 18px">香港大学商学院 金融会计专业</span>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative;padding: 20px;width: 33%" class="teacher_container">
            <div style="position: relative;margin:auto">
              <!--              <img style="width: 100%;height: auto"-->
              <!--                   src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/07/pacey1-副本-2.png">-->
              <!--              <div style="width: 100%;height: 100%" class="inner-content">-->
              <!--                <div class="flex_start_col_container" style="position: absolute;bottom: 20px;padding: 20px">-->
              <!--                  <span style="color: white;font-weight: 600;font-size: 22px">Y老师</span>-->
              <!--                  <span style="color: white;font-weight: 500;font-size: 18px">帝国理工 电子信息工程</span>-->
              <!--                </div>-->
              <!--              </div>-->
              <img style="width: 100%;height: auto"
                   src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/07/jetsq2.png">
              <div style="width: 100%;height: 100%" class="inner-content">
                <div class="flex_start_col_container" style="position: absolute;bottom: 20px;padding: 20px">
                  <span style="color: white;font-weight: 600;font-size: 22px">J老师</span>
                  <span style="color: white;font-weight: 500;font-size: 18px">牛津大学 数学系</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-button style="padding-left: 20px;padding-right:20px;font-size: 16px" @click="$router.push('discover')">
          查看全部
        </el-button>
      </div>
      <div class="center_col_container"
           style="background-color: #f1f1f1;padding-top: 100px;padding-bottom: 50px;width:100%;height:100%;">
        <span class="section_title"> 我们的学生</span>
        <span style="color: grey">看看来自真实学生的评价~</span>
        <div class="space_around_row_container" style="width:75%;padding-top: 20px">
          <div class="flex_start_col_container"
               style="width:50%;padding: 30px;margin: 10px;background-color: white;box-shadow: 2px 2px 2px rgba(0,0,0,.04);
             position: relative">
            <span style="font-weight: 600;font-size: 18px;margin-bottom: 20px;margin-top: 20px">P同学</span>
            <p style="font-size: 15px;color: dimgray;line-height:2.1;padding-bottom: 100px">
              EasyPaper给了我非常多的帮助，包括EasyPaper专题训练和从小助手那边了解到的补课老师，感觉是真滴良心。最感动的是他们的性价比好高，比外面的机构省了好多银子，可以直接对接到我喜欢的老师。（滑稽）让我在大考前能够安心准备与复习！直接起飞，强烈安利奥！
            </p>
            <div class="flex_start_center_row_container" style="margin-top: 20px;position: absolute;bottom: 20px">
              <div class="center_col_container">
                <img src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/03/tx2.jpeg"
                     class="avatar">
              </div>
              <div class="flex_start_col_container" style="margin-top: auto;margin-bottom: auto;margin-left: 15px">
                <span style="font-weight: 600;font-size: 17px;">P同学</span>
                <span style="font-size: 14px;color: dimgray">帝国理工电子电气工程录取 A*A*A*A*</span>
              </div>
            </div>
          </div>
          <div class="flex_start_col_container"
               style="width:50%;padding: 30px;margin: 10px;background-color: white;box-shadow: 2px 2px 2px rgba(0,0,0,.04);
;position: relative">
            <span style="font-weight: 600;font-size: 18px;margin-bottom: 20px;margin-top: 20px">D同学</span>
            <p style="font-size: 15px;color: dimgray;line-height:2.1">
              作为一名EasyPaper的老用户，第一次刚刚接触他们家补课老师的时候想不到小助手和老师的督促竟然这么到位。我一个懒癌晚期都能被逼成学习人。听说平台终于上线，这里先恭喜一下啦~总之走过路过千万别错过。点他收获惊喜，点他收获宝藏~
            </p>
            <div class="flex_start_center_row_container" style="margin-top: 20px;position: absolute;bottom: 20px">
              <div class="center_col_container">
                <img src="https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/03/tx1.jpg"
                     class="avatar">
              </div>
              <div class="flex_start_col_container" style="margin-top: auto;margin-bottom: auto;margin-left: 15px">
                <span style="font-weight: 600;font-size: 17px;">D同学</span>
                <span style="font-size: 14px;color: dimgray">香港大学工程系录取 A*A*A*A*</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_col_container" style="width: 100%">
        <LessonProcessDisplay/>
      </div>
      <div class="center_col_container"
           style="background-color:white;padding-top: 100px;padding-bottom: 50px;width:100%;height:100%;">
        <span class="section_title">还有疑问？</span>
        <span style="color: grey;margin-bottom: 10px">欢迎自己来看看或者咨询我们的小E客服助手哦~</span>
        <ServiceDialogBox width="550px" height="400px" :show-close="false"></ServiceDialogBox>
        <el-button round plain type="primary" style="margin-top: 20px" @click="$router.push('discover')">
          点我开始在线择师
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherPreview from "@/components/TeacherPreview";
import TeacherDetail from "@/components/TeacherDetail";
import ServiceDialogBox from "@/components/ServiceDialogBox";
import {getSfapiUrl} from "@/utils/utils";
import crypto_utils from "@/utils/crypto_utils";
import BackgroundHeader from "@/components/BackgroundHeader";
import LessonProcessDisplay from "@/components/display_module/LessonProcessDisplay";

export default {
  name: "Home",
  components: {LessonProcessDisplay, BackgroundHeader, ServiceDialogBox, TeacherDetail, TeacherPreview},
  mounted() {

  },
  methods: {
    goToProdution() {
      this.$router.push("products")
    }
  }
}
</script>

<style scoped>
.teacher_container {
  overflow: hidden;
}

.overlay {
  /*background: linear-gradient(rgba(222, 240, 175,0.2) 26.75236878762871%, rgba(221, 239, 174,0.5) 26.75236878762871%,rgba(52, 163, 150,0.8) 86.83355545642733%);*/
  background: linear-gradient(to bottom, rgba(6, 100, 200, 1.0) 0%, rgba(30, 144, 255, 0.5), rgba(5, 27, 68, 0.8) 100%);
  height: 100%;
  position: relative;
  width: 100%;
}

.teacher_container:hover .inner-content {
  -webkit-transform: scale(1, 0.94);
  transform: scale(1, 0.94);
}

.inner-content {
  position: absolute;
  top: 0;
  left: 10px;
  width: 100%;
  height: 100px;
  background: #50b9ce;
  opacity: 0.8;
  z-index: 100;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  transition: all .3s ease-in-out;
}

.avatar {
  width: 80px;
  height: 80px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  padding: 4px;
  vertical-align: middle;
  display: inline-block;
  border-bottom-color: #50b9ce;
  border-left-color: #50b9ce;
  border-top-color: #e8e8e8;
  border-right-color: #e8e8e8;
}

.jiaofu {
  background: url('https://wp.easypass.zqwei-tech.cn/wp-content/plugins/icyclub/inc/consultup/images/callout/callout-back.jpg') no-repeat fixed 0 0 /cover;
  background-image: url('https://wp.easypass.zqwei-tech.cn/wp-content/plugins/icyclub/inc/consultup/images/callout/callout-back.jpg');
  height: 100%;
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.background {
  background-image: url("https://wp.easypass.zqwei-tech.cn/wp-content/uploads/2021/07/ff9287bdd15f52049a55942a19ab6eaf.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
}

.section_title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 10px;
}

.service_cell .icon {
  margin-top: 15px;
  font-size: 50px;
  color: #00a57d;
  transition: all 500ms ease;
}

.service_cell:hover .icon {
  color: black;
}


.service_cell:hover:before {
  transform: scale(1);
}

.service_cell {
  border-radius: 8px;
  position: relative;
  text-decoration: none;
  color: #333;
  background-color: white;
}

.service_cell:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 3px;
  width: 100%;
  background: rgb(80, 185, 206);
  transform: scale(0);
  transition: all 0.3s;
}

.service_cell:hover:before {
  transform: scale(1);
}

.service_title {
  font-weight: 600;
  font-size: 25px;
  margin-top: 20px;
}

.nav {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 90px;
}

.nav a {
  color: dimgray;
  display: block;
  /*text-decoration: underline*/
  font-size: 14px;
  font-weight: 600;
  background: inherit;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-radius: 0;
  padding: 0 0 6px 0;
  line-height: 1;
  text-transform: uppercase;
  transition: all 500ms ease;
}

.nav a:link, .nav a:visited {
  color: black;
}

.nav a:hover {
  color: rgb(80, 185, 206);
}
</style>