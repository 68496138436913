<template>
  <div>
    <div class="center_col_container hidden-md-and-up">
      <el-alert
          title="本网站为电脑网站，使用竖屏查看可能会使UI显示较为奇怪。请使用电脑或Ipad打开本网页，或横屏查看。"
          type="warning"
          effect="dark">
      </el-alert>
      <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          mode="horizontal"
          @select="handleSelect">
        <el-submenu index="11">
          <template slot="title">
            <i class="el-icon-data-analysis"></i>
            <span>我的课程</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="1">
              <i class="el-icon-menu"></i>
              <span slot="title">我的排课</span>
            </el-menu-item>
            <el-menu-item index="lesson_finished">
              <i class="el-icon-finished"></i>
              <span slot="title">已完成</span>
            </el-menu-item>
            <el-menu-item index="3">
              <i class="el-icon-document"></i>
              <span slot="title">课程合集</span>
            </el-menu-item>
            <el-menu-item index="class">
              <i class="el-icon-takeaway-box"></i>
              <span slot="title">班课信息</span>
            </el-menu-item>
            <el-menu-item index="kc">
              <i class="el-icon-postcard"></i>
              <span slot="title">下载课程表</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="2">
          <i class="el-icon-s-custom"></i>
          <span slot="title">我的学生</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="el-icon-setting"></i>
          <span slot="title">个人信息</span>
        </el-menu-item>
        <el-menu-item index="tutorial">
          <i class="el-icon-reading"></i>
          <span slot="title">使用教程</span>
        </el-menu-item>
      </el-menu>
      <el-dialog title="课程时间确认" :visible.sync="orderTimeDialogVisible"
                 width="90%"
      >
        <div class="flex_start_col_container" v-loading="dialog_loading">
        <span style="font-size: 16px;padding: 10px">是否确认<b>{{
            confirm_lesson_info.student_name
          }}学生</b>的 {{ confirm_lesson_info.subject }}课程时间为
<b>当地时间{{ confirm_lesson_info.start_time }}-{{
    confirm_lesson_info.finish_time
  }}</b>(日期：{{ confirm_lesson_info.start_date }})？</span>
          <span style="font-size: 13px;padding: 10px;color: dimgray">（后期仍可调配时间）</span>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="disconfirmLessonTime(confirm_lesson_info.id)">这个时间不太行</el-button>
        <el-button type="primary" @click="confirmLessonTime(confirm_lesson_info.id)">该时间可行</el-button>
      </span>
      </el-dialog>
      <el-dialog title="提示" :visible.sync="lessonInfoDialogVisible"
                 width="90%"
      >
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="font-size: 18px;padding: 10px">课程信息：</span>
          <template v-if="check_lesson_info.notice_info!==''">
        <span style="font-size: 14px;padding: 10px" class="clickable_item"
              @click="copyToClip(check_lesson_info.notice_info)"
              v-html="getBrText(check_lesson_info.notice_info)">
        </span>
          </template>
          <template v-else>
        <span style="font-size: 16px;padding: 10px">
          暂无任何信息
        </span>
          </template>
          <template v-if="check_lesson_info.additional_info!==''">
            <span style="font-size: 18px;padding: 10px;margin-top: 20px;font-weight: 500">额外信息：</span>
            <span style="font-size: 14px;padding: 10px;" class="clickable_item"
                  v-html="getBrText(check_lesson_info.additional_info)"
                  @click="copyToClip(check_lesson_info.additional_info)">
        </span>
          </template>
          <span style="color: #8c939d;font-size: 14px;padding: 10px;margin-top: 10px">（点击文字复制课程信息）</span>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="lessonSettingUpdate(check_lesson_info)">修改信息</el-button>
        <el-button type="primary" @click="lessonInfoDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-dialog title="学生信息" :visible.sync="studentInfoDialogVisible"
                 width="90%">
        <StudentInfo :info="current_student_info"/>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="studentInfoDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-dialog title="课程信息提交" :visible.sync="lessonSettingDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="padding: 10px;padding-left:0px;font-size: 16px">课程提示信息（必填）：</span>
          <el-input type="textarea" :rows="5"
                    :autosize="{ minRows: 4}" v-model="lesson_setting.notice_info"
                    placeholder="如腾讯会议/zoom等线上链接"></el-input>
          <span style="padding: 10px;padding-left:0px;font-size: 16px;margin-top: 15px">额外补充信息（选填）：</span>
          <el-input type="textarea" rows="5"
                    :autosize="{ minRows: 4 }" v-model="lesson_setting.additional_info"
                    placeholder="如课前需要准备的，或是这节课需要讲评的信息"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lessonSettingSubmit(check_lesson_info.id)">提交</el-button>
      </span>
      </el-dialog>
      <el-dialog title="课程预约" :visible.sync="orderLessonDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <LessonOrdering :com="order_lesson_combination" @submit="orderLessonSubmit"/>
        </div>
      </el-dialog>
      <el-dialog title="课程时间约定设置" :visible.sync="timeSettingDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <TimePicker @submit="timeSettingSubmit" :duration="time_setting_lesson_duration"/>
        </div>
      </el-dialog>
      <el-dialog title="财务信息" :visible.sync="financeInfoDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <FinancialInfo :info="info.financial.info"/>
          <span slot="footer" class="dialog-footer" style="margin-left: auto">
          <el-button type="warning" @click="updateFinancialInfo">更新信息</el-button>
          <el-button type="primary" @click="financeInfoDialogVisible=false">好的</el-button>
        </span>
        </div>
      </el-dialog>
      <el-dialog title="财务信息" :visible.sync="submitFinanceDialogVisible"
                 width="90%">
        <div class="flex_start_col_container">
          <span style="padding: 20px;font-size: 18px">系统还未收到您的财务信息，<b>请立即完善</b>，该信息仅供财务结算！</span>
          <span slot="footer" class="dialog-footer" style="margin-left: auto">
<!--          <el-button type="warning" @click="submitFinanceDialogVisible=false">稍后再试</el-button>-->
          <el-button type="primary"
                     @click="submitFinanceDialogVisible=false;$router.push('/user/financial')">立即完善</el-button>
        </span>
        </div>
      </el-dialog>
      <el-dialog title="课堂反馈" :visible.sync="feedbackEnterDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="padding: 10px;padding-left:0px;font-size: 16px">学生课堂反馈/作业信息：</span>
          <el-input type="textarea" :rows="5"
                    :autosize="{ minRows: 4}" v-model="lesson_feedback"
                    placeholder="如本次课程的小结/课后练习/作业等"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="feedbackSumbit">提交</el-button>
      </span>
      </el-dialog>
      <el-dialog title="学生反馈" :visible.sync="feedbackDisplayDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="padding: 10px;padding-left:0px;font-size: 16px">课堂反馈信息：</span>
          <span style="font-size: 14px;padding: 10px"
                v-html="getBrText(display_feedback)">
        </span>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="feedbackDisplayDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-dialog title="老师端教程" :visible.sync="tutorialDisplayDialogVisible"
                 width="90%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <iframe v-if="tutorialDisplayDialogVisible" class="hover_shadow hidden-md-and-up" style="height: 300px;width:100%"
                  src="https://easytutor.zqwei-tech.cn/video/老师.mp4"
                  scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tutorialDisplayDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
    <el-row type="flex" :gutter="5" class="row-bg" style="margin: 20px;padding: 30px" justify="space-around" v-loading="loading">
      <el-col :span="24">
        <el-table
            :data="info.lessons"
            stripe
            style="width: 100%"
            v-if="this.display_index==='1'">
          <el-table-column
              label="日期">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_date }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="subject"
              label="课程">
          </el-table-column>
          <el-table-column
              prop="student.name"
              label="学生">
          </el-table-column>
          <el-table-column
              label="时间">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_time }}开始
            <template v-if="scope.row.status==='reorder'">（重新约定中）</template></span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程时长">
            <template slot-scope="scope">
              <i class="el-icon-connection"></i>
              <span style="margin-left: 10px">{{ scope.row.duration_hour }}小时</span>
            </template>
          </el-table-column>
          <el-table-column
              label="状态">
            <template slot-scope="scope">
              <div class="flex_start_col_container">
              <span v-if="scope.row.has_student_paid">
                {{ getDisplayStatus(scope.row.status) }}
              </span>
                <span v-else>
                <i class="el-icon-time"></i>
                等待学生付款
              </span>
              </div>
            </template>
          </el-table-column>
          <!--        <el-table-column-->
          <!--            label="课时费">-->
          <!--          <template slot-scope="scope">-->
          <!--            <template v-if="!scope.row.has_student_paid">-->
          <!--              <i class="el-icon-time"></i>-->
          <!--              <span style="margin-left: 10px">等待付款</span>-->
          <!--            </template>-->
          <!--            <template v-else>-->
          <!--              <i class="el-icon-circle-check"></i>-->
          <!--              <span style="margin-left: 10px">已支付</span>-->
          <!--            </template>-->
          <!--          </template>-->
          <!--        </el-table-column>-->
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.lesson_type==='class'">
                <el-button type="warning" v-if="scope.row.notice_info===''&&scope.row.additional_info===''" plain round
                           @click="lessonSettingUpdate(scope.row)">补全班课信息
                </el-button>
                <el-button type="success" v-else plain round @click="checkLessonInfo(scope.row)">班课信息</el-button>
              </template>
              <template v-else-if="scope.row.status==='ordering'">
                <el-button v-if="!scope.row.has_teacher_verified" @click="orderTimeComfirm(scope.row)" type="primary"
                           plain
                           round>约课确认
                </el-button>
                <template v-else>
                  <template v-if="scope.row.notice_info===''&&scope.row.additional_info===''">
                    <el-button type="danger" plain round
                               @click="lessonSettingUpdate(scope.row)">补全信息
                    </el-button>
                  </template>
                  <template v-else>
                    <el-button v-if="scope.row.has_student_paid" :disabled="true" type="primary" round>待学生确认</el-button>
                    <el-button v-if="!scope.row.has_student_paid" type="info" round disabled>等待学生付款</el-button>
                  </template>
                </template>
              </template>
              <template v-else-if="scope.row.status==='reorder'">
                <el-button type="info" round disabled v-if="scope.row.verify_master!=='teacher'">重约处理中</el-button>
                <el-button type="danger" round v-else @click="timeSettingUpdate(scope.row)">重约时间</el-button>
              </template>
              <template v-else>
                <el-button type="danger" v-if="scope.row.notice_info===''&&scope.row.additional_info===''" plain round
                           @click="lessonSettingUpdate(scope.row)">补全信息
                </el-button>
                <el-button type="success" v-else plain round @click="checkLessonInfo(scope.row)">上课信息</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="info.f_lessons"
            stripe
            style="width: 100%"
            v-if="this.display_index==='lesson_finished'">
          <el-table-column
              label="日期">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_date }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="subject"
              label="课程">
          </el-table-column>
          <el-table-column
              prop="student.name"
              label="学生">
          </el-table-column>
          <el-table-column
              label="时间">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_time }}开始
            <template v-if="scope.row.status==='reorder'">（重新约定中）</template></span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程时长">
            <template slot-scope="scope">
              <i class="el-icon-connection"></i>
              <span style="margin-left: 10px">{{ scope.row.duration_hour }}小时</span>
            </template>
          </el-table-column>
          <el-table-column
              label="状态">
            <template slot-scope="scope">
              <div class="flex_start_col_container">
              <span v-if="scope.row.has_student_paid">
                {{ getDisplayStatus(scope.row.status) }}
              </span>
                <span v-else>
                <i class="el-icon-time"></i>
                等待学生付款
              </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="反馈">
            <template slot-scope="scope">
              <template>

              </template>
              <template v-if="scope.row.teacher_feedback">
                <el-button v-if="scope.row.lesson_type==='class'" type="warning" round
                           @click="feedbackDisplayDialogVisible=true;display_feedback=scope.row.teacher_feedback">
                  查看填写的反馈
                </el-button>
                <el-button v-else-if="scope.row.student_feedback" type="primary" round
                           @click="feedbackDisplayDialogVisible=true;display_feedback=scope.row.student_feedback">查看学生反馈
                </el-button>
                <el-button v-else type="primary" disabled round>等待学生反馈</el-button>
              </template>
              <template v-else>
                <el-button round type="danger" @click="feedbackDialogDisplay(scope.row.id)">课堂/作业反馈</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="info.combinations"
            stripe
            style="width: 100%"
            v-if="this.display_index==='2'">
          <el-table-column
              label="学生">
            <template slot-scope="scope">
              <i class="el-icon-user"></i>
              <span style="margin-left: 5px">{{ scope.row.student.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程">
            <template slot-scope="scope">
              <i class="el-icon-office-building"></i>
              <span style="margin-left: 5px">{{ scope.row.subject }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="课时费/小时">
            <template slot-scope="scope">
              <span>￥{{ scope.row.hour_teacher_wage }}/小时</span>
            </template>
          </el-table-column>

          <el-table-column
              label="学生详细信息">
            <template slot-scope="scope">
              <el-button @click="checkStudentDetail(scope.row.student)" round type="primary">查看详情</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-table
            :data="info.lessonsets"
            stripe
            style="width: 100%"
            v-if="this.display_index==='3'">
          <el-table-column
              label="学生">
            <template slot-scope="scope">
              <i class="el-icon-user"></i>
              <span style="margin-left: 5px">{{ info.combination_dict[scope.row.combination].student.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程"
              width="220px">
            <template slot-scope="scope">
              <i class="el-icon-office-building"></i>
              <span style="margin-left: 5px"> {{ info.combination_dict[scope.row.combination].subject }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="总课时"
          >
            <template slot-scope="scope">
              {{ scope.row.total_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="剩余课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_hours }}小时
            </template>
          </el-table-column>

          <el-table-column
              label="未计划课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unplanned_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="未结算课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unpaid_hours }}小时
            </template>
          </el-table-column>

          <el-table-column
              label="课时费结算">
            <template slot-scope="scope">
              ￥{{ scope.row.total_wage - scope.row.remaining_wage }}/{{ scope.row.total_wage }}
            </template>
          </el-table-column>
          <el-table-column
              label="合集状态"
          >
            <template slot-scope="scope">
              <template v-if="!scope.row.has_student_paid">
                等待学生付款
              </template>
              <template v-else-if="scope.row.has_student_paid&&scope.row.has_finished">
                已结课
              </template>
              <template v-else-if="scope.row.has_student_paid&&!scope.row.has_finished">
                上课中（未结课）
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label=课程操作>
            <template slot-scope="scope">
              <el-button v-if="scope.row.remaining_unplanned_hours!==0" @click="orderNewLessonFromSet(scope.row)" round
                         type="primary">安排新课
              </el-button>
              <el-button v-else disabled round type="primary">无可用课时</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="info.class_sets"
            stripe
            style="width: 100%"
            v-if="this.display_index==='class'">
          <el-table-column
              label="课程"
              width="220px">
            <template slot-scope="scope">
              <i class="el-icon-office-building"></i>
              <span style="margin-left: 5px"> {{ scope.row.subject }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="总课时"
          >
            <template slot-scope="scope">
              {{ scope.row.total_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="剩余课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_hours }}小时
            </template>
          </el-table-column>

          <el-table-column
              label="未计划课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unplanned_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="未结算课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unpaid_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="课时费结算">
            <template slot-scope="scope">
              ￥{{ scope.row.total_wage - scope.row.remaining_wage }}/{{ scope.row.total_wage }}
            </template>
          </el-table-column>
          <el-table-column
              label="班课状态"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.has_finished">
                已结课
              </template>
              <template v-else>
                上课中（未结课）
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="display_index==='4'" class="center_col_container" style="padding: 20px">
          <TeacherUserInfo :user="info.user" :finance="info.financial" @change_pass="changePass"
                           @show_finance="showFinance"/>
        </div>
      </el-col>
    </el-row>
    </div>
    <el-row type="flex" :gutter="5" class="row-bg hidden-sm-and-down" justify="space-around" v-loading="loading">
      <el-dialog title="课程时间确认" :visible.sync="orderTimeDialogVisible"
                 width="30%"
      >
        <div class="flex_start_col_container" v-loading="dialog_loading">
        <span style="font-size: 16px;padding: 10px">是否确认<b>{{
            confirm_lesson_info.student_name
          }}学生</b>的 {{ confirm_lesson_info.subject }}课程时间为
<b>当地时间{{ confirm_lesson_info.start_time }}-{{
    confirm_lesson_info.finish_time
  }}</b>(日期：{{ confirm_lesson_info.start_date }})？</span>
          <span style="font-size: 13px;padding: 10px;color: dimgray">（后期仍可调配时间）</span>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="disconfirmLessonTime(confirm_lesson_info.id)">这个时间不太行</el-button>
        <el-button type="primary" @click="confirmLessonTime(confirm_lesson_info.id)">该时间可行</el-button>
      </span>
      </el-dialog>
      <el-dialog title="提示" :visible.sync="lessonInfoDialogVisible"
                 width="30%"
      >
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="font-size: 18px;padding: 10px">课程信息：</span>
          <template v-if="check_lesson_info.notice_info!==''">
        <span style="font-size: 14px;padding: 10px" class="clickable_item"
              @click="copyToClip(check_lesson_info.notice_info)"
              v-html="getBrText(check_lesson_info.notice_info)">
        </span>
          </template>
          <template v-else>
        <span style="font-size: 16px;padding: 10px">
          暂无任何信息
        </span>
          </template>
          <template v-if="check_lesson_info.additional_info!==''">
            <span style="font-size: 18px;padding: 10px;margin-top: 20px;font-weight: 500">额外信息：</span>
            <span style="font-size: 14px;padding: 10px;" class="clickable_item"
                  v-html="getBrText(check_lesson_info.additional_info)"
                  @click="copyToClip(check_lesson_info.additional_info)">
        </span>
          </template>
          <span style="color: #8c939d;font-size: 14px;padding: 10px;margin-top: 10px">（点击文字复制课程信息）</span>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="lessonSettingUpdate(check_lesson_info)">修改信息</el-button>
        <el-button type="primary" @click="lessonInfoDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-dialog title="学生信息" :visible.sync="studentInfoDialogVisible"
                 width="30%">
        <StudentInfo :info="current_student_info"/>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="studentInfoDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-dialog title="课程信息提交" :visible.sync="lessonSettingDialogVisible"
                 width="30%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="padding: 10px;padding-left:0px;font-size: 16px">课程提示信息（必填）：</span>
          <el-input type="textarea" :rows="5"
                    :autosize="{ minRows: 4}" v-model="lesson_setting.notice_info"
                    placeholder="如腾讯会议/zoom等线上链接"></el-input>
          <span style="padding: 10px;padding-left:0px;font-size: 16px;margin-top: 15px">额外补充信息（选填）：</span>
          <el-input type="textarea" rows="5"
                    :autosize="{ minRows: 4 }" v-model="lesson_setting.additional_info"
                    placeholder="如课前需要准备的，或是这节课需要讲评的信息"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lessonSettingSubmit(check_lesson_info.id)">提交</el-button>
      </span>
      </el-dialog>
      <el-dialog title="课程预约" :visible.sync="orderLessonDialogVisible"
                 width="30%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <LessonOrdering :com="order_lesson_combination" @submit="orderLessonSubmit"/>
        </div>
      </el-dialog>
      <el-dialog title="课程时间约定设置" :visible.sync="timeSettingDialogVisible"
                 width="30%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <TimePicker @submit="timeSettingSubmit" :duration="time_setting_lesson_duration"/>
        </div>
      </el-dialog>
      <el-dialog title="财务信息" :visible.sync="financeInfoDialogVisible"
                 width="30%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <FinancialInfo :info="info.financial.info"/>
          <span slot="footer" class="dialog-footer" style="margin-left: auto">
          <el-button type="warning" @click="updateFinancialInfo">更新信息</el-button>
          <el-button type="primary" @click="financeInfoDialogVisible=false">好的</el-button>
        </span>
        </div>
      </el-dialog>
      <el-dialog title="财务信息" :visible.sync="submitFinanceDialogVisible"
                 width="30%">
        <div class="flex_start_col_container">
          <span style="padding: 20px;font-size: 18px">系统还未收到您的财务信息，<b>请立即完善</b>，该信息仅供财务结算！</span>
          <span slot="footer" class="dialog-footer" style="margin-left: auto">
<!--          <el-button type="warning" @click="submitFinanceDialogVisible=false">稍后再试</el-button>-->
          <el-button type="primary"
                     @click="submitFinanceDialogVisible=false;$router.push('/user/financial')">立即完善</el-button>
        </span>
        </div>
      </el-dialog>
      <el-dialog title="课堂反馈" :visible.sync="feedbackEnterDialogVisible"
                 width="30%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="padding: 10px;padding-left:0px;font-size: 16px">学生课堂反馈/作业信息：</span>
          <el-input type="textarea" :rows="5"
                    :autosize="{ minRows: 4}" v-model="lesson_feedback"
                    placeholder="如本次课程的小结/课后练习/作业等"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="feedbackSumbit">提交</el-button>
      </span>
      </el-dialog>
      <el-dialog title="学生反馈" :visible.sync="feedbackDisplayDialogVisible"
                 width="30%">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <span style="padding: 10px;padding-left:0px;font-size: 16px">课堂反馈信息：</span>
          <span style="font-size: 14px;padding: 10px"
                v-html="getBrText(display_feedback)">
        </span>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="feedbackDisplayDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-dialog title="老师端教程" :visible.sync="tutorialDisplayDialogVisible"
                 width="850px">
        <div class="flex_start_col_container" v-loading="dialog_loading">
          <iframe v-if="tutorialDisplayDialogVisible" class="hover_shadow hidden-sm-and-down" style="height: 500px;width:100%"
                  src="https://easytutor.zqwei-tech.cn/video/老师.mp4"
                  scrolling="yes" border="0" frameborder="no" framespacing="1" allowfullscreen="true"></iframe>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tutorialDisplayDialogVisible=false">好的</el-button>
      </span>
      </el-dialog>
      <el-col :span="4">
        <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            @select="handleSelect">
          <el-submenu index="11">
            <template slot="title">
              <i class="el-icon-data-analysis"></i>
              <span>我的课程</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1">
                <i class="el-icon-menu"></i>
                <span slot="title">我的排课</span>
              </el-menu-item>
              <el-menu-item index="lesson_finished">
                <i class="el-icon-finished"></i>
                <span slot="title">已完成</span>
              </el-menu-item>
              <el-menu-item index="3">
                <i class="el-icon-document"></i>
                <span slot="title">课程合集</span>
              </el-menu-item>
              <el-menu-item index="class">
                <i class="el-icon-takeaway-box"></i>
                <span slot="title">班课信息</span>
              </el-menu-item>
              <el-menu-item index="kc">
                <i class="el-icon-postcard"></i>
                <span slot="title">下载课程表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="2">
            <i class="el-icon-s-custom"></i>
            <span slot="title">我的学生</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-setting"></i>
            <span slot="title">个人信息</span>
          </el-menu-item>
          <el-menu-item index="tutorial">
            <i class="el-icon-reading"></i>
            <span slot="title">使用教程</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="19">
        <el-table
            :data="info.lessons"
            stripe
            style="width: 100%"
            v-if="this.display_index==='1'">
          <el-table-column
              label="日期">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_date }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="subject"
              label="课程">
          </el-table-column>
          <el-table-column
              prop="student.name"
              label="学生">
          </el-table-column>
          <el-table-column
              label="时间">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_time }}开始
            <template v-if="scope.row.status==='reorder'">（重新约定中）</template></span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程时长">
            <template slot-scope="scope">
              <i class="el-icon-connection"></i>
              <span style="margin-left: 10px">{{ scope.row.duration_hour }}小时</span>
            </template>
          </el-table-column>
          <el-table-column
              label="状态">
            <template slot-scope="scope">
              <div class="flex_start_col_container">
              <span v-if="scope.row.has_student_paid">
                {{ getDisplayStatus(scope.row.status) }}
              </span>
                <span v-else>
                <i class="el-icon-time"></i>
                等待学生付款
              </span>
              </div>
            </template>
          </el-table-column>
          <!--        <el-table-column-->
          <!--            label="课时费">-->
          <!--          <template slot-scope="scope">-->
          <!--            <template v-if="!scope.row.has_student_paid">-->
          <!--              <i class="el-icon-time"></i>-->
          <!--              <span style="margin-left: 10px">等待付款</span>-->
          <!--            </template>-->
          <!--            <template v-else>-->
          <!--              <i class="el-icon-circle-check"></i>-->
          <!--              <span style="margin-left: 10px">已支付</span>-->
          <!--            </template>-->
          <!--          </template>-->
          <!--        </el-table-column>-->
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.lesson_type==='class'">
                <el-button type="warning" v-if="scope.row.notice_info===''&&scope.row.additional_info===''" plain round
                           @click="lessonSettingUpdate(scope.row)">补全班课信息
                </el-button>
                <el-button type="success" v-else plain round @click="checkLessonInfo(scope.row)">班课信息</el-button>
              </template>
              <template v-else-if="scope.row.status==='ordering'">
                <el-button v-if="!scope.row.has_teacher_verified" @click="orderTimeComfirm(scope.row)" type="primary"
                           plain
                           round>约课确认
                </el-button>
                <template v-else>
                  <template v-if="scope.row.notice_info===''&&scope.row.additional_info===''">
                    <el-button type="danger" plain round
                               @click="lessonSettingUpdate(scope.row)">补全信息
                    </el-button>
                  </template>
                  <template v-else>
                    <el-button v-if="scope.row.has_student_paid" :disabled="true" type="primary" round>待学生确认</el-button>
                    <el-button v-if="!scope.row.has_student_paid" type="info" round disabled>等待学生付款</el-button>
                  </template>
                </template>
              </template>
              <template v-else-if="scope.row.status==='reorder'">
                <el-button type="info" round disabled v-if="scope.row.verify_master!=='teacher'">重约处理中</el-button>
                <el-button type="danger" round v-else @click="timeSettingUpdate(scope.row)">重约时间</el-button>
              </template>
              <template v-else>
                <el-button type="danger" v-if="scope.row.notice_info===''&&scope.row.additional_info===''" plain round
                           @click="lessonSettingUpdate(scope.row)">补全信息
                </el-button>
                <el-button type="success" v-else plain round @click="checkLessonInfo(scope.row)">上课信息</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="info.f_lessons"
            stripe
            style="width: 100%"
            v-if="this.display_index==='lesson_finished'">
          <el-table-column
              label="日期">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_date }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="subject"
              label="课程">
          </el-table-column>
          <el-table-column
              prop="student.name"
              label="学生">
          </el-table-column>
          <el-table-column
              label="时间">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.start_time }}开始
            <template v-if="scope.row.status==='reorder'">（重新约定中）</template></span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程时长">
            <template slot-scope="scope">
              <i class="el-icon-connection"></i>
              <span style="margin-left: 10px">{{ scope.row.duration_hour }}小时</span>
            </template>
          </el-table-column>
          <el-table-column
              label="状态">
            <template slot-scope="scope">
              <div class="flex_start_col_container">
              <span v-if="scope.row.has_student_paid">
                {{ getDisplayStatus(scope.row.status) }}
              </span>
                <span v-else>
                <i class="el-icon-time"></i>
                等待学生付款
              </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="反馈">
            <template slot-scope="scope">
              <template>

              </template>
              <template v-if="scope.row.teacher_feedback">
                <el-button v-if="scope.row.lesson_type==='class'" type="warning" round
                           @click="feedbackDisplayDialogVisible=true;display_feedback=scope.row.teacher_feedback">
                  查看填写的反馈
                </el-button>
                <el-button v-else-if="scope.row.student_feedback" type="primary" round
                           @click="feedbackDisplayDialogVisible=true;display_feedback=scope.row.student_feedback">查看学生反馈
                </el-button>
                <el-button v-else type="primary" disabled round>等待学生反馈</el-button>
              </template>
              <template v-else>
                <el-button round type="danger" @click="feedbackDialogDisplay(scope.row.id)">课堂/作业反馈</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="info.combinations"
            stripe
            style="width: 100%"
            v-if="this.display_index==='2'">
          <el-table-column
              label="学生">
            <template slot-scope="scope">
              <i class="el-icon-user"></i>
              <span style="margin-left: 5px">{{ scope.row.student.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程">
            <template slot-scope="scope">
              <i class="el-icon-office-building"></i>
              <span style="margin-left: 5px">{{ scope.row.subject }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="课时费/小时">
            <template slot-scope="scope">
              <span>￥{{ scope.row.hour_teacher_wage }}/小时</span>
            </template>
          </el-table-column>

          <el-table-column
              label="学生详细信息">
            <template slot-scope="scope">
              <el-button @click="checkStudentDetail(scope.row.student)" round type="primary">查看详情</el-button>
            </template>
          </el-table-column>

        </el-table>

        <el-table
            :data="info.lessonsets"
            stripe
            style="width: 100%"
            v-if="this.display_index==='3'">
          <el-table-column
              label="学生">
            <template slot-scope="scope">
              <i class="el-icon-user"></i>
              <span style="margin-left: 5px">{{ info.combination_dict[scope.row.combination].student.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="课程"
              width="220px">
            <template slot-scope="scope">
              <i class="el-icon-office-building"></i>
              <span style="margin-left: 5px"> {{ info.combination_dict[scope.row.combination].subject }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="总课时"
          >
            <template slot-scope="scope">
              {{ scope.row.total_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="剩余课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_hours }}小时
            </template>
          </el-table-column>

          <el-table-column
              label="未计划课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unplanned_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="未结算课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unpaid_hours }}小时
            </template>
          </el-table-column>

          <el-table-column
              label="课时费结算">
            <template slot-scope="scope">
              ￥{{ scope.row.total_wage - scope.row.remaining_wage }}/{{ scope.row.total_wage }}
            </template>
          </el-table-column>
          <el-table-column
              label="合集状态"
          >
            <template slot-scope="scope">
              <template v-if="!scope.row.has_student_paid">
                等待学生付款
              </template>
              <template v-else-if="scope.row.has_student_paid&&scope.row.has_finished">
                已结课
              </template>
              <template v-else-if="scope.row.has_student_paid&&!scope.row.has_finished">
                上课中（未结课）
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label=课程操作>
            <template slot-scope="scope">
              <el-button v-if="scope.row.remaining_unplanned_hours!==0" @click="orderNewLessonFromSet(scope.row)" round
                         type="primary">安排新课
              </el-button>
              <el-button v-else disabled round type="primary">无可用课时</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="info.class_sets"
            stripe
            style="width: 100%"
            v-if="this.display_index==='class'">
          <el-table-column
              label="课程"
              width="220px">
            <template slot-scope="scope">
              <i class="el-icon-office-building"></i>
              <span style="margin-left: 5px"> {{ scope.row.subject }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="总课时"
          >
            <template slot-scope="scope">
              {{ scope.row.total_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="剩余课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_hours }}小时
            </template>
          </el-table-column>

          <el-table-column
              label="未计划课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unplanned_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="未结算课时"
          >
            <template slot-scope="scope">
              {{ scope.row.remaining_unpaid_hours }}小时
            </template>
          </el-table-column>
          <el-table-column
              label="课时费结算">
            <template slot-scope="scope">
              ￥{{ scope.row.total_wage - scope.row.remaining_wage }}/{{ scope.row.total_wage }}
            </template>
          </el-table-column>
          <el-table-column
              label="班课状态"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.has_finished">
                已结课
              </template>
              <template v-else>
                上课中（未结课）
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="display_index==='4'" class="center_col_container" style="padding: 20px">
          <TeacherUserInfo :user="info.user" :finance="info.financial" @change_pass="changePass"
                           @show_finance="showFinance"/>
          <!--        <el-button @click="$router.push('/user/financial')">完善财税信息</el-button>-->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import crypto_utils from "@/utils/crypto_utils";
import {randomString} from "@/utils/utils";
import {
  confirm_lesson_time, create_new_lesson,
  deconfirm_lesson_time, download_user_ics,
  notice_teacher_provide_info, reorder_lesson_time, submit_feedback, submit_for_lesson_info
} from "@/utils/netschool_utils";

import {get_single_lesson_payment_url} from "@/utils/payment_utils";
import StudentInfo from "@/components/StudentInfo";
import UserInfo from "@/components/UserInfo";
import TimePicker from "@/components/TimePicker";
import LessonOrdering from "@/components/LessonOrdering";
import TeacherUserInfo from "@/components/TeacherUserInfo";
import FinancialInfoForm from "@/page/financial/FinancialInfoForm";
import FinancialInfo from "@/components/FinancialInfo";

export default {
  name: "TeacherCenter",
  components: {
    FinancialInfo,
    FinancialInfoForm,
    TeacherUserInfo,
    LessonOrdering,
    TimePicker,
    UserInfo,
    StudentInfo,
    'full-calendar': require('vue-fullcalendar')
  },
  data() {
    return {
      orderTimeDialogVisible: false,
      lessonInfoDialogVisible: false,
      studentInfoDialogVisible: false,
      lessonSettingDialogVisible: false,
      timeSettingDialogVisible: false,
      orderLessonDialogVisible: false,
      financeInfoDialogVisible: false,
      submitFinanceDialogVisible: false,
      feedbackEnterDialogVisible: false,
      feedbackDisplayDialogVisible: false,
      tutorialDisplayDialogVisible: false,
      lesson_feedback: '',
      display_feedback: "",
      order_lesson_combination: {},
      order_lesson_set: null,
      time_setting_lesson_id: 0,
      time_setting_lesson_duration: 1,
      lesson_setting: {
        notice_info: "",
        additional_info: ""
      },
      lessons: [],
      combinations: [],
      display_index: "1",
      user: {},
      loading: true,
      dialog_loading: false,
      confirm_lesson_info: {},
      check_lesson_info: {},
      info: {
        financial: {}
      },
      current_student_info: {},
    }
  },
  mounted() {
    this.$EventBus.$on("teacher_user_center", (msg) => {
      this.info = msg
      this.loading = false
      if (!this.info.financial.status) {
        this.submitFinanceDialogVisible = true
      }
    });
  },
  methods: {
    feedbackSumbit() {
      submit_feedback(this.feedback_lesson_id, this.lesson_feedback, this)
    },
    feedbackDialogDisplay(id) {
      this.feedback_lesson_id = id
      this.feedbackEnterDialogVisible = true
    },
    updateFinancialInfo() {
      this.financeInfoDialogVisible = false
      this.$router.push('/user/financial')
    },
    showFinance() {
      this.financeInfoDialogVisible = true
    },
    orderNewLesson(com) {
      this.order_lesson_combination = com
      this.orderLessonDialogVisible = true
      this.order_lesson_set = null
    },
    orderNewLessonFromSet(set) {
      this.order_lesson_combination = this.info.combination_dict[set.combination]
      this.orderLessonDialogVisible = true
      this.order_lesson_set = set
    },
    orderLessonSubmit(time, duration_hour) {
      if (this.order_lesson_set) {
        create_new_lesson(this.order_lesson_combination.id, (Date.parse(time) / 1000).toFixed(), duration_hour, this, this.order_lesson_set.id)
      } else {
        create_new_lesson(this.order_lesson_combination.id, (Date.parse(time) / 1000).toFixed(), duration_hour, this)
      }
    },
    timeSettingSubmit(time) {
      reorder_lesson_time(this.time_setting_lesson_id, (Date.parse(time) / 1000).toFixed(), this)
    },
    timeSettingUpdate(lesson) {
      this.time_setting_lesson_id = lesson.id
      this.time_setting_lesson_duration = lesson.duration_hour
      this.timeSettingDialogVisible = true
    },
    lessonSettingUpdate(lesson) {
      this.lessonSettingDialogVisible = true
      this.lessonInfoDialogVisible = false
      this.check_lesson_info = lesson
    },
    lessonSettingSubmit(id) {
      if (this.lesson_setting.notice_info === '') {
        this.$message.error("提示信息不能为空！")
      } else if (this.lesson_setting.notice_info.length > 10000 || this.lesson_setting.additional_info.length > 10000) {
        this.$message.error("提示或额外信息过长！请重新输入！")
      } else {
        submit_for_lesson_info(id, this.lesson_setting, this)
      }
    },
    copyToClip(content) {
      content = content.replace(/\n/g, '  ')
      var aux = document.createElement("input");
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message.success("已复制到剪切板！")
    },
    getBrText(text) {
      if (text) {
        return text.replace(/\n/g, '<br>')
      }
      return ""
    },
    noticeTeacherProvideInfo(lesson) {
      notice_teacher_provide_info(lesson, this)
    },
    orderTimeComfirm(lesson) {
      this.confirm_lesson_info = lesson
      this.orderTimeDialogVisible = true
    },
    checkLessonInfo(lesson) {
      this.check_lesson_info = lesson
      this.lessonInfoDialogVisible = true
    },
    confirmLessonTime(id) {
      confirm_lesson_time(id, this)
    },
    disconfirmLessonTime(id) {
      deconfirm_lesson_time(id, this)
    },
    payForSingleLesson(id) {
      get_single_lesson_payment_url(id, this)
    },
    handleSelect(key) {
      if (key === "tutorial") {
        this.tutorialDisplayDialogVisible = true
      } else if (key !== "kc") {
        this.display_index = key
      } else {
        download_user_ics(this)
      }
    },
    getDisplayDate(time) {
      let time_obj = new Date(time)
      return time_obj.toLocaleString()
    },
    checkStudentDetail(info) {
      this.current_student_info = info
      this.studentInfoDialogVisible = true
    },
    changePass() {
      this.$router.push({path: '/user/change_pass/', params: {user: this.user.username}})
    },
    getDisplayStatus(status) {
      if (status === "waiting") {
        return "等待上课"
      } else if (status === "ordering") {
        return "约课待确认"
      } else if (status === "finished") {
        return "已结课"
      } else if (status === "reorder") {
        return "重新约课中"
      } else if (status === "error") {
        return "异常未完成"
      } else if (status === "return") {
        return "已退款"
      } else {
        return "未知"
      }
    }
  }
}
</script>

<style scoped>
.user_info .label {
  padding: 10px;
  color: #111111;
  font-weight: bold;
  font-size: 16px;
}

.user_info .label .content {
  margin-left: 5px;
  color: dimgray;
  font-size: 14px;
}
</style>