<template>
  <div class="flex_start_col_container user_info">
    <!--  <span style="font-size: 20px;font-weight: 500">学生详细信息</span>-->
    <span class="label">实名姓名 </span>
    <span class="content">{{ info.real_name }}</span>
    <span class="label">国内手机号 </span>
    <span class="content">{{ info.phone }}</span>
    <span class="label">身份证号 </span>
    <span class="content">{{ info.id_number }}</span>
    <span class="label">支付宝账号 </span>
    <span class="content">{{ info.alipay_account }}</span>
    <span class="label">支付宝实名 </span>
    <span class="content">{{ info.alipay_name }}</span>
    <template v-if="info.email">
      <span class="label">财务联系邮箱 </span>
      <span class="content">{{ info.email }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: "FinancialInfo",
  props: ["info"]
}
</script>

<style scoped>
.user_info{
  margin-bottom: 15px;
}

.user_info .label {
  padding-top: 15px;
  padding: 10px;
  color: #111111;
  font-weight: bold;
  font-size: 16px;
}

.user_info .content {
  padding: 10px;
  color: dimgray;
  font-size: 14px;
}
</style>