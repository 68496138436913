<template>
<div class="page_basic_container">
    <div class="center_col_container">
      <div class="center_col_container" style="width: 100%">
        <img src="../assets/404.jpg" style="width: 40%;height: auto">
        <h3>Oops! 没有找到该页面</h3>
        <h1><span>4</span><span>0</span><span>4</span></h1>
      </div>
      <h2>非常抱歉，您请求的页面不存在</h2>
    </div>
</div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>