<template>
  <div class="center_col_container" style="width: 100%">
    <BackgroundHeader text="其他服务" style="margin-bottom: 40px"/>
    <div class="center_col_container hidden-sm-and-down" style="width: 70%;background-color: #f7f7f7;padding: 45px">
      <div class="space_between_start_row_container">
        <div class="center_col_container" style="width: 50%;padding: 45px">
          <img style="width: 100%;height: auto;margin-top: 0px"
               src="../assets/文件.png">
        </div>
        <div class="flex_start_col_container" style="width: 50%;padding: 45px">
          <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">PS文书服务</span>
          <span style="color: grey;line-height: 2.0">我们为广大学生也提供PS修改服务，
            包括素材积累与准备，文书时间规划，背景拓展与提升方案，学科专业对接，PS框架建设，多轮反复修改，语言定稿等服务。
            学生只需要和我们平台的小助手沟通完成，即可给出一套完整的，富有针对性的PS文书服务。
            由于该服务具有高可定制性，需要经过我们的PS核心团队的协调并给出相应的方案，最终交由平台入驻的老师负责具体的PS修改学术补习和提供一些参考建议。
<br><br>
            我们的PS基本流程：基本了解学生情况+确定服务意愿=>通过若干PS课程会议以全面了解情况并给出可行性方案=>
            学术/实践素材准备=>基于专业意见准备相应辅导+修改意见=>经过反复修改后进入最后的语言定稿=>完成，并提交
          </span>
        </div>
      </div>
      <div class="space_between_start_row_container">
        <div class="flex_start_col_container" style="width: 50%;padding: 45px">
          <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">牛剑/IC模拟面试-面试课</span>
          <span style="color: grey;line-height: 2.0">
            我们的老师作为面试的经历者，可以从过来人的角度来帮助学生准备他们的面试，包括面试技巧，面试心得，一些常见的面试问题等等，
            帮助学生更好的准备面试并了解面试的过程，从而更好的应对来自目标院校的面试考验。<br><br>
            一般的流程为：首节面试课，帮助学生了解面试的考察点，基本流程与内容，教授相关知识点=>
            模拟完整的面试，了解学生的一些问题=>
            提供建议以及一些过来人的经验，帮助学生更好的准备=>
            形成反馈闭环，反复操练，让学生不论遇到何种情况都能得心应手的应对
          </span>
        </div>
        <div class="center_col_container" style="width: 50%;padding: 45px;margin-top: 10px">
          <img style="width: 100%;height: auto"
               src="../assets/文档.png">
        </div>
      </div>
      <div class="space_between_start_row_container">
        <div class="center_col_container" style="width: 50%;padding: 45px">
          <img style="width: 100%;height: auto;margin-top: 10px"
               src="../assets/文件2.png">
        </div>
        <div class="center_col_container" style="width: 50%;padding: 45px">
          <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px;margin-right: auto">学术背景提升</span>
          <span style="color: grey;line-height: 2.0">
              为了更好的准备PS素材，我们提供部分学术背景提升项目，包括生物医药，数学研究，python web开发，机器学习，无人机飞控技术，Arduino等等方面的学术背景提升项目。具体可以联系我们的小助手！
            </span>
        </div>
      </div>
      <div class="center_col_container" style="padding: 30px">
        <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">还没找到心仪的老师？</span>
        <el-button size="large" type="success" @click="$router.push('discover')" style="margin: 40px">立即在线选择</el-button>
      </div>
    </div>
    <div class="center_col_container hidden-md-and-up" style="width: 95%;background-color: #f7f7f7;padding: 45px">
      <div class="center_col_container">
        <div class="flex_start_col_container" style="width: 90%;padding: 10px">
          <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">PS文书服务</span>
          <span style="color: grey;line-height: 2.0">我们为广大学生也提供PS修改服务，
            包括素材积累与准备，文书时间规划，背景拓展与提升方案，学科专业对接，PS框架建设，多轮反复修改，语言定稿等服务。
            学生只需要和我们平台的小助手沟通完成，即可给出一套完整的，富有针对性的PS文书服务。
            由于该服务具有高可定制性，需要经过我们的PS核心团队的协调并给出相应的方案，最终交由平台入驻的老师负责具体的PS修改学术补习和提供一些参考建议。
<br><br>
            我们的PS基本流程：基本了解学生情况+确定服务意愿=>通过若干PS课程会议以全面了解情况并给出可行性方案=>
            学术/实践素材准备=>基于专业意见准备相应辅导+修改意见=>经过反复修改后进入最后的语言定稿=>完成，并提交
          </span>
        </div>
        <div class="center_col_container" style="width: 90%;padding: 10px">
          <img style="width: 100%;height: auto;margin-top: 0px"
               src="../assets/文件.png">
        </div>
      </div>
      <div class="center_col_container">
        <div class="flex_start_col_container" style="width: 90%;padding: 15px">
          <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">牛剑/IC模拟面试-面试课</span>
          <span style="color: grey;line-height: 2.0">
            我们的老师作为面试的经历者，可以从过来人的角度来帮助学生准备他们的面试，包括面试技巧，面试心得，一些常见的面试问题等等，
            帮助学生更好的准备面试并了解面试的过程，从而更好的应对来自目标院校的面试考验。<br><br>
            一般的流程为：首节面试课，帮助学生了解面试的考察点，基本流程与内容，教授相关知识点=>
            模拟完整的面试，了解学生的一些问题=>
            提供建议以及一些过来人的经验，帮助学生更好的准备=>
            形成反馈闭环，反复操练，让学生不论遇到何种情况都能得心应手的应对
          </span>
        </div>
        <div class="center_col_container" style="width: 70%;padding: 15px;margin-top: 10px">
          <img style="width: 100%;height: auto"
               src="../assets/文档.png">
        </div>
      </div>
      <div class="center_col_container">
        <div class="center_col_container" style="width: 90%;padding: 15px">
          <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px;margin-right: auto">学术背景提升</span>
          <span style="color: grey;line-height: 2.0">
              为了更好的准备PS素材，我们提供部分学术背景提升项目，包括生物医药，数学研究，python web开发，机器学习，无人机飞控技术，Arduino等等方面的学术背景提升项目。具体可以联系我们的小助手！
            </span>
        </div>
        <div class="center_col_container" style="width: 90%;padding: 15px">
          <img style="width: 100%;height: auto;margin-top: 10px"
               src="../assets/文件2.png">
        </div>
      </div>
      <div class="center_col_container" style="padding: 30px">
        <span style="font-size: 30px;padding-bottom: 15px;padding-top: 20px">还没找到心仪的老师？</span>
        <el-button size="large" type="success" @click="$router.push('discover')" style="margin: 40px">立即在线选择</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundHeader from "@/components/BackgroundHeader";
export default {
  name: "OtherService",
  components: {BackgroundHeader}
}
</script>

<style scoped>

</style>